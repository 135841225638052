
import { Modal } from "antd";
import { Select, Spin, Table } from "antd";

const { confirm } = Modal;

const WaitModal = async (text) => {      
    return confirm({
      content: 
        <div>
            <p>{text}</p>
                <div style={{ width: "100%", height: "100%", minHeight: "inherit", display: "flex", alignItems: "center", justifyContent: "center" }}>
                    <Spin size="large" />
                </div>
        </div>,
      okButtonProps: {style:{display:"none"}},
      cancelButtonProps: {style:{display:"none"}},
    });
} 

export default WaitModal;