import { Button, Input, Select } from "antd";
import { SearchOutlined } from "@ant-design/icons";

const { Option } = Select;

function handleSearch(selectedKeys, confirm, dataIndex) {
    confirm();
};

function handleReset(clearFilters) {
    clearFilters();
};

function ColumnSelectSearchProps(dataIndex, name, array, key = "key", value = "value") {
    let searchInput;
    const result =
    {
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => {

            return (
                <div style={{ padding: 8 }}>
                    <Select
                        ref={node => {
                            searchInput = node;
                        }}
                        placeholder={`Select ${name}`} 
                        value={selectedKeys[0]}
                        onChange={e => setSelectedKeys(e ? [e] : [])}
                        style={{ width: 188, marginBottom: 8, display: 'block' }}
                    >
                        {array.map(item => (
                            <Option key={item[key]}>{item[value]}</Option>
                        ))}
                    </Select>
                    <Button
                        type="primary"
                        onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{ width: 90, marginRight: 8 }}
                    >
                        Search
                    </Button>
                    <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
                        Reset
                    </Button>
                </div>
            );
        },
        filterIcon: filtered => (

            <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
        ),
        onFilter: (value, record) => record[dataIndex] == value,
        render: text => text,
    }
    return result;
};

export default ColumnSelectSearchProps;