import { LogLevel } from '@azure/msal-browser';
import axios from 'axios';

const config = window['appSettings'];

async function GetFeePdfStatementData(token, clientId, dateFrom, dateTo) {
    try {
        return await AuthLink("Pdf/GetPdfFeeStatement", token, {clientId, dateFrom, dateTo});
    }
    catch(e) {
        

        throw e;
    }
}

async function AuthLink(url, token, params = null) {
    try {      
            var authorizationToken = sessionStorage.getItem("authorizationToken");

            const result = await axios.get(
            config.API_URL + url, 
            {
                headers: {
                authorization: `Bearer ${token}`,
                authorization2: `${authorizationToken}`
                },
                params: params,
                responseType:'blob'
            });
            return result.data;
    }
    catch(e) {
        throw GetError(e);
    }
}


async function GetData(url, token, params = null) {
    try {      
            var authorizationToken = sessionStorage.getItem("authorizationToken");

            const result = await axios.get(
            config.API_URL + url, 
            {
                headers: {
                authorization: `Bearer ${token}`,
                authorization2: `${authorizationToken}`
                },
                params: params
            });
        return result.data;
    }
    catch(e) {
        throw GetError(e);
    }
}

async function PutData(url, token, data) {
    try {      
            var authorizationToken = sessionStorage.getItem("authorizationToken");

            const result = await axios.put(
            config.API_URL + url, 
            data,
            {
                headers: {
                authorization: `Bearer ${token}`,
                authorization2: `${authorizationToken}`
                }
            });
        return result;
    }
    catch(e) {
        throw GetError(e);
    }
}

async function DeleteData(url, token, params) {
    try {   
        var authorizationToken = sessionStorage.getItem("authorizationToken");

        const result = await axios.delete(
        config.API_URL + url, 
        {
            headers: {
            authorization: `Bearer ${token}`,
            authorization2: `${authorizationToken}`
            },
            params: params
        });
    return result;
    }
    catch(e) {
        throw GetError(e);
    }
}

async function PostData(url, token, data) {
    try {      
            var authorizationToken = sessionStorage.getItem("authorizationToken");

            const result = await axios.post(
            config.API_URL + url, 
            data,
            {
                headers: {
                authorization: `Bearer ${token}`,
                authorization2: `${authorizationToken}`
                },
            });
        return result.data;
    }
    catch(e) {
        throw GetError(e);
    }
}

async function PostData2(url, token, data) {
    try {      
            var authorizationToken = sessionStorage.getItem("authorizationToken");
            const result = await axios.post(
            config.API_URL + url, 
            data,
            {
                headers: {
                authorization: `Bearer ${token}`,
                authorization2: `${authorizationToken}`
                },
            });
        return result;
    }
    catch(e) {
        throw GetError(e);
    }
}

async function PostFile(url, token, files) {
    try {      
            var authorizationToken = sessionStorage.getItem("authorizationToken");
            const formData = new FormData();
           // var file=files[0];
           // formData.append("formFile",file);
            //formData.append("fileName",file.name);
            for (let i = 0; i < files.length; i++) {
            formData.append('files', files[i]);
            }
            //  files.forEach((file, i) => {
            //      formData.append(i.toString(), file);
            //  });

            const result = await axios.post(
            config.API_URL + url, 
            formData,
            {
                headers: {
                authorization: `Bearer ${token}`,
                authorization2: `${authorizationToken}`//,
               // , 'content-type':'application/x-www-form-urlencode'
               , 'content-type': 'multipart/form-data'
                },
            });
        return result.data;
    }
    catch(e) {
        throw GetError(e);
    }
}


function GetError(error) {
    switch(error.response.status) {
        case 400: 
            return error.response.data;
        case 401:
            return "Unauthorized.";
        case 403:
            return "Forbidden";
        case 404:
            return "Not found.";
        case 406:
            return "Not found.";
        case 500:
            return error.response.data;
        default:
            return error.response;
    }
  }

async function GetTestMessage(token) {
    try {
        return await GetData("Test/TestUser", token);
    }
    catch(e) {
        throw e;
    }
  }

async function FindClients(token, clientCode = null, firstName = null, surname = null) {
    try {
        return await GetData("Client/Find", token, {clientCode, firstName, surname});
    }
    catch(e) {
        if (e === "Not found.")
            return {clients: null};

        throw e;
    }
}

async function GetHistory(token, clientCode){
    try {
        return await GetData("Client/History", token, {clientCode});
    }
    catch(e) {
        if (e === "Not found.")
            return {clients: null};

        throw e;
    }
}

async function GetMonthlyInvoiceData(token, clientCode){
    try {
        return await GetData("Client/MonthlyInvoiceData", token, {clientCode});
    }
    catch(e) {
        if (e === "Not found.")
            return {services: null};

        throw e;
    }
}

async function GetMonthlyDDData(token, clientCode){
    try {
        return await GetData("Client/MonthlyDDData", token, {clientCode});
    }
    catch(e) {
        if (e === "Not found.")
            return {services: null};

        throw e;
    }
}

async function GetOffices(token) {
    try {
        return await GetData("Staff/Offices", token);
    }
    catch(e) {
        if (e === "Not found.")
            return {offices: null};

        throw e;
    }
}

async function GetUnprintedInvoices(token, clientCode, officeCode, startDate, endDate) {
    try {
        return await GetData("Invoice/UnprintedInvoices", token, {clientCode, officeCode, startDate, endDate});
    }
    catch(e) {
        if (e === "Not found.")
            return {invoices: null};

        throw e;
    }
}

async function GetVatRate(token){
    try {
        return await GetData("Invoice/GetCurrent", token);
    }
    catch(e) {
        if (e === "Not found.")
            return null;

        throw e;
    }
}

async function PutVatRate(token, vatRate) {
    try {
        return await GetData("Invoice/SetCurrent", token, {vatRate});
    }
    catch(e) {
        return false
    }
}

async function GetStandardMonthlyFees(token){
    try {
        return await GetData("Fees/StdFees", token);
    }
    catch(e) {
        if (e === "Not found.")
            return null;

        throw e;
    }
}

async function UpdateStandardMonthlyFees(token, data){
    try {
        return await PostData("Fees/Update", token, data);
    }
    catch(e) {
        if (e === "Not found.")
            return null;

        throw e;
    }
}

async function GetReceipts(token) {
    try {
        return await GetData("Invoice/GetReceipts", token);
    }
    catch(e) {
        if (e === "Not found.")
            return {receipts: null};

        throw e;
    }
}

async function GetReceiptList(token, clientCode, officeCode, startDate, endDate) {
    try {
        return await GetData("Invoice/GetReceiptList", token, {clientCode, officeCode, startDate, endDate});
    }
    catch(e) {
        if (e === "Not found.")
            return {receipts: null};

        throw e;
    }
}

async function GetLodgements(token) {
    try {
        return await GetData("Invoice/GetLodgements", token);
    }
    catch(e) {
        if (e === "Not found.")
            return {lodgements: null, lodgementTypes: null};

        throw e;
    }
}

async function PostLodgement(token, data) {
    try {
        return await PostData("Invoice/PostLodgement", token, data);
    }
    catch(e) {
        if (e === "Not found.")
            return null;

        return e;
    }
}

async function PutLodgement(token, data) {
    try {
        return await PutData("Invoice/PutLodgement", token, data);
    }
    catch(e) {
        if (e === "Not found.")
            return null;

        return e;
    }
}

async function DeleteLodgement(token, lodgementNumber) {
    try {
        return await DeleteData("Invoice/DeleteLodgement", token, {lodgementNumber});
    }
    catch(e) {
        if (e === "Not found.")
            return null;

        return e;
    }
}

async function DeleteRefund(token, refundNumber, reason) {
    try {
        return await DeleteData("Invoice/DeleteRefund", token, {refundNumber, reason});
    }
    catch(e) {
        if (e === "Not found.")
            return null;

        return e;
    }
}

async function AssociateReceipts(token, data) {
    try {
        return await PutData("Invoice/Associate", token, data);
    }
    catch(e) {
        if (e === "Not found.")
            return null;

        return e;
    }
}

async function PostMonthlyInvocie(token, data) {
    try {
        return await PostData("Invoice/PostMonthlyInvoice", token, data);
    }
    catch(e) {
        if (e === "Not found.")
            return null;

        return e;
    }
}

async function PostMonthlyDD(token, data) {
    try {
        return await PostData("Invoice/PostMonthlyDD", token, data);
    }
    catch(e) {
        if (e === "Not found.")
            return null;

        return e;
    }
}

async function GetMonthlyAR(token) {
    try {
        return await GetData("Invoice/GetMonthlyAR", token);
    }
    catch(e) {
        if (e === "Not found.")
            return [];

        throw e;
    }
}

async function CreateMonthlyInvoices(token) {
    try {
        return await PostData("Invoice/CreateInvoices", token, null);
    }
    catch(e) {
        if (e === "Not found.")
            return null;

        return e;
    }
}

async function GenerateDirectDebits(token) {
    try {
        return await PostData("Invoice/GenerateDD", token, null);
    }
    catch(e) {
        if (e === "Not found.")
            return null;

        return e;
    }
}

async function IgnoreFailedInvoices(token) {
    try {
        return await PostData("Invoice/IgnoreInvoices", token, null);
    }
    catch(e) {
        if (e === "Not found.")
            return null;

        return e;
    }
}

async function PutGenerateDueDate(token, data) {
    try {
        return await PutData("Invoice/GenerateDueDate", token, data);
    }
    catch(e) {
        if (e === "Not found.")
            return null;

        return e;
    }
}

async function UpdateUnpaidDD(token) {
    try {
        return await PostData("Invoice/UpdateUnpaid", token);
    }
    catch(e) {
        if (e === "Not found.")
            return null;

        return e;
    }
}

async function AllocateReceipts(token) {
    try {
        return await PostData("Invoice/Allocate", token);
    }
    catch(e) {
        if (e === "Not found.")
            return null;

        return e;
    }
}

async function IssueFeeStatements(token) {
    try {
        return await PostData("Fees/IssueFeeStatements", token);
    }
    catch(e) {
        if (e === "Not found.")
            return null;

        return e;
    }
}
async function OffsetCredits(token) {
    try {
        return await PostData("Invoice/OffsetCredits", token);
    }
    catch(e) {
        if (e === "Not found.")
            return null;

        return e;
    }
}


async function GetUnpaidDD(token, officeCode) {
    try {
        return await GetData("Invoice/GetUnpaidDD", token, {officeCode});
    }
    catch(e) {
        if (e === "Not found.")
            return null;

        throw e;
    }
}

async function UpdateUnpaidDDs(token, data) {
    try {
        return await PostData("Invoice/UpdateUnpaidDDs", token, data);
    }
    catch(e) {
        if (e === "Not found.")
            return null;

        return e;
    }
}

async function GetMICData(token, clientCode, forCreditNote){
    try {
        return await GetData("Client/MICData", token, {clientCode, forCreditNote});
    }
    catch(e) {
        if (e === "Not found.")
            return null;

        throw e;
    }
}

async function PostMICData(token, data) {
    try {
        return await PostData2("Client/CreateMonthlyInvoice", token, data);
    }
    catch(e) {
        if (e === "Not found.")
            return null;

        return e;
    }
}

async function PostCreditNote(token, data) {
    try {
        return await PostData2("Client/CreateCreditNote", token, data);
    }
    catch(e) {
        if (e === "Not found.")
            return null;

        return e;
    }
}

async function GetSharesBoughtReport(token, startDate, endDate) {
    try {
        return await GetData("Reports/GetSharesBoughtReport", token, {startDate, endDate});
    }
    catch(e) {
        if (e === "Not found.")
            return {invoices: null};

        throw e;
    }
}

async function PostDebtorsControlAccount(token, data) {
    try {
        return await PostData("Reports/DebtorsControlAccount", token, data);
    }
    catch(e) {
        if (e === "Not found.")
            return null;

        return e;
    }
}

async function GetFeeStatementData(token, clientId, dateFrom, dateTo) {
    try {
        return await GetData("Client/FeeStatement", token, {clientId, dateFrom, dateTo});
    }
    catch(e) {
        if (e === "Not found.")
            return {invoices: null};

        throw e;
    }
}


async function GetJournalInfo(token, clientCode){
    try {
        return await GetData("Invoice/JournalInfo", token, {clientCode});
    }
    catch(e) {
        if (e === "Not found.")
            return {services: null};

        throw e;
    }
}

async function PostJournalData(token, data) {
    try {
        return await PostData2("Invoice/CreateJournal", token, data);
    }
    catch(e) {
        if (e === "Not found.")
            return null;

        return e;
    }
}

async function GetBadDebtsInfo(token, clientCode){
    try {
        return await GetData("Invoice/BadDebtsInfo", token, {clientCode});
    }
    catch(e) {
        if (e === "Not found.")
            return {services: null};

        throw e;
    }
}

async function PostWriteOffData(token, data) {
    try {
        return await PostData2("Invoice/CreateWriteOff", token, data);
    }
    catch(e) {
        if (e === "Not found.")
            return null;

        return e;
    }
}

async function GetAddRefundInfo(token, clientCode){
    try {
        return await GetData("Invoice/AddRefundInfo", token, {clientCode});
    }
    catch(e) {
        if (e === "Not found.")
            return {services: null};

        throw e;
    }
}

async function GetRefundList(token, clientCode, officeCode, startDate, endDate) {
    try {
        return await GetData("Invoice/GetRefundList", token, {clientCode, officeCode, startDate, endDate});
    }
    catch(e) {
        if (e === "Not found.")
            return {receipts: null};

        throw e;
    }
}

async function PostRefundData(token, data) {
    try {
        return await PostData2("Invoice/CreateRefund", token, data);
    }
    catch(e) {
        if (e === "Not found.")
            return null;

        return e;
    }
}
async function UploadFile(token,file){
    try{

        return await PostFile("File/UploadFile", token, file)
    }catch(e){
        console.log(e);
        return e;
    }
}

export {GetTestMessage};
export {FindClients};
export {GetHistory};
export {GetOffices};
export {GetUnprintedInvoices};
export {GetVatRate};
export {PutVatRate};
export {GetStandardMonthlyFees};
export {UpdateStandardMonthlyFees};
export {GetReceipts};
export {GetReceiptList};
export {GetLodgements};
export {PostLodgement};
export {PutLodgement};
export {DeleteLodgement};
export {AssociateReceipts};
export {GetMonthlyInvoiceData};
export {PostMonthlyInvocie};
export {GetMonthlyDDData};
export {PostMonthlyDD};
export {GetMonthlyAR};
export {CreateMonthlyInvoices};
export {IgnoreFailedInvoices};
export {GenerateDirectDebits};
export {PutGenerateDueDate};
export {UpdateUnpaidDD};
export {AllocateReceipts};
export {GetUnpaidDD};
export {UpdateUnpaidDDs};
export {GetMICData};
export {PostMICData};
export {PostCreditNote};
export {GetSharesBoughtReport};
export {PostDebtorsControlAccount};
export {GetFeeStatementData};
export {GetJournalInfo};
export {PostJournalData};
export {GetBadDebtsInfo};
export {PostWriteOffData};
export {GetAddRefundInfo};
export {GetRefundList};
export {PostRefundData};
export {DeleteRefund};
export {OffsetCredits};
export {UploadFile};
export {IssueFeeStatements};
export {GetFeePdfStatementData};