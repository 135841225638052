import "../Style/LodgementsStyle.css"
import { useMsal } from '@azure/msal-react';
import { Spin, Button, Table, Input, DatePicker, Menu, Dropdown, Select } from 'antd';
import { useEffect, useState } from 'react';
import ColumnSearchProps from "../Components/AntdExtended/ColumnSearchProps";
import ColumnSelectSearchProps from '../Components/AntdExtended/ColumnSelectSearchProps';
import ReconcileSearchProps from '../Components/AntdExtended/ReconciledSearchProps';
import { Find, FindIndex, HasValue, SendSignal, Wait } from '../Helper/JSHelper';
import { DownOutlined, SaveOutlined, DeleteOutlined, ArrowLeftOutlined, ArrowRightOutlined } from '@ant-design/icons';
import moment from 'moment';
import { AssociateReceipts, DeleteLodgement, GetLodgements, GetOffices, GetReceipts, PostLodgement, PutLodgement } from '../Data Layer/Data';
import { GetAccessToken } from '../Helper/JWTToken';
import ConfirmModal from '../Components/ConfirmModal';
import ReceiptTable from '../Components/ReceiptTable';
import NumericInput from '../Components/NumericInput';
import locale from 'antd/lib/locale/en_GB';
import { useBeforeunload } from 'react-beforeunload';
import { Prompt } from "react-router-dom";

const { RangePicker } = DatePicker;
const { Option } = Select;

let _reconciledVisibleRows = [];
let _unReconciledVisibleRows = [];

function LodgementsPage() {
    const msal = useMsal();
    const emptyLodgement = {
        lodgementNumber: null,
        lodgedDate: null,
        lodgedAmount: null,
        savedAmount: null,
        description: null,
        type: null,
        reconciled: 0,
        isNewRow: true,
        isEdited: false,
        key: 0
    };

    const initialArrowState = {
        disabled: false,
        aLoading: false,
        bLoading: false,
        cLoading: false,
        eLoading: false
    }
    const defRange = [moment().startOf("month"), moment()];

    const [isLoading, setIsLoading] = useState(false);
    const [lodgements, setLodgements] = useState([]);
    const [lodgementTypes, setLodgementTypes] = useState([]);
    const [savedLodgements, setSavedLodgements] = useState([]);
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [receipts, setReceipts] = useState([]);
    const [selectedReceipt, setSelectedReceipt] = useState(null);
    const [arrowState, setArrowState] = useState(initialArrowState);
    const [dateRange, setDateRange] = useState(defRange);
    const [offices, setOffices] = useState();
    const [resetReconcilled, setResetReconcilled] = useState(false);
    const [resetUnReconcilled, setResetUnReconcilled] = useState(false);
    const [reconciledVisibleRows, setReconciledVisibleRows] = useState([]);
    const [unReconciledVisibleRows, setUnReconciledVisibleRows] = useState([]);

    useBeforeunload((e) => {
        if (lodgements[0].isEdited)
            e.returnValue = true;
    });

    useEffect(async () => {
        setIsLoading(true);

        const token = await GetAccessToken(msal);
        const lodgementData = await GetLodgements(token);
        const receiptData = await GetReceipts(token);
        const officeData = await GetOffices(token);

        let reconciledAmounts = [];
        for (let i = 0; i < receiptData.receipts.length; i++) {
            receiptData.receipts[i].key = i;
            receiptData.receipts[i].amountString = receiptData.receipts[i].amount.toLocaleString(undefined, { minimumFractionDigits: 2 });

            if (HasValue(receiptData.receipts[i].lodgementId)) {
                const index = FindIndex(reconciledAmounts, "id", receiptData.receipts[i].lodgementId)

                if (HasValue(index)) {
                    reconciledAmounts[index].reconciled += receiptData.receipts[i].amount;
                }
                else
                    reconciledAmounts.push({
                        id: receiptData.receipts[i].lodgementId,
                        reconciled: receiptData.receipts[i].amount
                    })
            }
        }

        let newLodgements = [];
        let newSavedLodgements = [];
        let types = [];

        newLodgements.push(emptyLodgement);

        lodgementData.lodgements.forEach(lodgement => {

            let rec = 0;
            const amount = Find(reconciledAmounts, "id", lodgement.lodgementId);
            if (HasValue(amount))
                rec = amount.reconciled;

            const newLodgement = {
                lodgementNumber: lodgement.lodgementNumber,
                lodgedDate: lodgement.lodgementDate,
                lodgedAmount: lodgement.lodgementAmount,
                savedAmount: lodgement.lodgementAmount,
                description: lodgement.lodgementDescription,
                type: lodgement.lodgementTypeId,
                reconciled: rec,
                isNewRow: false,
                isEdited: false,
                key: lodgement.lodgementId
            };
            newLodgements.push(newLodgement);
            newSavedLodgements.push({ ...newLodgement });
        });

        lodgementData.lodgementTypes.forEach(type => {
            let newType = {
                typeId: type.lodgementTypeId,
                typeDesc: type.lodgementTypeDesc,
            }

            types.push(newType)
        });

        setLodgementTypes(types);
        setLodgements(newLodgements);
        setSavedLodgements(newSavedLodgements);
        setReceipts(receiptData.receipts);
        setOffices(officeData.offices);

        setIsLoading(false);
    }, [])

    const dateChanged = (e, key) => {
        let copy = lodgements.slice();
        let lodgement = Find(copy, "key", key);
        lodgement.lodgedDate = e._d;
        lodgement.isEdited = true;

        setLodgements(copy);
    }

    const amountChanged = (val, key) => {
        if (isNaN(val))
            return;

        if (val < 0)
            return;

        let copy = lodgements.slice();
        let lodgement = Find(copy, "key", key);

        lodgement.lodgedAmount = val;
        lodgement.isEdited = true;

        setLodgements(copy);
    }

    const descriptionChanged = (e, key) => {
        e.preventDefault();
        let copy = lodgements.slice();
        let lodgement = Find(copy, "key", key);
        lodgement.description = e.target.value;
        lodgement.isEdited = true;

        setLodgements(copy);
    }

    const typeChanged = (typeId, key) => {
        let copy = lodgements.slice();
        let lodgement = Find(copy, "key", key);
        lodgement.type = typeId;
        lodgement.isEdited = true;

        setLodgements(copy);
    }

    const addLodgement = async () => {
        let copy = lodgements.slice();
        let addRow = copy[0];

        const token = await GetAccessToken(msal);
        const data = {
            lodgementAmount: Number(addRow.lodgedAmount),
            lodgementDescription: addRow.description,
            lodgementTypeId: addRow.type,
            lodgementDate: addRow.lodgedDate
        }
        const result = await PostLodgement(token, data);

        if (HasValue(result)) {
            let newLodgement = {
                lodgementNumber: result.lodgementNumber,
                lodgedDate: addRow.lodgedDate,
                lodgedAmount: addRow.lodgedAmount,
                description: addRow.description,
                type: addRow.type,
                reconciled: 0,
                isNewRow: false,
                isEdited: false,
                key: result.lodgementId
            }

            addRow = emptyLodgement;
            copy.splice(1, 0, newLodgement)
            copy[0] = addRow;

            setLodgements(copy);

            let copy2 = savedLodgements.slice();
            copy2.splice(1, 0, { ...newLodgement });

            setSavedLodgements(copy2);
        }
    }

    const saveLodgement = async (key) => {
        let copy = lodgements.slice();
        let lodgement = Find(copy, "key", key);

        const token = await GetAccessToken(msal);
        const data = {
            lodgementNumber: lodgement.lodgementNumber,
            lodgementAmount: Number(lodgement.lodgedAmount),
            lodgementDescription: lodgement.description,
            lodgementTypeId: lodgement.type,
            lodgementDate: lodgement.lodgedDate
        }
        const result = await PutLodgement(token, data);
        if (result.status == 200) {
            lodgement.isEdited = false;
            lodgement.savedAmount = Number(lodgement.lodgedAmount);

            setLodgements(copy);

            let copy2 = savedLodgements.slice();
            const index = FindIndex(copy2, "key", key);
            copy2[index] = { ...lodgement };

            setSavedLodgements(copy2);
        }
    }

    const deleteLodgement = (key) => {
        const callback = async () => {
            let copy = lodgements.slice();
            const index = FindIndex(copy, "key", key);

            const token = await GetAccessToken(msal);
            const result = await DeleteLodgement(token, copy[index].lodgementNumber);
            if (result.status == 200) {
                setSelectedRowKeys([]);
                copy.splice(index, 1);

                setLodgements(copy);

                let copy2 = savedLodgements.slice();
                copy2.splice(index, 1);

                setSavedLodgements(copy2);

                const copy3 = receipts.slice();
                copy3.forEach(receipt => {
                    if (HasValue(receipt.lodgementId) && receipt.lodgementId == selectedRowKeys[0])
                        receipt.lodgementId = null;
                });

                setReceipts(copy3);
                setSelectedReceipt(null);
            }
        }

        ConfirmModal("Are you sure you want to delete this lodgement?", callback);
    }

    function calculateUnreconciled(x) {
        var value = x?.lodgedAmount ?? 0;
        if (HasValue(x?.reconciled))
            value = Math.round((value - x.reconciled) * 100) / 100;

        //This is necessary to get rid of -0... because js   
        if (value === 0)
            value = 0;

        return value;
    }

    function lodgementsSort(a, b, sortOrder, prop, func = null) {
        if (a.key === 0) {
            if (sortOrder === 'ascend')
                return -1;
            if (sortOrder === 'descend')
                return 1;
        }
        if (b.key === 0) {
            if (sortOrder === 'ascend')
                return 1;
            if (sortOrder === 'descend')
                return -1;
        }
        if (func === null)
            return (a[prop] < b[prop]) ? -1 : 1;
        else
            return (func(a) < func(b)) ? -1 : 1;
    }

    const lodgementColumns = [
        {
            title: 'Lodgement Number',
            dataIndex: 'lodgementNumber',
            key: 'lodgementNumber',
            align: 'right',
            sorter: (a, b, sortOrder) => lodgementsSort(a, b, sortOrder, 'lodgementNumber'),
            ...ColumnSearchProps("lodgementNumber", "Lodgement Number"),

        },
        {
            title: 'Lodged Date',
            dataIndex: 'lodgedDate',
            key: 'lodgedDate',
            align: 'center',
            sorter: (a, b, sortOrder) => lodgementsSort(a, b, sortOrder, 'lodgedDate'),
            render: (text, record) => <DatePicker locale={locale} format="DD-MM-YYYY" value={HasValue(record.lodgedDate) ? moment(record.lodgedDate) : null} onChange={(e) => dateChanged(e, record.key)} allowClear={false} />
        },
        {
            title: 'Lodged Amount',
            dataIndex: 'lodgedAmount',
            key: 'lodgedAmount',        
            align: 'center',
            sorter: (a, b, sortOrder) => lodgementsSort(a, b, sortOrder, 'lodgedAmount'),
            ...ColumnSearchProps("lodgedAmount", "Lodged Amount", true),
            render: (text, record) => <NumericInput style={{ textAlign: "right" }} value={record.lodgedAmount} setValue={(e) => amountChanged(e, record.key)} />
        },
        {
            title: 'Description',
            dataIndex: 'description',
            key: 'description',
            align: 'center',
            ...ColumnSearchProps("description", "Description"),
            render: (text, record) => <Input style={{ width: "100%" }} value={record.description} onChange={(e) => descriptionChanged(e, record.key)} />
        },
        {
            title: 'Type',
            dataIndex: 'type',
            key: 'type',
            align: 'center',
            sorter: (a, b, sortOrder) => lodgementsSort(a, b, sortOrder, 'type'),
            ...ColumnSelectSearchProps("type", "Type", lodgementTypes, "typeId", "typeDesc"),
            render: (text, record) =>
                <Select value={Find(lodgementTypes, "typeId", record.type)?.typeDesc} style={{ minWidth: "164px" }} onChange={(e) => typeChanged(Number(e), record.key)}>
                    {lodgementTypes.map(type => (
                        <Option key={type.typeId}>{type.typeDesc}</Option>
                    ))}
                </Select>
        },
        {
            title: 'Reconciled Amount',
            align: 'right',
            sorter: (a, b, sortOrder) => lodgementsSort(a, b, sortOrder, 'reconciled'),
            ...ReconcileSearchProps(),
            defaultFilteredValue:['1'],
            render: (text, record) => {
                if (record.isNewRow)
                    return null;
                else
                    return (
                        <p>{record.reconciled?.toLocaleString(undefined, { minimumFractionDigits: 2 })}</p>
                    );
            }
        },
        {
            title: 'Unreconciled Amount',
            align: 'right',
            sorter: (a,b, sortOrder) => lodgementsSort(a, b, sortOrder, 'type', calculateUnreconciled),
            render: (text, record) => {
                var value = calculateUnreconciled(record);

                if (record.isNewRow)
                    return null;
                else
                    return (
                        <p>{value?.toLocaleString(undefined, { minimumFractionDigits: 2 })}</p>
                    );
            }
        },
        {
            title: '',
            render: (text, record) => {

                if (record.isNewRow)
                    return (
                        <Button className="btn-save-icon" icon={<SaveOutlined />} disabled={!HasValue(record.lodgedDate) || !HasValue(record.lodgedAmount)} onClick={addLodgement} />
                    )
                else {
                    var saveDisabled = !HasValue(record.lodgedDate) || !HasValue(record.lodgedAmount) || selectedRowKeys.length == 0 || selectedRowKeys[0] !== record.key || !record.isEdited;
                    var deleteDisabled = selectedRowKeys.length == 0 || selectedRowKeys[0] !== record.key
                    return (
                        <div style={{ display: 'flex', flexDirection: "row", justifyContent: "flex-start" }}>
                            <Button className="btn-save-icon" icon={<SaveOutlined />} disabled={saveDisabled} onClick={() => saveLodgement(record.key)} />
                            <Button className="btn-delete-icon" icon={<DeleteOutlined />} disabled={deleteDisabled} onClick={() => deleteLodgement(record.key)} />
                        </div>
                    )
                }
            }
        }
    ];

    const selectRow = (record) => {
        if (selectedRowKeys.length === 0 || selectedRowKeys[0] != record.key) {

            if (selectedRowKeys.length > 0) {
                let copy = lodgements.slice();
                let index = FindIndex(copy, "key", selectedRowKeys[0]);

                //Check if row has been edited
                if (HasValue(copy[index]) && copy[index].isEdited && !copy[index].isNewRow) {

                    const callback = () => {
                        const savedLodgement = Find(savedLodgements, "key", selectedRowKeys[0]);
                        copy[index] = { ...savedLodgement };

                        setLodgements(copy);

                        setSelectedReceipt(null);
                        setSelectedRowKeys([record.key]);
                        _reconciledVisibleRows = [];
                        _unReconciledVisibleRows = [];
                    }

                    ConfirmModal("Changes made to this lodgement will be lost. Do you wish to proceed?", callback);
                }
                else {
                    setSelectedReceipt(null);
                    setSelectedRowKeys([record.key]);
                    _reconciledVisibleRows = [];
                    _unReconciledVisibleRows = [];
                }
            }
            else {
                setSelectedReceipt(null);
                setSelectedRowKeys([record.key]);
            }
        }
    }

    const getUnrecnciledReceipts = () => {
        let list = [];
        const copy = receipts.slice();
        copy.forEach(receipt => {
            if (!HasValue(receipt.lodgementId))
                list.push(receipt)
        });

        return list;
    }

    const getReceiptsForLodgement = (key) => {
        let list = [];
        const copy = receipts.slice();
        copy.forEach(receipt => {
            if (receipt.lodgementId === key)
                list.push(receipt)
        });

        return list;
    }

    const reconcileAllReceipts = async () => {
        setArrowState({
            disabled: true,
            aLoading: false,
            bLoading: false,
            cLoading: true,
            eLoading: false
        });

        await Wait(10);

        const copy = receipts.slice();
        const data = {
            receipts: []
        };

        _unReconciledVisibleRows.forEach(row => {
            let receipt = Find(copy, "key", row);
            if (!HasValue(receipt))
                return;

            if (!HasValue(receipt.lodgementId)) {
                receipt.lodgementId = selectedRowKeys[0];

                data.receipts.push({
                    RecordNo: receipt.recordNo,
                    LodgementId: selectedRowKeys[0]
                });
            }
        });

        const token = await GetAccessToken(msal);
        const result = await AssociateReceipts(token, data);

        if (result?.status == 200) {
            setReceipts(copy);
            calculateReconciledAmount();
        }

        setArrowState(initialArrowState);
    };

    const reconcileReceipt = async () => {
        if (HasValue(selectedReceipt)) {
            setArrowState({
                disabled: true,
                aLoading: true,
                bLoading: false,
                cLoading: false,
                eLoading: false
            });

            const copy = receipts.slice();
            let receipt = Find(copy, "key", selectedReceipt);
            const data = {
                Receipts: [
                    {
                        RecordNo: receipt.recordNo,
                        LodgementId: selectedRowKeys[0]
                    }
                ]
            }

            const token = await GetAccessToken(msal);
            const result = await AssociateReceipts(token, data);

            if (result?.status == 200) {
                receipt.lodgementId = selectedRowKeys[0];

                setReceipts(copy);
                calculateReconciledAmount();
            }

            setArrowState(initialArrowState);
        }
    }

    const unReconcileAllReceipts = async () => {
        setArrowState({
            disabled: true,
            aLoading: false,
            bLoading: false,
            cLoading: false,
            eLoading: true
        });

        await Wait(10);

        const copy = receipts.slice();

        const data = {
            receipts: []
        };

        _reconciledVisibleRows.forEach(row => {
            let receipt = Find(copy, "key", row);
            if (!HasValue(receipt))
                return;

            if (HasValue(receipt.lodgementId) && receipt.lodgementId == selectedRowKeys[0]) {
                receipt.lodgementId = null;

                data.receipts.push({
                    RecordNo: receipt.recordNo,
                    LodgementId: null
                });
            }
        });

        const token = await GetAccessToken(msal);
        const result = await AssociateReceipts(token, data);

        if (result?.status == 200) {
            setReceipts(copy);
            calculateReconciledAmount();
        }

        setArrowState(initialArrowState);
    }

    const unReconcileReceipt = async (key) => {
        if (HasValue(selectedReceipt)) {
            setArrowState({
                disabled: true,
                aLoading: false,
                bLoading: true,
                cLoading: false,
                eLoading: false
            });

            const copy = receipts.slice();
            let receipt = Find(copy, "key", selectedReceipt)

            const data = {
                Receipts: [
                    {
                        RecordNo: receipt.recordNo,
                        LodgementId: null
                    }
                ]
            }

            const token = await GetAccessToken(msal);
            const result = await AssociateReceipts(token, data);

            if (result?.status == 200) {
                receipt.lodgementId = null;

                setReceipts(copy);
                calculateReconciledAmount();
            }

            setArrowState(initialArrowState);
        }
    }

    const calculateReconciledAmount = () => {
        const copy = lodgements.slice();
        const selectedLodgement = Find(copy, "key", selectedRowKeys[0]);
        let reconciled = 0;

        receipts.forEach(receipt => {
            if (receipt.lodgementId === selectedRowKeys[0])
                reconciled += receipt.amount;
        });

        selectedLodgement.reconciled = reconciled;
        setLodgements(copy);
    }

    const onDateRangeChanged = (value) => {
        setDateRange(value);
    }

    const LodgementsTitle = () => {
        return (
            <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-start", alignItems: "center" }}>
                <h3>Lodgements</h3>
                <RangePicker style={{ marginLeft: "50px", width: "250px" }}  format="DD-MM-YYYY" value={dateRange} onChange={onDateRangeChanged} allowEmpty={[true, true]} allowClear={true}></RangePicker>
            </div>
        )
    }

    const filterLodgements = (lodgements, dateRange) => {

        let filterLodgements = [];

        lodgements.forEach(lodgement => {
            if (lodgement.key === 0 || !HasValue(dateRange))
                filterLodgements.push(lodgement)
            else {
                const date = moment(lodgement.lodgedDate);

                if (HasValue(dateRange[0]) && date < dateRange[0].startOf("day"))
                    return;

                if (HasValue(dateRange[1]) && date > dateRange[1].endOf("day"))
                    return;

                filterLodgements.push(lodgement);
            }
        });

        return filterLodgements;
    }

    const anyIsEdited = (array) => {
        if (FindIndex(array, "isEdited", true) > -1)
            return true;
        return false;
    }

    const totalsRow = (pageData) => {
        let totalLodged = 0;
        let totalReconciled = 0;

        pageData.forEach(({ lodgedAmount, reconciled, key }) => {
            if (key == 0)
                return;

            totalLodged += HasValue(lodgedAmount) ? Number(lodgedAmount) : 0;
            totalReconciled += HasValue(reconciled) ? Number(reconciled)  : 0;
        });

        return (
            <Table.Summary.Row>
              <Table.Summary.Cell className="text-align-center">Total</Table.Summary.Cell>
              <Table.Summary.Cell></Table.Summary.Cell>
              <Table.Summary.Cell className="text-align-center">{totalLodged.toLocaleString(undefined, { minimumFractionDigits: 2 })} </Table.Summary.Cell>
              <Table.Summary.Cell></Table.Summary.Cell>
              <Table.Summary.Cell></Table.Summary.Cell>
              <Table.Summary.Cell>{totalReconciled.toLocaleString(undefined, { minimumFractionDigits: 2 })}</Table.Summary.Cell>
              <Table.Summary.Cell>{(totalLodged - totalReconciled).toLocaleString(undefined, { minimumFractionDigits: 2 })}</Table.Summary.Cell>
              <Table.Summary.Cell></Table.Summary.Cell>
            </Table.Summary.Row>
        );
    };

    const getReconciledTitle = () => {
        const lodgement = Find(lodgements, "key", selectedRowKeys[0]);
        const unreconciled = calculateUnreconciled(lodgement);
        return `Reconciled Receipts - Lodgement Number ${lodgement.lodgementNumber} - ${unreconciled.toLocaleString(undefined, { minimumFractionDigits: 2 })} unreconciled`
    }

    const setUnreconciled = (x) => {
        _unReconciledVisibleRows = x;
    }

    if (isLoading)
        return (
            <div style={{ width: "100%", height: "100%", minHeight: "inherit", display: "flex", alignItems: "center", justifyContent: "center" }}>
                <Spin size="large" />
            </div>
        )
    else return (
        <div className="div-lodgements" style={{ width: "100%", height: "100%", overflowX: "auto", overflowY: "auto" }}>
            <form>
            <div className="div-ant-table div-selectable-rows" style={{ overflowX: "hidden", minWidth: "1200px" }}>                
                <Table title={() => LodgementsTitle()} dataSource={filterLodgements(lodgements, dateRange)} columns={lodgementColumns} pagination={false} rowSelection={{ selectedRowKeys, type: "radio", columnWidth: "0px" }} onRow={(record) => ({ onClick: () => { selectRow(record); }, })} summary={totalsRow}></Table>                
            </div>
            </form>
            {(selectedRowKeys.length > 0 && selectedRowKeys[0] !== 0) &&
                <div className="div-ant-table  div-selectable-rows" style={{ display: 'flex', flexDirection: "row", justifyContent: "flex-start", width: "100%", minWidth: "1200px", overflowX: "hidden" }}>
                    <ReceiptTable receipts={getReceiptsForLodgement(selectedRowKeys[0])} title={getReconciledTitle()} callback={(key) => { setSelectedReceipt(key); SendSignal(setResetUnReconcilled); }} resetRow={resetReconcilled} setVisibleRows={(x) => {_reconciledVisibleRows = x;}}/>
                    <div style={{ display: 'flex', flexDirection: "column", justifyContent: "flex-start", alignItems: "center", marginLeft: "10px", marginRight: "10px", paddingTop: "105px" }}>
                        <Button style={{ marginTop: "10px" }} className="btn-save" disabled={arrowState.disabled} icon={<ArrowLeftOutlined />} loading={arrowState.aLoading} onClick={reconcileReceipt} ></Button>
                        <Button style={{ marginTop: "10px" }} className="btn-save" disabled={arrowState.disabled} icon={<ArrowRightOutlined />} loading={arrowState.bLoading} onClick={unReconcileReceipt} ></Button>
                        <Button style={{ marginTop: "10px" }} className="btn-save" disabled={arrowState.disabled} icon={<ArrowLeftOutlined />} loading={arrowState.cLoading} onClick={reconcileAllReceipts} >All</Button>
                        <Button style={{ marginTop: "10px" }} className="btn-save" disabled={arrowState.disabled} icon={<ArrowRightOutlined />} loading={arrowState.eLoading} onClick={unReconcileAllReceipts} >All</Button>
                    </div>
                    <ReceiptTable receipts={getUnrecnciledReceipts()} offices={offices} title="Unreconciled Receipts" callback={(key) => { setSelectedReceipt(key); SendSignal(setResetReconcilled); }} resetRow={resetUnReconcilled} setVisibleRows={setUnreconciled}/>
                </div>
            }
            <Prompt when={anyIsEdited(lodgements)} message={() => `Changes you made may not be saved!`} />
        </div>
    )
}

export default LodgementsPage;