import { Page, Text, View, Document, Image, StyleSheet, Font, Link } from '@react-pdf/renderer';
import { HasValue } from '../Helper/JSHelper';
import { _fontCalibri, _fontCalibriBold, _fontCalibriItalic, _imgGreenFields, _imgIacLogoWhite, _imgIfacLogoLong } from '../Resources/Resources';

function PDFRefund ({refund}) {

    Font.register({family: "Calibri", src:_fontCalibri});
    Font.register({family: "Calibri", fontStyle:"normal", fontWeight:"bold", src:_fontCalibriBold});
    Font.register({family: "Calibri", fontStyle:"italic", fontWeight:"normal", src:_fontCalibriItalic});

    const styles = StyleSheet.create({
        page: {
          marginLeft:"20px",
          paddingRight:"40px",
          marginTop:"10px",
          marginBottom:"10px",
          paddingBottom:"10px",
          fontSize:"12px",
          fontFamily:"Calibri",
          height:"100%"
        },
        watermarkCancelled:{
          position:"absolute",
          bottom:"40%",
          left:"10%",
          transform:"rotate(-30deg)",
          color:"red",
          opacity:"0.4",
          fontWeight:"bold",
          fontSize:"90"
        }
      });   

    let factor = 1;
      if (!HasValue(refund.clientAddress1))
        factor++;
      if (!HasValue(refund.clientAddress2))
        factor++;
      if (!HasValue(refund.clientAddress3))
        factor++;
      if (!HasValue(refund.clientAddress4))
        factor++;
      if (!HasValue(refund.clientAddress5))
        factor++;

      let variableSpace = 12 * factor + "px";

      var officeAddress= '';
if (HasValue(refund.address1Office)){
  officeAddress+= refund.address1Office;
}
if (HasValue(refund.address2Office)){
  officeAddress+=', '+ refund.address2Office;
}
if (HasValue(refund.address3Office)){
  officeAddress+=', '+ refund.address3Office;
}
if (HasValue(refund.address4Office)){
  officeAddress+=', '+ refund.address4Office;
}
if (HasValue(refund.address5Office)){
  officeAddress+=', '+ refund.address5Office;
}
if (HasValue(refund.address6Office)){
  officeAddress+=', '+ refund.address6Office;
}
if (HasValue(refund.eircodeOffice)){
  officeAddress+=', '+ refund.eircodeOffice;
}
var secondOfficeAddress=''
if (HasValue(refund.phone1Office)){
  secondOfficeAddress+=refund.phone1Office;
}
if (HasValue(refund.officeEmail)){
  secondOfficeAddress+='|'+refund.officeEmail;
}
if (HasValue(secondOfficeAddress))
{
  secondOfficeAddress+=('| www.ifac.ie');
}else{
  secondOfficeAddress='www.ifac.ie';
} 
  return (
    <Document title={`Refund ${refund.refundNo}`}>
      <Page size={'A4'} style={styles.page}>    
        <View style={{ height: "100%" }}>
          <View>
            <View style={{minHeight:"50px"}}>
              <Image src={_imgIfacLogoLong}></Image>
            </View>
            <View style={{ flexDirection: "row", justifyContent: "space-between", marginTop: "15px", marginBottom: "20px" }}>
              <View style={{ width: "20%" }}></View>
              <Text>Ref:   {`${refund.clientCode}/${refund.officeCode}/${refund.staffCode}`}</Text>
              <Text>Date: {refund.createdDateStr}</Text>
            </View>
            <View style={{minHeight:"30px", backgroundColor: "#00ABC7", padding: "5px", paddingBottom: "2px", flexDirection: "row", justifyContent: "center", border: "1px" }}>
              <Text style={{ color: "white", fontSize: "20", fontWeight: "bold" }}>Remittance Advice</Text>
            </View>

            <View style={{ width: "100%", flexDirection: "row", justifyContent: "flex-end", marginTop: "5px", minHeight:"12px"  }}>
              <Text style={{minHeight:"12px"}}>Number: {refund.refundNo}</Text>
            </View>
            <View style={{marginLeft: "40px", minHeight:"84px"}}>
              <Text style={{minHeight:"12px"}}>{refund.clientTitle} {refund.clientName}</Text>
              {HasValue(refund.clientAddress1) &&
                <Text style={{minHeight:"12px"}}>{refund.clientAddress1}</Text>}
              {HasValue(refund.clientAddress2) &&
                <Text style={{minHeight:"12px"}}>{refund.clientAddress2}</Text>}
              {HasValue(refund.clientAddress3) &&
                <Text style={{minHeight:"12px"}}>{refund.clientAddress3}</Text>}
              {HasValue(refund.clientAddress4) &&
                <Text style={{minHeight:"12px"}}>{refund.clientAddress4}</Text>}
              {HasValue(refund.clientAddress5) &&
                <Text style={{minHeight:"12px"}}>{refund.clientAddress5}</Text>}
                <View style={{minHeight:variableSpace, height:variableSpace}}></View>
            </View>
            
          </View>
          <View style={{ flexDirection: "column", justifyContent: "flex-end" }}>
            <View style={{minHeight:"25px", backgroundColor: "#00ABC7", padding: "5px", justifyContent: "start", marginTop: "30px", marginBottom: "10px", border: "1px" }}>
              <Text style={{ color: "white", fontWeight: "bold" }}>Amount Refunded</Text>
            </View>
            <View style={{ flexDirection: "column", justifyContent: "space-between", height:"100%" }}>
                  { HasValue(refund.dateDeleted) &&
                  <View style={styles.watermarkCancelled}>
                    <Text>CANCELLED {new Date(refund.dateDeleted).toLocaleDateString("en-GB")}</Text>
                  </View> 
                  }
                <View style={{ flexDirection:"column", justifyContent:"flex-start", height:"50%"}}>
                    <View style={{ flexDirection:"row", justifyContent:"flex-start", height:"100%"}}>
                        <View style={{ flexDirection: "column", justifyContent: "space-around", marginRight:"40px" }}>
                                  <Text style={{fontWeight: "bold"}}>Total Refunded:</Text>
                                <Text style={{fontWeight: "bold"}}>In respect of:</Text>
                                <Text style={{fontWeight: "bold"}}>Signed:</Text>
                        </View>                
                        <View style={{ flexDirection: "column", justifyContent: "space-around" }}>
                                <Text>{`€${refund.amount?.toLocaleString(undefined, { minimumFractionDigits: 2 })}`}</Text>
                                <Text>Invoice {refund.invoiceNo}</Text>
                                <Text>___________________________</Text>
                        </View> 
                    </View>   
                    <View style={{alignItems:"center"}}>
                        <Text style={{fontWeight: "bold"}}>Thanking you for choosing Ifac. We appreciate your business.</Text>
                    </View>
                </View>           
                <View style={{ flexDirection: "column", justifyContent: "space-around", alignItems: "center", height: "15%" }}>
                    <Text style={{fontWeight: "bold", fontSize:"20px"}}>Anticipating. Informing. Growing.</Text>
                    <Text style={{fontWeight: "bold"}}>Accountants and financial advisors for forward-looking businesses in Ireland</Text>
                </View>
                <View style={{ flexDirection:"column", justifyContent: "space-around", alignItems: "center" ,marginBottom:"10px"}}>             
                  <Text > {officeAddress}</Text>                            
                  <Text style={{fontSize:"10px"}} >{secondOfficeAddress} </Text>
              </View>  
            </View>
          </View>
        </View>
      </Page>
    </Document>
        );
}

export default PDFRefund;