import { useMsal } from "@azure/msal-react";
import { Button, Dropdown, Input, Menu }  from 'antd'; 
import MenuItem from "antd/lib/menu/MenuItem";
import { useEffect, useState } from "react";
import { FindClients } from "../Data Layer/Data";
import { HasValue } from "../Helper/JSHelper";
import { GetAccessToken } from "../Helper/JWTToken";

function ClientCodeSelect({index, onSelect, clientCode, onEnter}) {

    const [search, setSearch] = useState("");
    const [clientVisible, setClientVisible] = useState(false);
    const [clientDropdown, setClientDropdown] = useState(<></>);
    const msal = useMsal();

    useEffect(async () => {
        if (!HasValue(clientCode))
            setSearch("");
    }, [clientCode]);

    const onSearchChange = (e) => {
        e.preventDefault();
        setSearch(e.target.value)
    }

    const onSearchClick = async () => {
        const token = await GetAccessToken(msal);

        let searchTerms = search.split(' ');
        let code = null;
        let firstName = null;
        let surname = null

        for (let i = 0; i < searchTerms.length; i++) {
            //Check if search term is numeric
            if (isNaN(searchTerms[i]))
                //If not numeric and last term, assume surname
                if (i === searchTerms.length - 1)
                    surname = searchTerms[i]
                //else assume first name
                else
                    firstName = searchTerms[i];
            //if numeric assume code
            else
                code = searchTerms[i];
        }

        const data = await FindClients(token, code, firstName, surname);
        const setClient = async(code) => {
            setSearch("" + code);
            await onSelect(index, code);

            setClientVisible(false);
        };

        // if there is only one client, select automatically
        if (HasValue(data.clients) && data.clients.length === 1) {
            await setClient(data.clients[0].code)
            setClientDropdown(GenerateClientsDropdown(data.clients, setClient, setClientVisible));
        }
        else {
            setClientDropdown(GenerateClientsDropdown(data.clients, setClient, setClientVisible));
            //This is used to force the dropdown to appear without mouse-over
            setClientVisible(true);
        }
    }

    //Used to close client dropdown on outside click
    const doc = document.getElementById('root');
    
    function closeDropdownHandler (e) {
        setClientVisible(false);
        doc.removeEventListener("click", closeDropdownHandler);
    }
    
    if (clientVisible) 
        doc.addEventListener("click", closeDropdownHandler);

    return (
        <div style={{display:"flex", justifyContent:"flex-start", alignItems:"center"}}>
            {clientVisible ?
            <Dropdown overlay={clientDropdown} visible={clientVisible} >
                    <Input.Search placeholder="Client search" value={search} onChange={onSearchChange} onSearch={onSearchClick} style={{width: "200px"}} onPressEnter={onEnter}/>
            </Dropdown>
            :
            <Dropdown overlay={clientDropdown} >
                    <Input.Search placeholder="Client search" value={search} onChange={onSearchChange} onSearch={onSearchClick} style={{width: "200px"}} onPressEnter={onEnter}/>
            </Dropdown>
            
            }
        </div>
    )
}

function GenerateClientsDropdown(clientList, callback) {

    if (clientList == null)
        return (
            <Menu>
                <p className="error-text">No clients were found!</p>
            </Menu>
        );

    else {
        return (
            <Menu style={{maxHeight: '400px', overflowY:'auto'}}>
                {
                    clientList.map((client, i) =>
                        <Menu.Item key={i}>
                            <a onClick={() => {callback(client.code, client.title, client.firstName, client.surname)}}>
                                {`${client.code} ${client.firstName} ${client.surname}`}
                            </a>
                        </Menu.Item>
                    )
                }
            </Menu>
        )
    }
}

export default ClientCodeSelect;