
import { useEffect, useState, useContext } from "react";
import { Select, Spin, Table, Checkbox } from "antd";
import { useMsal } from "@azure/msal-react";
import { GetAccessToken } from "../Helper/JWTToken";
import { MainContext } from "../Components/Context";
import { GetUnpaidDD, UpdateUnpaidDDs } from "../Data Layer/Data";
import { Authorize, CheckAuthorization } from "../Helper/Authorization";
import { CopyList, Find, FindAll, FindIndex, HasValue } from "../Helper/JSHelper";
import StandardSelect from "../Components/StandardSelect";
import ConfirmModal from "../Components/ConfirmModal";
import SaveButton from "../Components/SaveButton";
import CancelButton from "../Components/CancelButton";
import ColumnSearchProps from "../Components/AntdExtended/ColumnSearchProps";
import AlertModal from "../Components/AlertModal";

function UnpaidDDPage() {
    const context = useContext(MainContext);
    const msal = useMsal();

    const [isLoading, setIsLoading] = useState(false);
    const [editAuthorized, setEditAuthorized] = useState(false);
    const [ddData, setddData] = useState(null);
    const [unpaidDDs, setUnpaidDDs] = useState(null);
    const [monthList, setMonthList] = useState([]);
    const [displayMonths, setDisplayMonths] = useState([]);
    const [selectedMonth, setSelectedMonth] = useState(null);
    const [message231, setMessage231] = useState('');
    const [isDirty, setIsDirty] = useState(false);

    useEffect(async () => {
        const auth = await CheckAuthorization(["Unpaid Direct Debits - Edit"], msal);

        setEditAuthorized(auth);
    }, []);

    useEffect(async () => {
        if (context.office.code != -1)
        {
            setIsLoading(true);
            const token = await GetAccessToken(msal);
            const data = await GetUnpaidDD(token, context.office.code);

            setddData(data.unpaidDDs);
            setMonthList(data.months);
            setDisplayMonths(data.displayMonths);
            setMessage231(data.message231);

            setSelectedMonth(0);

            setIsLoading(false);
        }
        else
        {
            setddData(null);
            setMonthList([]);
            setDisplayMonths([]);
            setSelectedMonth(null);
        }
    }, [context.office.code]);

    useEffect(async () => {
        if (ddData != null)
        {
            setUnpaidDDs(CopyList(ddData));
        }
        else
            setUnpaidDDs(null);

        setIsDirty(false);
    }, [ddData]);

    function mapColumns(unpaidDDs, month) {
        if (!HasValue(unpaidDDs) || !HasValue(month))
            return null;

        const filteredDDs = FindAll(unpaidDDs, "month", month);

        const result = filteredDDs.map((item, i) => {
            return {
                key: i,
                clientCode: item.clientId,
                clientName: `${item.title} ${item.firstName} ${item.surname}`,
                invoiceNo: item.invoiceNumber,
                amount: item.amount,
                rollover: item.rollover,
                monthsUnpaid: item.monthsUnpaid,
                applyFee: item.applyFee,
                unpaidDDId: item.unpaidDirectDebitId,
                month: item.month,
                reason: item.reason
            }
        });

        return result;
    }

    const rolloverChanged = (val, id) => {
        const index = FindIndex(unpaidDDs, "unpaidDirectDebitId", id);

        let copy = [...unpaidDDs];
        let unpaidDD = copy[index];

        if (!unpaidDD.rolloverPossible)
            {
                AlertModal(message231, 'Ok');
                return;
            }

        unpaidDD.rollover = val;

        setUnpaidDDs(copy);
        setIsDirty(true);
    };

    const applyFeeChanged = (val, id) => {
        const index = FindIndex(unpaidDDs, "unpaidDirectDebitId", id);

        let copy = [...unpaidDDs];
        let unpaidDD = copy[index];
        unpaidDD.applyFee = val;

        setUnpaidDDs(copy);
        setIsDirty(true);
    };

    const columns = [
        {
            title: 'Client Code',
            dataIndex: 'clientCode',
            key: 'clientCode',
            sorter: (a,b) => (a.clientCode < b.clientCode) ? -1 : 1,
            ...ColumnSearchProps("clientCode", "Client Code", true),           
            render: (text, record) => <p className="text-center">{text}</p>,
        },
        {
            title: 'Client Name',
            dataIndex: 'clientName',
            key: 'clientName',
            sorter: (a,b) => (a.clientName < b.clientName) ? -1 : 1,
            ...ColumnSearchProps("clientName", "Client Name"), 
        },
        {
            title: 'Invoice No',
            dataIndex: 'invoiceNo',
            key: 'invoiceNo',
            sorter: (a,b) => (a.invoiceNo < b.invoiceNo) ? -1 : 1,
            ...ColumnSearchProps("invoiceNo", "Invoice No"),
        },
        {
            title: 'Amount',
            dataIndex: 'amount',
            key: 'amount',
            sorter: (a,b) => (a.amount < b.invoiamountceNo) ? -1 : 1,
            ...ColumnSearchProps("amount", "Amount", true),
            render: (text, record) => <p className="text-right">{record.amount?.toLocaleString(undefined, { minimumFractionDigits: 2 })}</p>
        },
        {
            title: 'Rollover',
            dataIndex: 'rollover',
            key: 'rollover',
            render: (text, record) => <div className="text-center"><Checkbox checked={record.rollover} disabled={!editAuthorized || record.month != monthList[0]} onChange={(e) => rolloverChanged(e.target.checked, record.unpaidDDId)} /></div>
        },
        {
            title: 'Months Unpaid',
            dataIndex: 'monthsUnpaid',
            key: 'monthsUnpaid',
            sorter: (a,b) => (a.monthsUnpaid < b.monthsUnpaid) ? -1 : 1,
            ...ColumnSearchProps("monthsUnpaid", "Months Unpaid", true),
            render: (text, record) => <p className="text-right">{text}</p>
        },
        {
            title: 'Apply DD Fee',
            dataIndex: 'applyFee',
            key: 'applyFee',
            render: (text, record) => <div className="text-center"><Checkbox checked={record.applyFee} disabled={!editAuthorized || record.monthsUnpaid >= 3 || record.month != monthList[0]} onChange={(e) => applyFeeChanged(e.target.checked, record.unpaidDDId)} /></div>
        },
        {
            title: 'Reason',
            dataIndex: 'reason',
            key: 'reason',
            sorter: (a,b) => (a.reason < b.reason) ? -1 : 1,
            ...ColumnSearchProps("reason", "Reason", false),          
            render: (text, record) => <p className="text-left">{text}</p>
        }
    ];

    const onMonthChanged = (val) => {
        if (Number(val) == selectedMonth)
            return;

        const callback = () => {
            setSelectedMonth(Number(val));
            setIsDirty(false);
        }
        if (isDirty)
            ConfirmModal("Al changes made will be lost. Do you wish to proceed?", callback);
        else
            callback();
    }

    const TableTitle = () => {
        return (
            <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-start", alignItems: "center" }}>
                <h3 style={{ marginRight: "30px" }}>Unpaid Direct Debit Date</h3>
                <StandardSelect items={displayMonths} selectedItem={displayMonths[selectedMonth]} onChange={onMonthChanged} />
            </div>
        )
    }

    const saveChanges = async () => {

        const filteredDDs = FindAll(unpaidDDs, "month", monthList[selectedMonth]);

        const toUpdate = filteredDDs.map((item, i) => {
            return {
                unpaidDirectDebitId: item.unpaidDirectDebitId,
                rollover: item.rollover,
                applyFee: item.applyFee,
            }
        });

        const data = {
            unpaidDDs: toUpdate
        }

        const token = await GetAccessToken(msal);
        await UpdateUnpaidDDs(token, data);

        const newData = await GetUnpaidDD(token, context.office.code);

        setddData(newData.unpaidDDs);
    }

    const cancelChanges = () => {
        const callback = () => {
            setUnpaidDDs(CopyList(ddData));
            setIsDirty(false);
        }
        if (isDirty)
            ConfirmModal("Al changes made will be lost. Do you wish to proceed?", callback);
        else
            callback();
    }

    const totalsRow = (pageData) => {
        let totalAmount = 0;
        let totalInvoices = 0;

        pageData.forEach(({ amount }) => {
            totalAmount += amount;
            totalInvoices ++;
        });

        return (
            <Table.Summary.Row>
              <Table.Summary.Cell className="text-align-center">Total</Table.Summary.Cell>
              <Table.Summary.Cell></Table.Summary.Cell>
              <Table.Summary.Cell className="text-align-right">{totalInvoices} </Table.Summary.Cell>
              <Table.Summary.Cell className="text-align-right">{totalAmount.toLocaleString(undefined, { minimumFractionDigits: 2 })} </Table.Summary.Cell>
              <Table.Summary.Cell></Table.Summary.Cell>
              <Table.Summary.Cell></Table.Summary.Cell>
              <Table.Summary.Cell></Table.Summary.Cell>
              <Table.Summary.Cell></Table.Summary.Cell>
            </Table.Summary.Row>
        );
    };

    if (isLoading)
        return (
            <div style={{ width: "100%", height: "100%", minHeight: "inherit", display: "flex", alignItems: "center", justifyContent: "center" }}>
                <Spin size="large" />
            </div>
        )
    else if (ddData == null)
        return (
            <p style={{padding:"20px"}}>Select an office to view the unpaid direct debits.</p>
        )
    else
        return (
            <>
                <div className="div-ant-table" style={{ maxHeight: "100%", width: "100%", overflow: "auto" }}>
                    <Table title={() => TableTitle()} dataSource={mapColumns(unpaidDDs, monthList[selectedMonth])} columns={columns} pagination={{ showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items` }} summary={totalsRow}/>
                </div>
                <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-end", paddingTop: "20px" }}>
                    <Authorize permissions={["Unpaid Direct Debits - Edit"]} isVisible = {selectedMonth == 0}>
                        <SaveButton style={{ marginRight: "10px" }} disabled={!isDirty} callback={saveChanges} />
                        <CancelButton callback={cancelChanges} disabled={!isDirty}/>
                    </Authorize>
                </div>
            </>
        );
}

export default UnpaidDDPage;