
import { Modal } from "antd";

import SaveButton from "./SaveButton";
import CancelButton from "./CancelButton";
import TextArea from "antd/lib/input/TextArea";
import { useState } from "react";
import { HasValue } from "../Helper/JSHelper";

const { confirm } = Modal;

let reason = "";

const TextEntryModal = async (text, callback) => { 
    let modal;

    const close = () => {
      modal.destroy();
    }

    const save = async () =>
    {
      if (HasValue(reason))
      {
        await callback(reason); 
        close();
      }
    }

    modal = confirm({
      content: 
        <div>
            <p style={{fontWeight:"bold"}}>{text}</p>
            <TextArea maxLength={500} onChange={(val) => reason = val.target.value} rows={3} style={{width:"500px", marginLeft: "15px"}}/>
            <div style={{marginTop:"20px", width:"100%", display: "flex", justifyContent: "flex-end"}}>
                <SaveButton style={{marginRight:"10px"}} text="Save" callback={save} />
                <CancelButton text="Cancel" callback={() => close()}/>
            </div>
        </div>,
      okButtonProps: {style:{display:"none"}},
      cancelButtonProps: {style:{display:"none"}},
    });
} 

export default TextEntryModal;