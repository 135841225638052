import { useEffect, useState, useContext } from "react";
import NamedInput from "../Components/NamedInput";
import MainLayout from "./MainLayout";
import { Spin, Table, Button, Input, Pagination} from "antd";
import {SearchOutlined} from "@ant-design/icons";
import { useMsal } from "@azure/msal-react";
import { GetAccessToken } from "../Helper/JWTToken";
import { FindClients, GetHistory, GetVatRate, PutVatRate } from "../Data Layer/Data";
import { MainContext } from "../Components/Context";
import SaveButton from "../Components/SaveButton";
import CancelButton from "../Components/CancelButton";
import { HasValue } from "../Helper/JSHelper";
import ColumnSearchProps from "../Components/AntdExtended/ColumnSearchProps";


function VatRatePage() {
    const msal = useMsal();
    const [isLoading, setIsLoading] = useState(false);
    const [currentVatRate, setCurrentVatRate] = useState(0);
    const [vatRateText, setVatRateText] = useState("");
    const [newVatRate, setNewVatRate] = useState(null);
    const [errorText, setErrorText] = useState("");
    const [saveDisabled, setSaveDisabled] = useState(false);
    const [isDirty, setIsDirty] = useState(false);

  
    //Get current vat rate
    useEffect(async () => {
        setIsLoading(true);
        const token = await GetAccessToken(msal);
        const data = await GetVatRate(token);

        setCurrentVatRate(data);
        setVatRateText(data.toLocaleString(undefined, {minimumFractionDigits: 2}))
        setIsLoading(false);
    }, []);

    const onVatChange = (e) => {
        e.preventDefault();
        const val = e.target.value;
        setVatRateText(val);

        if (isNaN(val) || !HasValue(val) || val < 0 || val > 100) {
            setSaveDisabled(true);
            setErrorText("Vat Rate (Standard) must be a number between 0 and 100")
        }
        else {
            setSaveDisabled(false);
            setErrorText("");
            setIsDirty(true);
        }
    }

    function delay(ms) {
        return new Promise(resolve => setTimeout(resolve, ms));
      }

    const saveVat = async () => {
        setErrorText("");
        const token = await GetAccessToken(msal);
        const success = await PutVatRate(token, Number(vatRateText));

        if (success) {
            setCurrentVatRate(Number(vatRateText));
            setVatRateText(Number(vatRateText).toLocaleString(undefined, {minimumFractionDigits: 2}));
        }
        else 
        {
            setErrorText("Failed to save new Vat Rate!");
            setVatRateText(currentVatRate.toLocaleString(undefined, {minimumFractionDigits: 2}));
        }

        setIsDirty(false);        
    }

    const cancel = () => {
        setVatRateText(currentVatRate.toLocaleString(undefined, {minimumFractionDigits: 2}));
        setErrorText("");
        setSaveDisabled(false);
        setIsDirty(false);
    }
    
    if (isLoading)
      return (
        <div style={{width:"100%", height:"100%", minHeight:"inherit", display:"flex", alignItems:"center", justifyContent:"center"}}>
            <Spin size="large" />
        </div>
      )
    else
        return (
                <div style={{display:"flex", flexDirection:"row", justifyContent:"center"}}>
                <div style={{display:"flex", flexDirection:"column", alignItems:"center"}}>
                    <h3 style={{marginBottom:"10px"}}>Vat Rate (Standard)</h3>
                    <div style={{ display:"flex", flexDirection:"row", justifyContent:"space-between", alignItems:"center"}}>
                        <p>Current Rate:</p>
                        <Input style={{width:"80px", margin:"0px 10px", textAlign:"center"}} value={vatRateText} onChange={onVatChange} ></Input>
                        <SaveButton style={{marginRight:"10px"}} disabled={saveDisabled} callback={saveVat} disabled={!isDirty}/>
                        <CancelButton callback={cancel} disabled={!isDirty}/>
                    </div>
                    <p style={{marginTop:"10px"}} className="error-text">{errorText}</p>
                </div>
                </div>
        )
}

export default VatRatePage;