import { useEffect, useState, useContext } from "react";
import { Spin, Table, Button,  DatePicker, Popover} from "antd";
import { useMsal } from "@azure/msal-react";
import { GetAccessToken } from "../Helper/JWTToken";
import {  GetFeeStatementData,GetFeePdfStatementData} from "../Data Layer/Data";
import { MainContext } from "../Components/Context";
import { HasValue } from "../Helper/JSHelper";

import { pdf} from '@react-pdf/renderer'
import moment from "moment";
import PDFFeeStatement from "../Components/PDFFeeStatement";

const { RangePicker } = DatePicker;

function FeeStatementPage ()
{
    let defDateRange;
    if (moment().year() === 2022)
        defDateRange = [moment("2022-01-17"), moment()]
    else
        defDateRange = [moment().startOf('year'), moment()]

    const context = useContext(MainContext); 
    
    const msal = useMsal();
   
    const [isLoading, setIsLoading] = useState(false);   
    const [dateRange, setDateRange] = useState(defDateRange);
    const [feeStatementData, setFeeStatementData] = useState(null);
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);

    useEffect(() => {
       async function fetchData(){

        if(HasValue(context.client.code)) {
            setIsLoading(true);

            // let startDate = null;
            // let endDate = null;

            // if (HasValue(dateRange)){
            //   startDate = dateRange[0]?.format();
            //   endDate = dateRange[1]?.format();
            // }
            
            const token = await GetAccessToken(msal);
            const data = await GetFeeStatementData(token, context.client.code, dateRange[0].toDate().toLocaleDateString("en-GB"), dateRange[1].toDate().toLocaleDateString("en-GB"));

            setFeeStatementData(data);         
            setIsLoading(false);
        }
        else 
            setFeeStatementData(null);
    }
    fetchData();
    }, [dateRange, context.client.code,msal]);

    useEffect(() => {
      async function scrollTo() {
        if (!isLoading) {
          var toScroll = document.getElementById("divFeeStatementTable");
          if (toScroll != null) {
            toScroll.scrollTop = toScroll.offsetHeight;
          }
        }
      }
      scrollTo();
    }, [isLoading]);

    const mapRows = (data) => {
        let rows = [];

        rows.push({
            date: dateRange[0]?.toDate(),
            dateString: dateRange[0]?.toDate().toLocaleDateString("en-GB"),
            description: "Balance b/f",
            number: null,
            debit: null,
            credit: null,
            balance: data.balanceBefore?.toLocaleString(undefined, { minimumFractionDigits: 2 }),
            boldText: false,
            assignedTo: null,
            key: 0
        });

        data.feeStatementRows.forEach((row, index) => {
            rows.push({
                date: new Date(row.date),
                dateString: new Date(row.date).toLocaleDateString("en-GB"),
                description: row.description,
                number: row.number,
                debit: row.debit?.toLocaleString(undefined, { minimumFractionDigits: 2 }),
                credit: row.credit?.toLocaleString(undefined, { minimumFractionDigits: 2 }),
                balance: row.balance?.toLocaleString(undefined, { minimumFractionDigits: 2 }),
                boldText: false,
                assignedTo: row.assignedTo,
                key: index + 1
            })
        });

        rows.push({
            date: null,
            dateString: null,
            description: "Total Due",
            number: null,
            debit: null,
            credit: null,
            balance: data.totalDue?.toLocaleString(undefined, { minimumFractionDigits: 2 }),
            boldText: true,
            assignedTo: null,
            key: (data.feeStatementRows?.length ?? 0) + 1
        });
        
        return rows;
    }

    const invoicePopover = (row) =>{
        return (
            <div style={{display:"flex", flexDirection:"row", width:"fit-content"}}>
                <p className="text-bold" style={{marginRight:"10px"}}>Invoice:</p>
                <p>{row.assignedTo}</p>
            </div>
        );
    }

    const columns = [
        {
          title: 'Date',
          dataIndex: 'dateString',
          key: 'date',
          render: (text, record) => <p className="text-center">{text}</p>            
        },
        {
            title: 'Description',
            dataIndex: 'description',
            key: 'description',
            render: (text, record) => (record.boldText) ?
                                    <p className="text-bold">{text}</p> :
                                    <p>{text}</p>              
        },
        {
            title: 'Number',
            dataIndex: 'number',
            key: 'number',
            render: (text, record) => (HasValue(record.assignedTo)) ?
                                        <div className="text-left">
                                        <Popover placement="top" content={invoicePopover(record)} trigger="click">
                                            <p className="has-popover" style={{width:"fit-content"}}>{text}</p>
                                        </Popover>
                                        </div>
                                        :
                                        <p className="text-left">{text}</p>                
        },
        {
            title: 'Debit €',
            dataIndex: 'debit',
            key: 'debit',
            render: (text, record) => <p className="text-right">{text}</p>             
        },
        {
            title: 'Credit €',
            dataIndex: 'credit',
            key: 'credit',
            render: (text, record) => <p className="text-right">{text}</p>               
        },
        {
            title: 'Balance €',
            dataIndex: 'balance',
            key: 'balance',
            render: (text, record) => (record.boldText) ?
                                    <p className="text-right text-bold">{text}</p> :
                                    <p className="text-right">{text}</p>               
        }
    ]


    const showPDF_API = async ( dateFrom, dateTo) => {
      const token = await GetAccessToken(msal);
      const data = await GetFeePdfStatementData( token, context.client.code,dateFrom,dateTo );
      const file = new Blob([data], { type: "application/pdf" });

      const fileURL = URL.createObjectURL(file);
      window.open(fileURL, "_blank");
    };

    //it is not used
    const showPDF = async (feeStatement, dateFrom, dateTo) => {
      const blob = await pdf(
        <PDFFeeStatement feeStatement={feeStatement} dateFrom={dateFrom} dateTo={dateTo} />
      ).toBlob();
      let url = URL.createObjectURL(blob);
      window.open(url, "_blank");
    };
        

    const selectRow = (record, index) => {
        if (selectedRowKeys.length === 0 || selectedRowKeys[0] !== record.key) {
            setSelectedRowKeys([record.key]);
        }
    }

    if (isLoading)
      return (
        <div style={{width:"100%", height:"100%", minHeight:"inherit", display:"flex", alignItems:"center", justifyContent:"center"}}>
            <Spin size="large" />
        </div>
      )
    if (feeStatementData === null)
        return (
            <p style={{padding:"20px"}}>Select a client to view fee statement.</p>
        )
    else
        return (
          <div style={{display:"flex", flexDirection:"column", height:"100%", width: "100%", overflowY:"hidden"}}>
            <div style={{display:"flex", flexDirection:"row", justifyContent:"space-between", alignItems:"center", marginTop:"15px", marginBottom:"15px"}}>
                <h3>Fee Statement - {context.client.code} {context.client.firstName} {context.client.surname}</h3>
                <div style={{display:"flex", flexDirection:"row", justifyContent:"space-between", maxWidth:"30%"}}>
                    <p style={{marginRight:"10px"}} className="text-bold">Address:</p>
                    <p>{feeStatementData.clientAddress1}, {feeStatementData.clientAddress2}, {feeStatementData.clientAddress3}, {feeStatementData.clientAddress4}, {feeStatementData.clientAddress5}</p>
                </div>
                <RangePicker value={dateRange} format="DD-MM-YYYY" onChange={setDateRange} allowEmpty={[false,false]} allowClear={false}></RangePicker>
            </div>
            <div className="div-ant-table div-selectable-rows" id="divFeeStatementTable" style={{width: "100%", overflow:"auto"}}>
                <Table dataSource={mapRows(feeStatementData)} columns={columns} pagination={false} rowSelection={{ selectedRowKeys, type: "radio", columnWidth: "0px" }} onRow={(record, index) => ({ onClick: () => { selectRow(record, index); }, })}/>
            </div>
            <div className="div-non-ant-table" style={{display:"flex", flexDirection:"row", justifyContent:"space-between", marginTop:"15px", marginBottom:"10px"}}>
                <table style={{ width: "50%", position:"relative", background:"white"}}>
                    <thead>
                        <tr>
                            <th>Current</th>
                            <th>30-60 Days</th>
                            <th>60-90 Days</th>
                            <th>+90 Days</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr className="text-right">
                            <td>{feeStatementData?.totalCurrent?.toLocaleString(undefined, { minimumFractionDigits: 2 })}</td>
                            <td>{feeStatementData?.total3060?.toLocaleString(undefined, { minimumFractionDigits: 2 })}</td>
                            <td>{feeStatementData?.total6090?.toLocaleString(undefined, { minimumFractionDigits: 2 })}</td>
                            <td>{feeStatementData?.total90Plus?.toLocaleString(undefined, { minimumFractionDigits: 2 })}</td>
                        </tr>
                    </tbody>
                </table>
                <div style={{marginRight: "17px"}}>
                {/* <a href="https://localhost:5001/api/Pdf/GetPdfFeeStatement?clientId=15220&dateFrom=2022-12-31T22:00:00.000Z&dateTo=2023-03-24T10:45:19.858Z" target="_parent">Test</a>
                */}
                    <Button type="primary" onClick={() => {
                        showPDF_API(dateRange[0].toDate().toLocaleDateString("en-GB"), dateRange[1].toDate().toLocaleDateString("en-GB"))
                         //showPDF(feeStatementData, dateRange[0].toDate(), dateRange[1].toDate());
                        }}
                         >Print</Button>
                </div>
            </div>
          </div>
        )
}

export default FeeStatementPage;