import { Input } from "antd";

function NamedInput({name = "", value = null, setter = null}) {

    const onChange = (e) => {
        e.preventDefault();
        setter(e.target.value);
    }

    return (
        <div style={{display: "flex", flexDirection: "row", justifyContent: "space-between", width: "100%"}}>
            <label style={{minWidth:"fit-content", marginRight:"15px"}}>{name}</label>
            <Input style={{maxWidth:"60%"}} onChange={onChange} value={value}></Input>
        </div>
    )
}

export default NamedInput;