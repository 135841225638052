import { Select } from "antd";
import { useEffect, useRef } from "react";

const { Option } = Select;

function StandardSelect ({items, selectedItem, onChange, disabled, style, placeholder, invertList = false, focusOnAppear = false}) {

    const select = useRef(null);

    useEffect(()=>{
        if (focusOnAppear) {
            select.current.focus();  
        }
    },[])

    let list;
    if (invertList)
        list = [...items].reverse();
    else
        list = [...items];

    const getKey = (index) => {
        if (invertList)
            return list.length - 1 - index;
        else
            return index;
    }

    return (
        <Select ref={select} value={selectedItem} placeholder={placeholder} onChange={onChange} disabled={disabled} style={style} >
            {list.map((item, index) => (
                <Option key={getKey(index)}>{item}</Option>
            ))}
        </Select>
    )
}

export default StandardSelect;