import { Page, Text, View, Document, Image, StyleSheet, Font, Link } from '@react-pdf/renderer';
import { HasValue } from '../Helper/JSHelper';
import { _fontCalibri, _fontCalibriBold, _fontCalibriItalic, _imgGreenFields, _imgIacLogoWhite, _imgIfacLogoLong } from '../Resources/Resources';

function PDFFeeStatement ({feeStatement, dateFrom, dateTo}) {

    Font.register({family: "Calibri", src:_fontCalibri});
    Font.register({family: "Calibri", fontStyle:"normal", fontWeight:"bold", src:_fontCalibriBold});
    Font.register({family: "Calibri", fontStyle:"italic", fontWeight:"normal", src:_fontCalibriItalic});

    const nrRowsFirstPage = 30;
    const nrRowsPage = 30;

    const styles = StyleSheet.create({
        page: {
          marginLeft:"20px",
          paddingRight:"40px",
          marginTop:"10px",
          marginBottom:"10px",
          paddingBottom:"10px",
          fontSize:"12px",
          fontFamily:"Calibri",
          height:"100%"
        },
        link: {
          color:"blue",
          textDecoration:"underline"
        }
      });

    let rows = [];

    rows.push({
        dateString: dateFrom.toLocaleDateString("en-GB"),
        description: "Balance b/f",
        number: null,
        debit: null,
        credit: null,
        balance: feeStatement.balanceBefore?.toLocaleString(undefined, { minimumFractionDigits: 2 }),
        boldText: false
    });

    feeStatement.feeStatementRows.forEach(row => {
        rows.push({
            dateString: new Date(row.date).toLocaleDateString("en-GB"),
            description: row.description,
            number: row.number,
            debit: row.debit?.toLocaleString(undefined, { minimumFractionDigits: 2 }),
            credit: row.credit?.toLocaleString(undefined, { minimumFractionDigits: 2 }),
            balance: row.balance?.toLocaleString(undefined, { minimumFractionDigits: 2 }),
            boldText: false
        })
    });

    rows.push({
        dateString: null,
        description: "Total Due",
        number: null,
        debit: null,
        credit: null,
        balance: feeStatement.totalDue?.toLocaleString(undefined, { minimumFractionDigits: 2 }),
        boldText: true
    });

      const reference = `${feeStatement.clientCode}/${feeStatement.officeCode}`;

      let pages = 1;
      let remainingRows = rows.length;
      let pageRows = [];

      if (remainingRows <= nrRowsFirstPage)
      {
        pageRows.push(rows);
      }
      else
      {
        pageRows.push(rows.slice(0, nrRowsFirstPage));
        remainingRows -= nrRowsFirstPage;

        while (remainingRows > 0)
        {
          pages++;
          const currentPos = rows.length - remainingRows;
          let rowsToAdd = nrRowsPage;
          if (nrRowsPage > remainingRows)
            rowsToAdd = remainingRows;

          pageRows.push(rows.slice(currentPos, currentPos + rowsToAdd));

          remainingRows -= nrRowsPage;
        }
      }

      let factor = 1;
      if (!HasValue(feeStatement.clientAddress1))
        factor++;
      if (!HasValue(feeStatement.clientAddress2))
        factor++;
      if (!HasValue(feeStatement.clientAddress3))
        factor++;
      if (!HasValue(feeStatement.clientAddress4))
        factor++;
      if (!HasValue(feeStatement.clientAddress5))
        factor++;

      let variableSpace = 12 * factor + "px";

  return (
    <Document title={`Fee Statement ${reference}`}>
      {
      pageRows.map((currentRows, index) => (
      <Page size={'A4'} style={styles.page}>    
        <View style={{ height: "100%" }}>
          <View>
            <View style={{minHeight:"50px"}}>
              <Image src={_imgIfacLogoLong}></Image>
            </View>
            <View style={{ flexDirection: "row", justifyContent: "space-between", marginTop: "15px", marginBottom: "20px" }}>
              <View style={{ width: "25%" }}></View>
              <Text>Ref:   {reference}</Text>
              <Text>Date: {new Date().toLocaleDateString("en-GB")}</Text>
            </View>
                <View style={{minHeight:"30px", backgroundColor: "#00ABC7", padding: "5px", paddingBottom: "2px", flexDirection: "row", justifyContent: "center", border: "1px", marginBottom:"5px" }}>
                  <Text style={{ color: "white", fontSize: "20", fontWeight: "bold" }}>Statement</Text>
                </View>
                <View style={{marginLeft: "40px", minHeight:"84px"}}>
                  <View style={{minHeight:"12px", height:"12px"}}></View>
                  <Text style={{minHeight:"12px"}}>{feeStatement.clientTitle} {feeStatement.clientFirstName} {feeStatement.clientSurname}</Text>
                  {HasValue(feeStatement.clientAddress1) &&
                    <Text style={{minHeight:"12px"}}>{feeStatement.clientAddress1}</Text>}
                  {HasValue(feeStatement.clientAddress2) &&
                    <Text style={{minHeight:"12px"}}>{feeStatement.clientAddress2}</Text>}
                  {HasValue(feeStatement.clientAddress3) &&
                    <Text style={{minHeight:"12px"}}>{feeStatement.clientAddress3}</Text>}
                  {HasValue(feeStatement.clientAddress4) &&
                    <Text style={{minHeight:"12px"}}>{feeStatement.clientAddress4}</Text>}
                  {HasValue(feeStatement.clientAddress5) &&
                    <Text style={{minHeight:"12px"}}>{feeStatement.clientAddress5}</Text>}
                    <View style={{minHeight:variableSpace, height:variableSpace}}></View>
                </View>
          </View>
          <View style={{ flexDirection: "column", justifyContent: "flex-end" }}>
            <View style={{backgroundColor: "#00ABC7", color: "white", padding: "1px", flexDirection:"row", justifyContent: "space-evenly", alignItems:"center", border: "1px", marginTop:"5px", paddingTop:"4px"}}>
              <View style={{textAlign:"left", width:"100%"}}>
                  <Text>Date</Text>
              </View>
              <View style={{textAlign:"left", width:"100%"}}>
                  <Text>Description</Text>
              </View>
              <View style={{textAlign:"left", width:"100%"}}>
                  <Text>Number</Text>
              </View>
              <View style={{textAlign:"center", width:"100%"}}>
                  <Text>Debit €</Text>
              </View>
              <View style={{textAlign:"center", width:"100%"}}>
                  <Text>Credit €</Text>
              </View>
              <View style={{textAlign:"center", width:"100%"}}>
                  <Text>Balance €</Text>
              </View>
            </View>

            <View style={{height: GetContentHeight(index + 1, pages), width: "100%", flexDirection: "column", justifyContent:"flex-start", border: "1px",
             borderTop:"0px" }}>
              <View style={{height: "5px"}}></View>
                {
                    currentRows.map((item, index) => (
                        <View style={{flexDirection:"row", justifyContent: "space-evenly", alignItems:"center"}}>
                            <View style={{textAlign:"left", width:"100%", marginLeft:"5px", paddingTop:"1px", marginBottom:"1px"}}>
                                <Text>{item.dateString}</Text>
                            </View>
                            <View style={{textAlign:"left", width:"100%", marginTop:"1px", marginBottom:"1px"}}>
                                { 
                                item.boldText ?
                                <Text style={{fontWeight:"bold"}}>{item.description}</Text>
                                :
                                <Text>{item.description}</Text>
                                }
                            </View>
                            <View style={{textAlign:"left", width:"100%", marginTop:"1px", marginBottom:"1px"}}>
                                <Text>{item.number}</Text>
                            </View>
                            <View style={{textAlign:"center", width:"100%", marginTop:"1px", marginBottom:"1px"}}>
                                <Text style={{textAlign:"right"}}>{item.debit}</Text>
                            </View>
                            <View style={{textAlign:"center", width:"100%", marginTop:"1px", marginBottom:"1px"}}>
                                <Text style={{textAlign:"right"}}>{item.credit}</Text>
                            </View>
                            <View style={{textAlign:"center", width:"100%", marginRight:"5px", marginTop:"1px", marginBottom:"1px"}}>
                                { 
                                item.boldText ?
                                <Text style={{fontWeight:"bold", textAlign:"right"}}>{item.balance}</Text>
                                :
                                <Text style={{textAlign:"right"}}>{item.balance}</Text>
                                }
                            
                            </View>
                        </View>
                    ))
                }
            </View>
            {
              (index + 1 === pages) &&
              <View style={{flexDirection: "row", width:"60%", justifyContent:"flex-start", alignItems:"flex-end", marginTop:"5px", paddingTop:"5px"}}>
                  <View style={{flexDirection:"column", justifyContent:'flex-end', border:"1px", borderRight:"0px", width:"100%"}}>
                      <View style={{textAlign:"center", backgroundColor: "#00ABC7", color: "white", borderBottom:"1px"}}>
                          <Text>Current</Text>
                      </View>
                      <View style={{textAlign:"right"}}>
                          <Text>{feeStatement?.totalCurrent?.toLocaleString(undefined, { minimumFractionDigits: 2 })}</Text>
                      </View>
                  </View>
                  <View style={{flexDirection:"column", justifyContent:'flex-end', border:"1px", borderRight:"0px", width:"100%"}}>
                      <View style={{textAlign:"center", backgroundColor: "#00ABC7", color: "white", borderBottom:"1px"}}>
                          <Text>30-60 Days</Text>
                      </View>
                      <View style={{textAlign:"right"}}>
                          <Text>{feeStatement?.total3060?.toLocaleString(undefined, { minimumFractionDigits: 2 })}</Text>
                      </View>
                  </View>
                  <View style={{flexDirection:"column", justifyContent:'flex-end', border:"1px", borderRight:"0px", width:"100%"}}>
                      <View style={{textAlign:"center", backgroundColor: "#00ABC7", color: "white", borderBottom:"1px"}}>
                          <Text>60-90 Days</Text>
                      </View>
                      <View style={{textAlign:"right"}}>
                          <Text>{feeStatement?.total6090?.toLocaleString(undefined, { minimumFractionDigits: 2 })}</Text>
                      </View>
                  </View>
                  <View style={{flexDirection:"column", justifyContent:'flex-end', border:"1px", width:"100%"}}>
                      <View style={{textAlign:"center", backgroundColor: "#00ABC7", color: "white", borderBottom:"1px"}}>
                          <Text>+90 Days</Text>
                      </View>
                      <View style={{textAlign:"right"}}>
                          <Text>{feeStatement?.total90Plus?.toLocaleString(undefined, { minimumFractionDigits: 2 })}</Text>
                      </View>
                  </View>
              </View>
            }
            <View>
              <View style={{ flexDirection: "row", justifyContent: "space-between", marginTop: "5px", fontWeight: "bold" }}>
                <Text>E. &amp; O.E.</Text>   

                  <Text style={{fontSize:"14px"}}>Please remit to your Local Office by {new Date(feeStatement.dueDate).toLocaleDateString("en-GB")}</Text>

                <View style={{width:"40px"}}/>
              </View>
              <View style={{ flexDirection: "row", justifyContent: "center"}}>

                <Text>Payable by Direct Debit, Cheque, Draft, Card, Bank Transfer or on <Link src="https://www.ifac.ie" target="_blank" style={styles.link}>www.ifac.ie</Link> using the <Link src="https://www.ifac.ie/pay-invoice/" style={styles.link}>“Pay Invoice”</Link> link.</Text>

              </View>
              <View style={{ flexDirection: "row", marginTop: "5px", marginBottom:"5px"}}>
                <View style={{ flexDirection: "row"}}>
                  <Text style={{fontWeight:"bold"}}>IBAN: </Text>
                  <Text>{feeStatement.iban}</Text>
                </View>
                <View style={{ flexDirection: "row", marginLeft:"10px"}}> 
                  <Text style={{fontWeight:"bold"}}>BIC: </Text>
                  <Text>{feeStatement.bic}</Text>
                </View>
              </View>
                <Text style={{marginBottom:"5px", fontSize:"10px"}}>Ifac - Registered in Ireland No. 3528R. Registered office: Danville Business Park, Ring Road, Kilkenny, R95 N156. VAT No. IE2803507F</Text>
                <View style={{ flexDirection: "row", justifyContent:"center", fontSize:"10px", marginBottom:"5px"}}>
                  <Text>
                  Page {index + 1} of {pages}
                  </Text>
                </View>
            </View>
          </View>
        </View>
      </Page>
      ))
      }
    </Document>
    );
}

function GetContentHeight(currentPage, pages)
{
  if (currentPage == pages)
    return "74%";
  else
    return "80%";
}

export default PDFFeeStatement;