
import { Table, Menu, Dropdown } from 'antd';
import { useEffect, useState} from 'react';
import ColumnSearchProps from "../Components/AntdExtended/ColumnSearchProps";
import {HasValue} from '../Helper/JSHelper';
import { DownOutlined} from '@ant-design/icons';


function ReceiptTable({ receipts, title, callback, offices, resetOffice = false, resetRow = false, setVisibleRows = null}) {

    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [selectedOffice, setSelectedOffice] = useState(null);
    const [officeName, setOfficeName] = useState("Select office...");
    const [filteredReceipts, setFilteredReceipts] = useState([]);

    const isUnreconciledTable = HasValue(offices);

    useEffect(() => {
        if (resetOffice) {
            setSelectedOffice(null);
            setOfficeName("Select office...")
        }

        if (resetRow) {
            setSelectedRowKeys([]);
        }
    },);

    useEffect(() => {
        if (!isUnreconciledTable)
            setFilteredReceipts(receipts);
        else if (selectedOffice == null)
            setFilteredReceipts([]);
        else
            setFilteredReceipts(getFilteredReceipts(receipts, selectedOffice));
    }, [selectedOffice, receipts]);

    useEffect(() => {
        if (HasValue(setVisibleRows)){
            let rowKeys = [];
            filteredReceipts.forEach(receipt => {
                rowKeys.push(receipt.key);
            });

            setVisibleRows(rowKeys);
        }
    }, [filteredReceipts]);

    const columns = [
        {
            title: 'Receipt Number',
            dataIndex: 'receiptNo',
            key: 'receiptNo',
            sorter: (a,b) => (a.receiptNo < b.receiptNo) ? -1 : 1,
            ...ColumnSearchProps("receiptNo", "Receipt Number"),
        },
        {
            title: 'Receipt Date',
            dataIndex: 'receiptDateStr',
            key: 'receiptDateStr',
            align: 'center',
            sorter: (a,b) => (a.receiptDate < b.receiptDate) ? -1 : 1,
            ...ColumnSearchProps("receiptDateStr", "Receipt Date")
        },
        {
            title: 'Receipt Amount',
            dataIndex: 'amountString',
            key: 'amount',
            align: 'right',
            sorter: (a,b) => (a.amount < b.amount) ? -1 : 1,
            ...ColumnSearchProps("amount", "Receipt Amount")
        },
        {
            title: 'Client Code',
            dataIndex: 'clientCode',
            key: 'clientCode',
            align: 'right',
            sorter: (a,b) => (a.clientCode < b.clientCode) ? -1 : 1,
            ...ColumnSearchProps("clientCode", "Client Code")
        },
        {
            title: 'Client Name',
            dataIndex: 'clientName',
            key: 'clientName',
            align: 'center',
            sorter: (a,b) => (a.clientName < b.clientName) ? -1 : 1,
            ...ColumnSearchProps("clientName", "Client Name")
        }
    ];

    if (isUnreconciledTable)
        columns.push({
            title: 'Note',
            dataIndex: 'receiptNote',
            key: 'receiptNote',
            align: 'center'
        })
    else
        columns.push({
            title: 'Local Office',
            dataIndex: 'officeName',
            key: 'officeName',
            align: 'center',
            sorter: (a,b) => (a.officeName < b.officeName) ? -1 : 1,
            ...ColumnSearchProps("officeName", "Local Office")
        });

    function GenerateOfficesDropdown(officeList, callback) {

        return (
            <Menu style={{ maxHeight: '400px', overflowY: 'auto' }}>
                <Menu.Item key="-1">
                    <a onClick={() => { callback(null, "No selection") }}>
                        No selection
                    </a>
                </Menu.Item>
                {
                    officeList.map((office, i) =>
                        <Menu.Item key={i}>
                            <a onClick={() => { callback(office.officeCode, office.officeName) }}>
                                {`${office.officeCode} - ${office.officeName}`}
                            </a>
                        </Menu.Item>
                    )
                }
                <Menu.Item key="-2">
                    <a onClick={() => { callback(-1, "All offices") }}>
                        All offices
                    </a>
                </Menu.Item>
            </Menu>
        )
    }

    const GetTitle = (title) => {
        return (
            <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center" }}>
                <h3>{title}</h3>
                {
                    isUnreconciledTable &&
                    <div style={{display:"flex", justifyContent:"flex-start", alignItems:"center", marginRight:"20px"}}>
                        <h3 style={{marginRight:"15px", position:"relative", top:"-2px"}}>Office: </h3>
                        <Dropdown overlay={GenerateOfficesDropdown(offices, (code, name) => {setSelectedOffice(code); setOfficeName(name);})}>
                            <a className="ant-dropdown-link" style={{ color: "unset" }} onClick={e => e.preventDefault()}>{officeName}  <DownOutlined /> </a>
                        </Dropdown>
                    </div>
                }
            </div>
        )
    }

    const selectRow = (record) => {
        if (selectedRowKeys.length === 0 || selectedRowKeys[0] != record.key) {

            setSelectedRowKeys([record.key]);
            callback(record.key);
        }
    }

    const getFilteredReceipts = (receipts, officeCode) => {

        if (!isUnreconciledTable || officeCode === -1)
            return receipts;

        if (officeCode === null)
            return null;

        let filteredReceipts = [];

        receipts.forEach(receipt => {
            if (receipt.officeCode === officeCode)
                filteredReceipts.push(receipt);
        });

        return filteredReceipts;
    }

    const onFilter = (a,b,c,d) => {
        if (d.action === 'filter')
            setFilteredReceipts(d.currentDataSource);
    }

    const totalsRow = (pageData ) => {
        let totalAmount = 0;

        filteredReceipts.forEach(({ amount, key }) => {
            totalAmount += amount ?? 0;
        });

        return (
            <Table.Summary.Row>
              <Table.Summary.Cell className="text-align-center">Total</Table.Summary.Cell>
              <Table.Summary.Cell></Table.Summary.Cell>
              <Table.Summary.Cell >{totalAmount.toLocaleString(undefined, { minimumFractionDigits: 2 })} </Table.Summary.Cell>
              <Table.Summary.Cell></Table.Summary.Cell>
              <Table.Summary.Cell></Table.Summary.Cell>
              <Table.Summary.Cell></Table.Summary.Cell>
            </Table.Summary.Row>
        );
    };

    return (
        <div style={{ overflowY: "auto", overflowX: "hidden", width: "50%", minWidth: "550px" }}>
            <Table title={() => GetTitle(title)} columns={columns} summary={totalsRow} pagination={{ showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items` }} dataSource={getFilteredReceipts(receipts, selectedOffice)} rowSelection={{ selectedRowKeys, type: "radio", columnWidth: "0px" }} onRow={(record) => ({ onClick: () => { selectRow(record); }, })} onChange={onFilter}/>
        </div>
    )
}

export default ReceiptTable;