const config = window['appSettings'];

export const msalConfig = {
    auth: {
      clientId: "6506f68d-34ce-4a9e-b055-d6edd55e5aae",
      authority: "https://login.microsoftonline.com/aa98d3b1-4d25-40f1-80ed-bfde253e19ac",
      redirectUri: config.redirectUri,
      postLogoutRedirectUri: config.postLogoutRedirectUri
    },
    cache: {
      cacheLocation: "sessionStorage", // This configures where your cache will be stored
      storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
    }
  };
  
  // Add scopes here for ID token to be used at Microsoft identity platform endpoints.
  export const loginRequest = {
   scopes: ["User.Read"]
  };
  
  export const accessTokenRequest = {
    scopes: [
      'https://ifac.ie/ADIfacApp/user_impersonation',
      'https://ifac.ie/ADIfacApp/Users.Read',
  ]
  }
