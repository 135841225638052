
import { Modal } from "antd";

import SaveButton from "../Components/SaveButton";
import CancelButton from "../Components/CancelButton";

const { confirm } = Modal;

const ConfirmModal = async (text, callback) => { 
    let modal;
    
    const close = () => {
      modal.destroy();
    }

    modal = confirm({
      content: 
        <div>
            <p>{text}</p>
            <div style={{marginTop:"20px", width:"100%", display: "flex", justifyContent: "flex-end"}}>
                <SaveButton style={{marginRight:"10px"}} text="Yes" callback={async () =>{await callback(); close();}} />
                <CancelButton text="No" callback={() => close()}/>
            </div>
        </div>,
      okButtonProps: {style:{display:"none"}},
      cancelButtonProps: {style:{display:"none"}},
    });
} 

export default ConfirmModal;