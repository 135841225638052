
import './App.css';
import './Style/Main.css';
import LoginPage from './Pages/LoginPage';
import HomePage from './Pages/HomePage';
import 'antd/dist/antd.css';
import { AuthenticatedTemplate, UnauthenticatedTemplate } from "@azure/msal-react";
import {
  BrowserRouter as Router,
  Switch,
  Route
} from 'react-router-dom';

import { useMsal, useIsAuthenticated } from "@azure/msal-react";
import { loginRequest } from "./authConfig";
import {Context} from './Components/Context' 
import ClientHistoryPage from './Pages/ClientHistoryPage';
import InvoiceListPage from './Pages/InvoiceListPage';
import MainLayout from "./Pages/MainLayout";
import VatRatePage from './Pages/VatRatePage';
import StandardMonthlyFeesPage from './Pages/StandardMonthlyFeesPage';
import LodgementsPage from './Pages/LodgementsPage';
import MonthlyInvoicePage from './Pages/MonthlyInvoicePage';
import MonthlyARPage from './Pages/MonthlyARPage';
import MonthlyDDPage from './Pages/MonthlyDDPage';
import UnpaidDDPage from './Pages/UnpaidDDPage';
import CreateMonthlyInvoicePage from './Pages/CreateMonthlyInvoicePage';
import ReceiptsListPage from './Pages/ReceiptsListPage';
import CreateCreditNotePage from './Pages/CreateCreditNotePage';
import { SharesBoughtPage } from './Pages/SharesBoughtPage';
import { DebtorsControlAccountPage } from './Pages/DebtorsControlAccountPage';
import { HasValue } from './Helper/JSHelper';
import FeeStatementPage from './Pages/FeeStatementPage';
import CreateJournalPage from './Pages/CreateJournalPage';
import BadDebtsPage from './Pages/BadDebtsPage';
import AddRefundPage from './Pages/AddRefundPage';
import RefundListPage from './Pages/RefundListPage';

function App() {

  return (
    <Router>
        <AuthenticatedTemplate>
          <Context>
            <MainLayout>
              <Switch>
                <Route path='/client/history' component={ClientHistoryPage}/>  
                <Route path='/invoicing/format' component={InvoiceListPage}/>
                <Route path='/invoicing/receipts' component={ReceiptsListPage}/>
                <Route path='/invoicing/refunds' component={RefundListPage}/>
                <Route path='/invoicing/vatrate' component={VatRatePage}/>
                <Route path='/invoicing/stdfees' component={StandardMonthlyFeesPage}/>
                <Route path='/invoicing/monthlyar' component={MonthlyARPage}/>
                <Route path='/invoicing/lodgements' component={LodgementsPage}/>
                <Route path='/invoicing/monthlyinvoice' component={MonthlyInvoicePage}/>
                <Route path='/invoicing/createmonthlyinvoice' component={CreateMonthlyInvoicePage}/>
                <Route path='/invoicing/createcreditnote' component={CreateCreditNotePage}/>
                <Route path='/invoicing/monthlydd' component={MonthlyDDPage}/>
                <Route path='/invoicing/feestatement' component={FeeStatementPage}/>
                <Route path='/invoicing/addrefund' component={AddRefundPage}/>
                <Route path='/invoicing/createjournal' component={CreateJournalPage}/>
                <Route path='/invoicing/baddebts' component={BadDebtsPage}/>
                <Route path='/reporting/unpaiddd' component={UnpaidDDPage}/>
                <Route path='/reporting/sharesbought' component={SharesBoughtPage}/>
                <Route path='/reporting/debtorscontrolaccount' component={DebtorsControlAccountPage}/>
                <Route path='/' component={HomePage}/>                 
              </Switch> 
            </MainLayout>
          </Context>
        </AuthenticatedTemplate>   
        <UnauthenticatedTemplate>
            <LoginPage/>
          </UnauthenticatedTemplate>       
    </Router>
  );
  
}

export const PageLayout = (props) => {
  const isAuthenticated = useIsAuthenticated();

  return (
      <>
          <div>
              <a className="navbar-brand" href="/">Microsoft Identity Platform</a>
              { isAuthenticated ? <SignOutButton /> : <SignInButton /> }
          </div>
          <h5><center>Welcome to the Microsoft Authentication Library For Javascript - React Quickstart</center></h5>
          <br />
          <br />
          {props.children}
      </>
  );
};

export const SignInButton = () => {
  const { instance } = useMsal();

  const handleLogin = (loginType) => {
      if (loginType === "popup") {
          instance.loginPopup(loginRequest).catch(e => {
              console.log(e);
          });
      } else if (loginType === "redirect") {
          instance.loginRedirect(loginRequest).catch(e => {
              console.log(e);
          });
      }
  }
  return (
      <div >
          <button  onClick={() => handleLogin("popup")}>Sign in using Popup</button>
          <button  onClick={() => handleLogin("redirect")}>Sign in using Redirect</button>
      </div>
  )
}

export const SignOutButton = () => {
  const { instance } = useMsal();

  const handleLogout = (logoutType) => {
      if (logoutType === "popup") {
          instance.logoutPopup({
              postLogoutRedirectUri: "/",
              mainWindowRedirectUri: "/"
          });
      } else if (logoutType === "redirect") {
          instance.logoutRedirect({
              postLogoutRedirectUri: "/",
          });
      }
  }
  return (
      <div>
          <button  onClick={() => handleLogout("popup")}>Sign out using Popup</button>
          <button  onClick={() => handleLogout("redirect")}>Sign out using Redirect</button>
      </div>
  )
}

document.addEventListener('keydown', function (event) {
  if (event.key === "Enter" && event.target.nodeName === 'INPUT') {   
    var form = event.target.form;
    if (HasValue(form))
    {
      var index = Array.prototype.indexOf.call(form, event.target);

      var elementToFocus = form.elements[index + 1];
      if (!HasValue(elementToFocus))
        elementToFocus = form.elements[0];

      elementToFocus.focus();
    }
  }
});

export default App;
