import { useMsal } from "@azure/msal-react";
import { Spin, Table, Button, Input, Pagination, DatePicker} from "antd";
import { useState } from "react";
import { useEffect } from "react";
import { GetSharesBoughtReport } from "../Data Layer/Data";
import { GetAccessToken } from "../Helper/JWTToken";
import moment from "moment";
import { HasValue } from "../Helper/JSHelper";

const { RangePicker } = DatePicker;

function SharesBoughtPage ()
{
    const defRangeClient = [moment().startOf('year'), moment()];
    const msal = useMsal();
    const [sharesData, setSharesData] = useState();
    const [totalShares, setTotalShares] = useState();
    const [totalValue, setTotalValue] = useState();
    const [isLoading, setIsLoading] = useState(false);
    const [dateRange, setDateRange] = useState(defRangeClient);

    useEffect(async () => {
        let startDate;      
        let endDate;    

        if (HasValue(dateRange)){
            startDate = dateRange[0]?.format();
            endDate = dateRange[1]?.format();
        }

        setIsLoading(true);
        const token = await GetAccessToken(msal);
        const data = await GetSharesBoughtReport(token, startDate, endDate);

        data.shares.forEach((share) => {
           share.valueText = share.value.toLocaleString(undefined, { minimumFractionDigits: 2 });
        });

        setSharesData(data.shares);
        setTotalShares(data.totalShares);
        setTotalValue(data.totalValue);
        setIsLoading(false);
    }, [dateRange]);

    const dateChanged = (val) => {
        setDateRange(val);
    };

    const columns = [
        {
          title: 'Client Code',
          dataIndex: 'clientCode',
          key: 'clientCode',
          sorter: (a,b) => (a.clientCode < b.clientCode) ? -1 : 1,    
          render: (text, record) => { return (          
                record.isCreditNote ?
                <p style={{color:"red"}}>{text}</p> :   
                <p>{text}</p>
          )}  
        },
        {
          title: 'Client Name',
          dataIndex: 'clientName',
          key: 'clientName',
          sorter: (a,b) => (a.clientName < b.clientName) ? -1 : 1,    
          render: (text, record) => { return (          
                record.isCreditNote ?
                <p style={{color:"red"}}>{text}</p> :   
                <p>{text}</p>
          )}  
        },
        {
          title: 'Date',
          dataIndex: 'date',
          key: 'date',
          sorter: (a,b) => (a.date < b.date) ? -1 : 1,     
          render: (text, record) => { return (          
                record.isCreditNote ?
                <p style={{color:"red"}}>{(new Date(record.date)).toLocaleDateString("en-GB")}</p> :   
                <p>{(new Date(record.date)).toLocaleDateString("en-GB")}</p>
          )}              
        },        
        {
          title: 'Number',
          dataIndex: 'number',
          key: 'number',
          sorter: (a,b) => (a.number < b.number) ? -1 : 1,    
          render: (text, record) => { return (          
                record.isCreditNote ?
                <p style={{color:"red"}}>{text}</p> :   
                <p>{text}</p>
          )}  
        },          
        {
          title: 'Value',
          dataIndex: 'valueText',
          key: 'valueText',
          sorter: (a,b) => (a.value < b.value) ? -1 : 1,    
          render: (text, record) => { return (          
                record.isCreditNote ?
                <p style={{color:"red"}}>{text}</p> :   
                <p>{text}</p>
          )}  
        },          
        {
          title: 'Office',
          dataIndex: 'officeName',
          key: 'officeName',
          sorter: (a,b) => (a.officeName < b.officeName) ? -1 : 1,    
          render: (text, record) => { return (          
                record.isCreditNote ?
                <p style={{color:"red"}}>{text}</p> :   
                <p>{text}</p>
          )}  
        },
    ];

    const totalsRow = (pageData) => {
      return (
          <Table.Summary.Row>
            <Table.Summary.Cell className="text-align-left">Total</Table.Summary.Cell>
            <Table.Summary.Cell></Table.Summary.Cell>
            <Table.Summary.Cell></Table.Summary.Cell>
            <Table.Summary.Cell className="text-align-left">{totalShares}</Table.Summary.Cell>
            <Table.Summary.Cell className="text-align-left">{totalValue?.toLocaleString(undefined, { minimumFractionDigits: 2 })} </Table.Summary.Cell>
            <Table.Summary.Cell></Table.Summary.Cell>
          </Table.Summary.Row>
      );
    };

      

    if (isLoading)
        return (
            <div style={{ width: "100%", height: "100%", minHeight: "inherit", display: "flex", alignItems: "center", justifyContent: "center" }}>
                <Spin size="large" />
            </div>
        )
    else
            return (
                <div>
                    <RangePicker value={dateRange} onChange={dateChanged} format="DD-MM-YYYY" allowEmpty={[false, false]} allowClear={false} style={{margin: '10px'}}></RangePicker>
                    <Table dataSource={sharesData} columns={columns} summary={totalsRow} />
                </div>
            )
}

export {SharesBoughtPage}