import "../Style/LodgementsStyle.css"
import { useMsal } from '@azure/msal-react';
import { Spin, Button, Table, Input} from 'antd';
import { useContext, useEffect, useState } from 'react';
import { Find, FindIndex, HasValue} from '../Helper/JSHelper';
import moment from 'moment';
import { GetMICData, GetVatRate, PostMICData } from '../Data Layer/Data';
import { GetAccessToken } from '../Helper/JWTToken';
import NumericInput from '../Components/NumericInput';
import { MainContext } from "../Components/Context";
import StandardSelect from "../Components/StandardSelect";
import KeySelect from "../Components/KeySelect";
import SaveButton from "../Components/SaveButton";
import PDFInvoice from "../Components/PDFInvoice";
import { pdf } from "@react-pdf/renderer";
import TextEditModal from "../Components/TextEditModal";
import AlertModal from "../Components/AlertModal";

const { TextArea } = Input;

function GetMonthList() {
    let monthList = [];
    
    //let currentMonth = moment().startOf('month');
    let currentMonth = moment.utc(Date()).startOf('month');
    let index = 0;

    do {
        monthList.push({
            key: index,
            display: currentMonth.format('MMMM YYYY'),
            date: currentMonth.toDate()
        });

        currentMonth.add(-1, 'months');
        index++;
    }
    while (index < 60)

    return monthList;
}

function GetInvoiceUpload(invoiceData, monthList) {

    let minMonth = 60;
    let maxMonth = 0;
    let looFee = 0;
    let shareCapital = null;

    let services = [];

    invoiceData.services.forEach(service => {
        looFee += service.net ?? 0;

        if (service.toDateKey < minMonth)
            minMonth = service.toDateKey;

        if (service.fromDateKey > maxMonth)
            maxMonth = service.toDateKey
        
        var cmiService = {
            serviceId: service.serviceId,
            serviceCategoryId: service.categoryId,
            startMonth: monthList[service.fromDateKey].date,
            endMonth: monthList[service.toDateKey].date,
            amount: service.net
        };

        if (service.serviceId == 22 && HasValue(invoiceData.bookeepingSubServices) && invoiceData.bookeepingSubServices.length > 0) {
            cmiService.subServices = [];

            invoiceData.bookeepingSubServices.forEach(subService => {
                if (subService.subServiceId == 3)
                    shareCapital = subService.net;
                else{
                    if (subService.net!==""){
                        looFee += subService.net ?? 0;  
                    }             
                }
                let cmiSubService = {
                    SubServiceId: subService.subServiceId,
                    Amount: subService.net
                };

                if (subService.subServiceId == 4) {
                    cmiSubService.startMonth = monthList[subService.fromDateKey]?.date;
                    cmiSubService.endMonth = monthList[subService.toDateKey]?.date;
                }
                
                cmiService.subServices.push(cmiSubService);
            });
        }

        services.push(cmiService)
    });   

    let result = {
        minMonth: monthList[maxMonth].date,
        maxMonth: monthList[minMonth].date,
        loFee: Math.round(looFee * 100) / 100,
        shareCapital: shareCapital,
        services: services
    }

    return result;
}

function GenerateInvociePreview(invoiceData, vat, description, info, monthList) {

    let date = new Date();
    let dueDate = moment().add(1, "months").toDate();

    if (!HasValue(invoiceData.services) || invoiceData.services.length == 0)
        return;

    let looFee = 0;
    let shareCapital = 0;
    let isMultipleVatRate = false;
    let minMonth = 60;

    invoiceData.services.forEach(service => {
        looFee += service.net ?? 0;

        if (service.toDateKey < minMonth)
            minMonth = service.toDateKey;
    });

    let monthString = moment(monthList[minMonth].date).endOf('year').format("MM/YYYY");
    let yearString = moment(monthList[minMonth].date).format("YYYY");

    if (HasValue(invoiceData.bookeepingSubServices) && invoiceData.bookeepingSubServices.length > 0) {
        invoiceData.bookeepingSubServices.forEach(subService => {
            if (subService.subServiceId == 3)
                shareCapital = subService.net ?? 0;
            else
                looFee += subService.net ?? 0;
        });
    }

    if (shareCapital > 0)
        isMultipleVatRate = true;

    let net = Math.round((looFee + shareCapital) * 100) / 100;
    let looFeeGross = Math.round(looFee * (1 + vat / 100) * 100) / 100;
    let amountDue = Math.round((looFeeGross + shareCapital) * 100) / 100;
    let vatAmount = Math.round((looFeeGross - looFee) * 100) / 100;

    let ref = `${info.clientCode}/${monthString}/${yearString}/syn2`;

    let result = {
        isMonthlyInvoice: false,
        invoiceNumber: "",
        ref: ref,
        dateCreated: date,
        clientTitle: info.clientTitle,
        clientFirstName: info.clientFirstName,
        clientSurname: info.clientSurname,
        clientAddress1: info.clientAddress1,
        clientAddress2: info.clientAddress2,
        clientAddress3: info.clientAddress3,
        clientAddress4: info.clientAddress4,
        clientAddress5: info.clientAddress5,
        description: description,
        dateCancelled: null,
        isMultipleVatRate: isMultipleVatRate,
        vat: vat,
        annualReturnFeeString: shareCapital.toLocaleString(undefined, { minimumFractionDigits: 2 }),
        netString: net.toLocaleString(undefined, { minimumFractionDigits: 2 }),
        vatAmountString: vatAmount.toLocaleString(undefined, { minimumFractionDigits: 2 }),
        loFeeString: looFee.toLocaleString(undefined, { minimumFractionDigits: 2 }),
        loFeeGrossString: looFeeGross.toLocaleString(undefined, { minimumFractionDigits: 2 }),
        amountDueString: amountDue.toLocaleString(undefined, { minimumFractionDigits: 2 }),
        dateDue: dueDate,
        iban: info.iban,
        bic: info.bic,
        isCreditNote: false
    };

    return result;
}

function GetCategoryId (invoiceService, serviceData) {
    let result = null;

    const service = Find(serviceData.services, "serviceId", invoiceService.serviceId)

    if (HasValue(serviceData.clientSubServices))
        serviceData.clientSubServices.forEach(clientSubService => {
            const subService = Find(service.subServices, "subServiceId", clientSubService.subServiceId);

            if (HasValue(subService)) {
                const category = Find(service.categories, "serviceCategoryId", subService.serviceCategoryId)

                if (HasValue(category))
                    result = category.serviceCategoryId;
            }
        });

    return result;
}

function CreateInvoice(services = null, subServices = null) {
    let invoice = {
        services: [],
        bookeepingSubServices: []
    };

    if (services != null) {
        let newServices = services.map((item, i) => {
            return { ...item };
        });

        invoice.services = newServices
    }

    let brIndex = FindIndex(invoice.services, "serviceId", 22);
    if (HasValue(brIndex)) {
        if (!HasValue(subServices) || subServices.length === 0)
            invoice.bookeepingSubServices = [
                {
                    subServiceId: 1,
                    name: "POAC",
                    net: null,
                    hasDates: false,
                    fromDateKey: null,
                    toDateKey: null
                },
                {
                    subServiceId: 2,
                    name: "Prepayment Standard",
                    net: null,
                    hasDates: false,
                    fromDateKey: null,
                    toDateKey: null
                },
                {
                    subServiceId: 4,
                    name: "Vat by IFAC",
                    net: null,
                    hasDates: true,
                    fromDateKey: null,
                    toDateKey: null
                },
                {
                    subServiceId: 3,
                    name: "Share Capital",
                    net: null,
                    hasDates: false,
                    fromDateKey: null,
                    toDateKey: null
                },
            ];
        else {
            invoice.bookeepingSubServices = subServices;

            invoice.bookeepingSubServices.forEach(element => {
                if (element.subServiceId != 4) {
                    element.fromDateKey = invoice.services[brIndex].fromDateKey;
                    element.toDateKey = invoice.services[brIndex].toDateKey;
                }
            });
        }
    }

    return invoice;
}

function CreateInvoiceService(service, serviceCategoryId = null) {
    let category = Find(service.categories, 'serviceCategoryId', serviceCategoryId ?? service.categoryId);
    return {
        serviceId: service.serviceId,
        name: service.name,
        categories: service.categories,
        categoryName: category?.description,
        categoryId: serviceCategoryId ?? service.categoryId,
        toDateKey: service.toDateKey,
        fromDateKey: service.fromDateKey,
        hasNet: service.hasNet,
        net: service.net
    };
}

function CreateMonthlyInvoicePage() {
    const msal = useMsal();
    const context = useContext(MainContext);

    const [isLoading, setIsLoading] = useState(false);
    const [currentVatRate, setCurrentVatRate] = useState(0);
    const [serviceData, setServiceData] = useState(null);
    const [invoiceData, setInvoiceData] = useState(CreateInvoice());
    const [monthList, setMonthList] = useState(GetMonthList());
    const [description, setDescription] = useState("");
    const [modalVisible, setModalVisible] = useState(false);
    const [selectedPayMethod, setSelectedPayMethod] = useState(null);
    const [isHQAdded, setIsHQAdded] = useState(false);

    const paymentList = ["Cash", "DD"];

    useEffect(async () => {
        if (HasValue(context.client.code)) {
            setIsLoading(true);
            const token = await GetAccessToken(msal);
            const data = await GetMICData(token, context.client.code, false);
            const vat = await GetVatRate(token);

            setCurrentVatRate(vat);
            setServiceData(data);
            setIsHQAdded(data.isHQAdded);
            setIsLoading(false);
            setSelectedPayMethod(null);
        }
        else{
            setServiceData(null);
            setSelectedPayMethod(null);
        }
    }, [context.client.code]);

    useEffect(async () => {
        setDescription("")
        setInvoiceData(CreateInvoice());
    }, [serviceData]);

    const genetareDescription = (data) => {
        if (!HasValue(data.services) || data.services.length == 0)
            return "";

        let result = "";

        for (let i = 0; i < data.services.length; i++) {
            let serviceString = "";

            switch(data.services[i].serviceId) {
                case 5:{
                    serviceString = "Payroll Service";
                    break;
                }
                case 22: {
                    serviceString = "Recorder Bookkeeping Service and ongoing Local Office support ";
                    break;
                }
                case 23: {
                    serviceString = "Bookeeping Service";
                    break;
                }
                case 28: {
                    serviceString = "FarmPro Service";
                    break;
                }
                case 30: {
                    serviceString = "Professional Services";
                    break;
                }
                case 11: {
                    serviceString = "Professional Services";
                    break;
                }
                case 29:{
                    serviceString = "Administration Fee";
                    break;
                }
                default: {
                    serviceString = `${data.services[i].name} Service`;
                    break;
                }
            }

            let monthString = `${monthList[data.services[i].fromDateKey]?.display} to ${monthList[data.services[i].toDateKey]?.display}`;

            if (data.services[i].fromDateKey == data.services[i].toDateKey)
                monthString = monthList[data.services[i].fromDateKey]?.display;
            

            if (serviceString === "Administration Fee")
                result += `${i + 1}. ${serviceString} for ${monthString}.\n\n`;
            else
                result += `${i + 1}. Provision of ${serviceString} for ${monthString}.\n\n`;
        }

        setDescription(result);
    }

    const computeNet = (invoiceData, service) => {
        const test = serviceData;

        if (!HasValue(service.categoryId) || !HasValue(service.fromDateKey) || !HasValue(service.toDateKey))
            return;

        let serviceInfo = null;
        let subServiceInfo = null;

        if (service.serviceId !== 22) {
            serviceInfo = Find(serviceData.services, "serviceId", service.serviceId);
            subServiceInfo = Find(serviceInfo.subServices, "serviceCategoryId", service.categoryId)
            if (!HasValue(subServiceInfo))
                service.net = null;
            else
                service.net = Math.round(calcFees(subServiceInfo.fees, monthList[service.fromDateKey], monthList[service.toDateKey]) * 100) / 100;     
        }
        else {
            serviceInfo = Find(serviceData.services, "serviceId", 22);

            for (let i = 0; i < invoiceData.bookeepingSubServices.length; i++) {
                if (invoiceData.bookeepingSubServices[i].subServiceId == 4 || invoiceData.bookeepingSubServices[i].subServiceId == 3)
                    continue;

                invoiceData.bookeepingSubServices[i].net = null;

                for (let j = 0; j < serviceInfo.subServices.length; j++) {
                    if (serviceInfo.subServices[j].serviceCategoryId == service.categoryId && serviceInfo.subServices[j].subServiceType == invoiceData.bookeepingSubServices[i].subServiceId) {
                        invoiceData.bookeepingSubServices[i].net = Math.round(calcFees(serviceInfo.subServices[j].fees, monthList[service.fromDateKey], monthList[service.toDateKey]) * 100) / 100;
                        break;
                    }
                }
            }
        }
    }

    const calcFees = (fees, fromDate, toDate) =>{
        let current = moment(fromDate.date);
        let end = moment(toDate.date);
        let result = 0;

        while (!current.isAfter(end, 'days'))
        {
            var test = current.year();
            let fee = Find(fees, 'year', current.year());
            if (HasValue(fee))
                result += fee.fee;

            current = current.add(1, 'months');
        }

        return result;
    }

    const serviceChanged = (oldServiceId, newServiceId) => {
        let invoiceClone = CreateInvoice(invoiceData.services, invoiceData.bookeepingSubServices);

        if (oldServiceId == -1 && newServiceId != -1) //insert new service
        {
            let newService = Find(serviceData.services, "serviceId", newServiceId);
            let newInvoiceService = CreateInvoiceService(newService, GetCategoryId(newService, serviceData));
            invoiceClone.services.push(newInvoiceService);
        }
        else if (newServiceId == -1) //delete existing service
        {
            const index = FindIndex(invoiceClone.services, "serviceId", oldServiceId);
            invoiceClone.services.splice(index, 1);

        }
        else //swap existing service
        {
            const index = FindIndex(invoiceClone.services, "serviceId", oldServiceId);
            let newService = Find(serviceData.services, "serviceId", newServiceId);
            let newInvoiceService = CreateInvoiceService(newService, GetCategoryId(newService, serviceData));
            invoiceClone.services[index] = newInvoiceService;
        }

        invoiceClone = CreateInvoice(invoiceClone.services, invoiceClone.bookeepingSubServices);

        setInvoiceData(invoiceClone);
        genetareDescription(invoiceClone);
    };

    const categoryChanged = (serviceId, categoryId) => {
        let invoiceClone = CreateInvoice(invoiceData.services, invoiceData.bookeepingSubServices);

        const index = FindIndex(invoiceClone.services, "serviceId", serviceId);
        let service = invoiceClone.services[index];
        service.categoryId = categoryId;
        invoiceClone.services[index] = CreateInvoiceService(service);

        computeNet(invoiceClone, invoiceClone.services[index]);

        setInvoiceData(invoiceClone);
    };

    const monthChanged = (serviceId, monthKey, isFrom, isSubService) => {
        let invoiceClone = CreateInvoice(invoiceData.services, invoiceData.bookeepingSubServices);

        if (isSubService) {
            const index = FindIndex(invoiceClone.bookeepingSubServices, "subServiceId", serviceId);
            let service = invoiceClone.bookeepingSubServices[index];
            if (isFrom)
                service.fromDateKey = monthKey;
            else
                service.toDateKey = monthKey;

            if (HasValue(service.fromDateKey) && HasValue(service.toDateKey) && service.fromDateKey < service.toDateKey) {
                service.fromDateKey = monthKey;
                service.toDateKey = monthKey;
            }

            invoiceClone.bookeepingSubServices[index] = { ...service };
        }
        else {
            const index = FindIndex(invoiceClone.services, "serviceId", serviceId);
            let service = invoiceClone.services[index];
            if (isFrom)
                service.fromDateKey = monthKey;
            else
                service.toDateKey = monthKey;

            if (HasValue(service.fromDateKey) && HasValue(service.toDateKey) && service.fromDateKey < service.toDateKey) {
                service.fromDateKey = monthKey;
                service.toDateKey = monthKey;
            }

            invoiceClone.services[index] = CreateInvoiceService(service);
            computeNet(invoiceClone, invoiceClone.services[index]);
        }

        genetareDescription(invoiceClone);

        setInvoiceData(invoiceClone);
    }

    const netChanged = (serviceId, val, isSubService) => {
        if (isNaN(val))
            return;

        if (val < 0)
            return;

        let invoiceClone = CreateInvoice(invoiceData.services, invoiceData.bookeepingSubServices);

        if (isSubService) {
            const index = FindIndex(invoiceClone.bookeepingSubServices, "subServiceId", serviceId);
            let service = invoiceClone.bookeepingSubServices[index];
            service.net = val;
        }
        else {
            const index = FindIndex(invoiceClone.services, "serviceId", serviceId);
            let service = invoiceClone.services[index];
            service.net = val;

            invoiceClone.services[index] = CreateInvoiceService(service);
        }

        setInvoiceData(invoiceClone);
    }

    const getGross = (net) => {
        if (!HasValue(net))
            return null;

        return Math.round(net * (1 + currentVatRate / 100) * 100) / 100;
    }

    const columns = [
        {
            title: 'Service',
            render: (text, record) => {
                if (!record.isSubService)
                    return (
                        <KeySelect style={{ width: "230px" }} selectedItem={record.serviceName} items={record.serviceSelect} onChange={(val) => serviceChanged(record.serviceId, Number(val))} />
                    )

                else
                    return (
                        <p>{record.serviceName}</p>
                    )
            }
        },
        {
            title: 'Category Code / Level',
            render: (text, record) => record.hasCategories && <KeySelect style={{ width: "250px" }} selectedItem={record.categoryName} items={record.categorySelect} onChange={(val) => categoryChanged(record.serviceId, Number(val))} />
        },
        {
            title: 'From',
            render: (text, record) => record.hasDates && <KeySelect style={{ width: "150px" }} selectedItem={(HasValue(record.fromDateKey)) ? monthList[record.fromDateKey].display : null} items={monthList} onChange={(val) => monthChanged(record.serviceId, Number(val), true, record.isSubService)} />
        },
        {
            title: 'To',
            render: (text, record) => record.hasDates && <KeySelect style={{ width: "150px" }} selectedItem={(HasValue(record.toDateKey)) ? monthList[record.toDateKey].display : null} items={monthList} onChange={(val) => monthChanged(record.serviceId, Number(val), false, record.isSubService)} />
        },
        {
            title: 'Net',
            render: (text, record) => {
                if (record.serviceId === -2|
                    record.hideShareCapitalFields === 1) {// shareCapital with Category 5 or 5S
                    return (
                    <p style={{ width: "100%", textAlign: "right",disabled:"true"  }}>{record.net?.toLocaleString(undefined, { minimumFractionDigits: 2 })}</p>
                    )
                }
                else if (record.hasAmounts)
                    return (
                        <NumericInput style={{ textAlign: "right" }} value={record.net} setValue={(e) => netChanged(record.serviceId, e, record.isSubService)} />
                    )
            }
        },
        {
            title: 'Gross',
            render: (text, record) => {
                if (record.hasAmounts) {
                    if (record.serviceId === -2) {
                        return (
                        <p style={{ width: "100%", textAlign: "right" }}>{record.gross?.toLocaleString(undefined, { minimumFractionDigits: 2 })}</p>
                        )
                    }
                    else if (record.isSubService && record.serviceId == 3)
                        return (
                            <p style={{ width: "100%", textAlign: "right" }}>{record.net?.toLocaleString(undefined, { minimumFractionDigits: 2 })}</p>
                        )
                    else
                        return (
                            <p style={{ width: "100%", textAlign: "right" }}>{getGross(record.net)?.toLocaleString(undefined, { minimumFractionDigits: 2 })}</p>
                        )
                }
            }
        },
    ];

    const generateRow = (service) => {
        if (service == null) {
            return {
                serviceName: null,
                serviceId: -1,
                hasCategories: false,
                categoryName: null,
                categoryId: null,
                categorySelect: [],
                hasDates: false,
                hasAmounts: false,
                isSubService: false,
                fromDateKey: null,
                toDateKey: null,
                net: null
            };
        }

        let hasCategories = false;
        let categorySelect = [];
        let hasAmounts = true;
        if (service.serviceId == 22)
            hasAmounts = false;
        if (HasValue(service.categories) && service.categories.length > 0) {
            hasCategories = true;
            categorySelect = service.categories.map((category, i) => {
                return {
                    key: category.serviceCategoryId,
                    display: category.description
                }
            });
        }
        let newRow = {
            serviceName: service.name,
            serviceId: service.serviceId,
            hasCategories: hasCategories,
            categorySelect: categorySelect,
            categoryName: service.categoryName,
            categoryId: service.categoryId,
            hasDates: true,
            hasAmounts: hasAmounts,
            isSubService: false,
            fromDateKey: service.fromDateKey,
            toDateKey: service.toDateKey,
            net: service.net
        }

        return newRow;
    };

    const generateBookepingRows = (subServices,service) => {
        let newRows = [];

        let netTotal = 0;
        let grossTotal = 0;

        let hideShareCapitalFields=0;//don't hide share capital fiels

        subServices.forEach(subservice => {
            var net = 0;
            if (!isNaN(subservice.net))
                net = Number(subservice.net); 

            netTotal += net;

            if (subservice.subServiceId == 3)// Share Capital
             {  
                if (service.categoryId && (service.categoryId===3 //Category 5
                ||service.categoryId===4//Category 5S
                 ))
                    {
                        hideShareCapitalFields=1;//hide share capital net field
                    }
                else{
                    grossTotal += net;
                }
            }
            else
                grossTotal += getGross(net) ?? 0;


            newRows.push({
                serviceName: subservice.name,
                serviceId: subservice.subServiceId,
                hasCategories: false,
                categoryName: null,
                categoryId: null,
                categorySelect: [],
                hasDates: subservice.hasDates,
                hasAmounts: true,
                isSubService: true,
                fromDateKey: subservice.fromDateKey,
                toDateKey: subservice.toDateKey,
                net: subservice.net,
                hideShareCapitalFields:hideShareCapitalFields

            });
        });

        //Add Totals row
        newRows.push({
            serviceName: "",
            serviceId: -2,
            hasCategories: false,
            categoryName: null,
            categoryId: null,
            categorySelect: [],
            hasDates: false,
            hasAmounts: true,
            isSubService: true,
            fromDateKey: null,
            toDateKey: null,
            net: netTotal,
            gross: grossTotal
        })

        return newRows;
    }

    const generateTableRows = () => {
        let rows = [];

        let serviceSelect = [];

        serviceData.services.forEach((service) => {
            const index = FindIndex(invoiceData.services, "serviceId", service.serviceId);

            if (!HasValue(index))
                serviceSelect.push({
                    key: service.serviceId,
                    display: service.name
                });
        });

        serviceSelect.push({
            key: -1,
            display: "None"
        });

        invoiceData.services.forEach(service => {
            let newRow = generateRow(service);
            newRow.serviceSelect = serviceSelect;

            rows.push(newRow);

            if (service.serviceId == 22) {
                rows.push(...generateBookepingRows(invoiceData.bookeepingSubServices,service));
            }
        });

        let netTotal = 0;
        let grossTotal = 0;

        rows.forEach(row => {
            if (row.serviceId == -2)
                return;

            var net = 0;
            if (!isNaN(row.net))
                net = Number(row.net); 

            netTotal += net;

            if (row.isSubService && row.serviceId == 3)
                grossTotal += net;
            else
                grossTotal += getGross(net) ?? 0;
        });

        //Add empty row if necessary
        if (invoiceData.services.length < 6) {
            let newRow = generateRow(null);
            newRow.serviceSelect = serviceSelect;

            rows.push(newRow);
        }

        //Add Totals row
        rows.push({
            serviceName: "Total",
            serviceId: -2,
            hasCategories: false,
            categoryName: null,
            categoryId: null,
            categorySelect: [],
            hasDates: false,
            hasAmounts: true,
            isSubService: true,
            fromDateKey: null,
            toDateKey: null,
            net: netTotal,
            gross: grossTotal
        })

        return rows;
    }

    const setTitle = () => {
        let text = "Create Monthly Invoice";

        if (HasValue(context.client.code)) {
            text += ` - ${context.client.code} - ${context.client.title} ${context.client.firstName} ${context.client.surname}`;
        }

        return (
            <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                <h3>{text}</h3>
            </div>
        );
    }

    const showInvoice = async () => {
        const invoice = GenerateInvociePreview(invoiceData, currentVatRate, description, serviceData.info, monthList);
        const blob = await pdf(<PDFInvoice invoice={invoice} draft={true}/>).toBlob();
        let url = URL.createObjectURL(blob)
        window.open(url, "_blank");
    }

    const saveDisabled = () => {
        if (!HasValue(selectedPayMethod))
            return true;

        if (invoiceData.services.length < 1)
            return true;
        let result = false;
        invoiceData.services.forEach(service => {
            if (HasValue(service.categories) && service.categories.length > 0 && !HasValue(service.categoryId))
                result = true;

            if (!HasValue(service.fromDateKey) || !HasValue(service.toDateKey))
                result = true;

            if (service.serviceId != 22 && (service.net ?? 0) == 0)
                result = true;
        });

        return result;
    }

    const editDescription = () => {
        setModalVisible(true);
    }

    const payMethodChanged = (val) => {

        setSelectedPayMethod(val)
    }

    const saveInvoice = async () => {

        if (selectedPayMethod == "1"){
            var hasNonPayrollService = false;
            invoiceData.services.forEach(service => {  
                if (service.serviceId != 5 && service.serviceId > 0) 
                    hasNonPayrollService = true;          
            });
            
            if (hasNonPayrollService && !serviceData.hasBankDetails) {
                AlertModal(serviceData.message238);
                return;
            }

            const payrollIndex = Find(invoiceData.services, "serviceId", 5);
            if (HasValue(payrollIndex) && !serviceData.hasPayrollBankDetails) {
                AlertModal(serviceData.message238);
                return;
            }
        }

        let data = GetInvoiceUpload(invoiceData, monthList);

        data.description = description;
        data.vatRate = currentVatRate;
        data.clientCode = Number(context.client.code);
        data.payMethod = selectedPayMethod;

        const token = await GetAccessToken(msal);
        const result = await PostMICData(token, data);
        if (result.status == 200) {
            AlertModal("Invoice created.");
            setDescription("")
            setInvoiceData(CreateInvoice());
        }
        else
            AlertModal("Failed to create invoice.");
    }

    if (isLoading)
        return (
            <div style={{ width: "100%", height: "100%", minHeight: "inherit", display: "flex", alignItems: "center", justifyContent: "center" }}>
                <Spin size="large" />
            </div>
        )
    if (serviceData === null)
        return (
            <p style={{ padding: "20px" }}>Select a client to create an invoice.</p>
        )
    else
        return (
            <>
                <div className="div-ant-table center-cells" style={{ maxHeight: "100%", width: "100%", overflow: "auto" }}>
                    <form>
                    <Table title={setTitle} dataSource={generateTableRows()} columns={columns} pagination={{position: ['none', 'none'], defaultPageSize: 12}} />
                    </form>
                </div>
                { 
                    isHQAdded &&
                    <div style={{display: "flex", justifyContent: "space-between", alignItems:"center", marginTop: "15px"}}>
                        <div style={{display: "flex", justifyContent: "flex-start", alignItems:"center"}}>
                            <p style={{fontWeight:"bold", width:"max-content"}}>Invoice Payment Method:</p>
                            <StandardSelect style={{width:"100px", marginLeft: "15px"}} items={paymentList} selectedItem={selectedPayMethod} onChange={payMethodChanged}/>
                        </div>
                        <div style={{ width: "100%", display: "flex", justifyContent: "flex-end" }}>
                            <SaveButton text="Create" disabled={saveDisabled()} callback={saveInvoice} />
                            <Button type="primary" style={{ marginLeft: "10px" }} disabled={saveDisabled()} onClick={showInvoice}>Preview</Button>
                            <Button type="primary" style={{ marginLeft: "10px" }} disabled={saveDisabled()} onClick={editDescription}>Inv Desc</Button>
                            <TextEditModal toEdit={description} visible={modalVisible} setVisible={setModalVisible} callback={setDescription} />
                        </div>
                    </div>
                }
                {
                    !isHQAdded &&
                    <div style={{display: "flex", justifyContent: "flex-start", alignItems:"center", marginTop: "15px"}}>
                        <h3>An invoice cannot be created for this client as they have not yet been added by HQ</h3>
                    </div>
                }
            </>
        )
}

export default CreateMonthlyInvoicePage;