import { Select } from "antd";
import { HasValue } from "../Helper/JSHelper";

const { Option } = Select;

function KeySelect({ items, selectedItem, onChange, disabled, style }) {
    if (HasValue(items))
        return (
            <Select value={selectedItem} onChange={onChange} disabled={disabled} style={style}>
                {items.map((item, index) => (
                    <Option key={item.key}>{item.display}</Option>
                ))}
            </Select>
        )
    else
        return (
            <p>Error</p>
        )
}

export default KeySelect;