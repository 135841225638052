import {useState} from 'react';
import {Input, Image} from 'antd'; 
import { _imgGreenFields, _imgFeenomLogo } from '../Resources/Resources';
import TermsFooter from '../Components/TermsFooter';
import SignInButton from '../Components/SignInButton'


function LoginPage() {
    return (
    <>
        <div className='div-login' style = {{backgroundImage: `url(${_imgGreenFields})`, backgroundSize: 'cover'}}>
            <TextBox title='Anticipating. Informing. Growing.' style={{position: 'absolute', top: '80%', left: '50%', transform: 'translate(-50%, -50%)', width: 'fit-content'}}>Accountants and financial advisors for forward-looking businesses in Ireland</TextBox>
        </div>
        <div className='div-login'>
            <Login></Login>
            <TermsFooter style={{position: 'absolute',  width: 'fit-content', top: '100%', left: '50%', transform: 'translate(-50%, -100%)'}}></TermsFooter>
        </div>
    </>
    )
}

function TextBox(props) {
    const style = props.style;
    
    return (
        <div className='div-text-card' style={style}>
            <h2 style={{color: 'inherit'}}>{props.title}</h2>
            <div style={{width: '400px'}}>
                <p>{props.children}</p>
            </div>
        </div>
    )
}

function Login() {

    return (
    <div style={{position: 'absolute', top: '50%', left: '50%', display:'flex', flexDirection:'row', alignItems:'center', transform: 'translate(-50%, -50%)', width: 'fit-content', height: 'fit-content'}}>
        <div style={{float: 'left', marginRight: '20px'}}>
            <Image src={_imgFeenomLogo} preview={false} ></Image>
        </div>
        <div style = {{float: 'left', width: '250px', height: '110px', display: 'flex', flexDirection: 'column', justifyContent: 'center'}}>         
            <SignInButton style={{width: '200px', height: '50px', fontSize: '1.5em'}}>Login</SignInButton>
        </div>
        
    </div>
    )
}

export default LoginPage;