
import { Button, Input } from "antd";
import { SearchOutlined } from "@ant-design/icons";

function handleSearch(selectedKeys, confirm, dataIndex) {
  confirm();
};

function handleReset(clearFilters) {
  clearFilters();
};

function ColumnSearchProps(dataIndex, name, isNumeric = false) {
  let searchInput;
  const result =
  {
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => {

      return (
        <div style={{ padding: 8 }}>
          <Input
            ref={node => {
              searchInput = node;
            }}
            placeholder={`Search ${name}`}
            value={selectedKeys[0]}
            onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
            onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
            style={{ width: 188, marginBottom: 8, display: 'block' }}
          />
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90, marginRight: 8 }}
          >
            Search
          </Button>
          <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
            Reset
          </Button>
        </div>
      );
    },
    filterIcon: filtered => (

      <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilter: (value, record) => {
      if (isNumeric) {
        if (!isNaN(value) && Number(value) == record[dataIndex]) 
          return true;
        else if (!isNaN(record[dataIndex]) && (record[dataIndex]?.toLocaleString(undefined, { minimumFractionDigits: 2 }) == value || record[dataIndex]?.toLocaleString(undefined, { minimumFractionDigits: 0 }) == value))
          return true;
      }

      return  record[dataIndex]?.toString().toLowerCase().includes(value.toLowerCase() ?? false)
    },
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.select());
      }
    },
    render: text => text,
  }
  return result;
};

export default ColumnSearchProps;