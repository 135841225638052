import { useContext } from "react";
import { HasValue } from "../Helper/JSHelper";
import { MainContext } from "./Context";

function HeaderAction () {
    const context = useContext(MainContext);

    if (!HasValue(context.action1))
        return null;
    else
        return (
            <context.action1.test></context.action1.test>
        )
}

export default HeaderAction;