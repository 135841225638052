import { useState } from "react";
import { useEffect } from "react";
import { Spin, Table, Button, Input, Pagination, DatePicker } from "antd";
import { GetAccessToken } from "../Helper/JWTToken";
import { useMsal } from "@azure/msal-react";
import moment from "moment";
import { HasValue } from "../Helper/JSHelper";

const { RangePicker } = DatePicker;

function DebtorsControlAccountPage() {
  const defDateRange = [moment().startOf("month"), moment()];
  const msal = useMsal();
  const [isLoading, setIsLoading] = useState(false);
  const [debtorsData, setDebtorData] = useState();
  const [dateRange, setDateRange] = useState(defDateRange);

  useEffect(async () => {
    setIsLoading(true);

    let startDate;
    let endDate;

    if(HasValue( dateRange)){
        startDate = dateRange[0]?.format;
        endDate = dateRange[1]?.format;
    }

    const token = await GetAccessToken(msal);
    // const data = await

    setIsLoading(false);
  }, [dateRange]);

  const dateChanged = (val) => {
    setDateRange(val);
};

  //   const columns = [
  //       {
  //         title: 'Office',
  //         dataIndex: 'clientCode',
  //         key: 'clientCode',
  //         sorter: (a,b) => (a.clientCode < b.clientCode) ? -1 : 1,
  //       },
  //       {
  //         title: 'Client Code',
  //         dataIndex: 'clientCode',
  //         key: 'clientCode',
  //         sorter: (a,b) => (a.clientCode < b.clientCode) ? -1 : 1,
  //       },
  //       {
  //         title: 'Client Code',
  //         dataIndex: 'clientCode',
  //         key: 'clientCode',
  //         sorter: (a,b) => (a.clientCode < b.clientCode) ? -1 : 1,
  //       },
  //       {
  //         title: 'Client Code',
  //         dataIndex: 'clientCode',
  //         key: 'clientCode',
  //         sorter: (a,b) => (a.clientCode < b.clientCode) ? -1 : 1,
  //       },
  //       {
  //         title: 'Client Code',
  //         dataIndex: 'clientCode',
  //         key: 'clientCode',
  //         sorter: (a,b) => (a.clientCode < b.clientCode) ? -1 : 1,
  //       },
  //       {
  //         title: 'Client Code',
  //         dataIndex: 'clientCode',
  //         key: 'clientCode',
  //         sorter: (a,b) => (a.clientCode < b.clientCode) ? -1 : 1,
  //       },
  //       {
  //         title: 'Client Code',
  //         dataIndex: 'clientCode',
  //         key: 'clientCode',
  //         sorter: (a,b) => (a.clientCode < b.clientCode) ? -1 : 1,
  //       },
  //       {
  //         title: 'Client Code',
  //         dataIndex: 'clientCode',
  //         key: 'clientCode',
  //         sorter: (a,b) => (a.clientCode < b.clientCode) ? -1 : 1,
  //       },
  //       {
  //         title: 'Client Code',
  //         dataIndex: 'clientCode',
  //         key: 'clientCode',
  //         sorter: (a,b) => (a.clientCode < b.clientCode) ? -1 : 1,
  //       },
  //       {
  //         title: 'Client Code',
  //         dataIndex: 'clientCode',
  //         key: 'clientCode',
  //         sorter: (a,b) => (a.clientCode < b.clientCode) ? -1 : 1,
  //       },
  //       {
  //         title: 'Client Code',
  //         dataIndex: 'clientCode',
  //         key: 'clientCode',
  //         sorter: (a,b) => (a.clientCode < b.clientCode) ? -1 : 1,
  //       },
  //   ]

  if (isLoading)
    return (
      <div style={{width: "100%", height: "100%", minHeight: "inherit", display: "flex", alignItems: "center", justifyContent: "center", }}>
        <Spin size="large" />
      </div>
    );
  else
    return (
      <div>
        <RangePicker value={dateRange} format="DD-MM-YYYY" onChange={dateChanged} allowEmpty={[false, false]} allowClear={false} style={{ margin: "10px" }}></RangePicker>
        {/* <Table dataSource={sharesData} columns={columns} /> */}
      </div>
    );
}

export { DebtorsControlAccountPage };
