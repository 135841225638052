
import { useMsal } from "@azure/msal-react";
import { Button }  from 'antd'; 
import { loginRequest } from "../authConfig";


function handleLogin(instance) {
    instance.loginRedirect(loginRequest).catch(e => {
        console.error(e);
    });
}

/**
 * Renders a button which, when selected, will redirect the page to the login prompt
 */
function SignInButton(props ){
    const { instance } = useMsal();

    return (
        <Button type='primary' className="btn-login" style={{width: 'fit-content', ...props.style}} onClick={() => handleLogin(instance)}>{props.children}</Button>
    );
}

export default SignInButton;