import { useMsal } from "@azure/msal-react";
import { Dropdown, Menu }  from 'antd'; 
import { useContext, useEffect, useState } from "react";
import { MainContext } from "../Components/Context";
import {  GetOffices } from "../Data Layer/Data";
import { HasValue } from "../Helper/JSHelper";
import { GetAccessToken } from "../Helper/JWTToken";
import { DownOutlined } from '@ant-design/icons';

function OfficeCard({hasAllOffices = false}) {
    const context = useContext(MainContext);
    let initialText = "Select office...";

    if (HasValue(context.office.code) && HasValue(context.office.name))
        initialText = `${context.office.code} - ${context.office.name}`;
    else if (HasValue(context.office.name))
        initialText = context.office.name;

    const [officeDropdown, setOfficeDropdown] = useState(<></>);
    const [singleOffice, setSingleOffice] = useState(true);
    const msal = useMsal();

    const callback = (code, name) => {
        
        const office = {code, name, singleOffice: false};

        context.setOffice(office);

        const client = {code: null, firstName: null, surname: null,isNewClient:null};

        context.setClient(client);
        sessionStorage.removeItem("clientCode");
        sessionStorage.removeItem("clientFirstName");
        sessionStorage.removeItem("clientSurname");
        sessionStorage.removeItem("isNewClient");
    };

    useEffect(async () => {
        const token = await GetAccessToken(msal);
        const data = await GetOffices(token);

        if (HasValue(data.offices))
        {
            if (data.offices.length === 1)
            {
                setSingleOffice(true);

                const office = {code: data.offices[0].officeCode, name: data.offices[0].officeName, singleOffice: true};
                context.setOffice(office);
            }
            else
            {
                setSingleOffice(false);  
                setOfficeDropdown(GenerateOfficesDropdown(data.offices, callback, hasAllOffices)) 
            }             
        }
    }, []);

    if (singleOffice)
        return (
            <div></div>
        )
    else
        return (
            <div style={{display:"flex", justifyContent:"flex-start", alignItems:"center"}}>
                <h3 style={{marginRight:"15px", position:"relative", top:"-2px"}}>Office: </h3>
                <Dropdown overlay={officeDropdown}>
                    <a className="ant-dropdown-link" style={{color:"unset"}} onClick={e => e.preventDefault()}> {initialText}   <DownOutlined/> </a>
                </Dropdown>
            </div>
        )
}

function GenerateOfficesDropdown(officeList, callback, hasAllOffices) {

        return (
            <Menu style={{maxHeight: '400px', overflowY:'auto'}}>
                <Menu.Item key="-1">
                            <a onClick={() => {callback(-1, null)}}>
                                No selection
                            </a>
                </Menu.Item>
                {
                    hasAllOffices &&
                    <Menu.Item key="-2">
                                <a onClick={() => {callback(null, 'All offices')}}>
                                    All offices
                                </a>
                    </Menu.Item>
                }       
                {
                    officeList.map((office, i) =>
                        <Menu.Item key={i}>
                            <a onClick={() => {callback(office.officeCode, office.officeName)}}>
                                {`${office.officeCode} - ${office.officeName}`}
                            </a>
                        </Menu.Item>
                    )
                }
            </Menu>
        )
}

export default OfficeCard;