import { useMsal } from "@azure/msal-react";
import { Button, Input } from "antd";
import MainLayout from "./MainLayout";
import { useState } from "react";
import { GetTestMessage } from "../Data Layer/Data";
import { GetAccessToken} from "../Helper/JWTToken";

function HomePage() {
    
    const msal = useMsal();
    const [ token, setToken ] = useState('No token');
    const [ message, setMessage ] = useState("No message");

    const onClick = async () => {
        try {           
            const result = await GetTestMessage(token);
            setMessage(result);
        }
        catch (e) {
            debugger;
        }
    }

    const getToken = async() => {
        try {
           var token = await GetAccessToken(msal);    
           setToken(token);     
        }
        catch(e) {
            setToken("Unable to aquire access token");
        }
    }
    
    /*return (
        <>
            <Button onClick={onClick}>Get Test Message</Button>
            <Button onClick={getToken}>Get Token</Button>            
            <p>{token}</p>
            <p>{message}</p>
        </>
    )*/
    
    return (
        <></>
    );
}

export default HomePage;