import { Input } from "antd";
import { useState } from "react";
import { HasValue } from "../Helper/JSHelper";

function NumericInput ({value, style, setValue}) {

    try{     
    const [isInFocus, setIsInFocus] = useState(false);
    
    const onFocus = (e) => {
        setIsInFocus(true);
    }
    const onBlur = (e) => {
        setIsInFocus(false);
        if (isNaN(value) || !HasValue(value))
            return;
        
        let number = Math.round(Number(value) * 100) / 100;
        setValue(number);
    }
    
    function displayValue(value, isFocused) {
        if (isFocused)
            return value;
        else
        {
            if (isNaN(value) || !HasValue(value))
                return value;

            let number = Number(value);
            return number?.toLocaleString(undefined, { minimumFractionDigits: 2 })
        }
    }

    return (
        <Input value={displayValue(value, isInFocus)} style={style} onChange={(e) => setValue(e.target.value)} onFocus={onFocus} onBlur={onBlur}/>
    )
    }
    catch (e) {
        console.log(e);
        return <p>Error</p>
    }
}

export default NumericInput