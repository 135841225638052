import { Page, Text, View, Document, Image, StyleSheet, Font, Link } from '@react-pdf/renderer';
import { HasValue } from '../Helper/JSHelper';
import { _fontCalibri, _fontCalibriBold, _fontCalibriItalic, _imgGreenFields, _imgIacLogoWhite, _imgIfacLogoLong } from '../Resources/Resources';

function PDFInvoice ({invoice, draft = false}) {

    Font.register({family: "Calibri", src:_fontCalibri});
    Font.register({family: "Calibri", fontStyle:"normal", fontWeight:"bold", src:_fontCalibriBold});
    Font.register({family: "Calibri", fontStyle:"italic", fontWeight:"normal", src:_fontCalibriItalic});

    const styles = StyleSheet.create({
        page: {
          marginLeft:"20px",
          paddingRight:"40px",
          marginTop:"10px",
          marginBottom:"10px",
          paddingBottom:"10px",
          fontSize:"12px",
          fontFamily:"Calibri",
          height:"100%"
        },
        watermarkCancelled:{
          position:"fixed",
          bottom:"10%",
          left:"10%",
          transform:"rotate(-30deg)",
          color:"red",
          opacity:"0.4",
          fontWeight:"bold",
          fontSize:"90"
        },
        watermarkDraft:{
          position:"fixed",
          bottom:"10%",
          left:"25%",
          transform:"rotate(-30deg)",
          color:"red",
          opacity:"0.4",
          fontWeight:"bold",
          fontSize:"90"
        },
        link: {
          color:"blue",
          textDecoration:"underline"
        }
      });

      let serviceText = "Professional Services Provided";
      let returnFeeText = "Share Capital";

      if (invoice.isMonthlyInvoice)
      {
        serviceText = `Professional Services Provided for ${invoice.monthDisplay}`;
      }

      let titleText = "Invoice";
      if (invoice.isCreditNote)
      {
        if (invoice.isBadDebtCN)
        {
          returnFeeText = "Share Capital";
          titleText = "Bad Debt Credit Note";
          serviceText = "Debt Written Off";
        }
        else
        {
          returnFeeText = "Share Capital";
          titleText = "Credit Note";
          serviceText = "Change in Professional Services Provided";
        }
      }
      else if (invoice.hasAnnualReturnFee)
        returnFeeText = null;//"Annual Return Fee";

      let factor = 1;
      if (!HasValue(invoice.clientAddress1))
        factor++;
      if (!HasValue(invoice.clientAddress2))
        factor++;
      if (!HasValue(invoice.clientAddress3))
        factor++;
      if (!HasValue(invoice.clientAddress4))
        factor++;
      if (!HasValue(invoice.clientAddress5))
        factor++;

      let variableSpace = 12 * factor + "px";
var officeAddress= '';
if (HasValue(invoice.address1Office)){
  officeAddress+= invoice.address1Office;
}
if (HasValue(invoice.address2Office)){
  officeAddress+=', '+ invoice.address2Office;
}
if (HasValue(invoice.address3Office)){
  officeAddress+=', '+ invoice.address3Office;
}
if (HasValue(invoice.address4Office)){
  officeAddress+=', '+ invoice.address4Office;
}
if (HasValue(invoice.address5Office)){
  officeAddress+=', '+ invoice.address5Office;
}
if (HasValue(invoice.address6Office)){
  officeAddress+=', '+ invoice.address6Office;
}
if (HasValue(invoice.eircodeOffice)){
  officeAddress+=', '+ invoice.eircodeOffice;
}
var secondOfficeAddress=''
if (HasValue(invoice.phone1Office)){
  secondOfficeAddress+=invoice.phone1Office;
}
if (HasValue(invoice.officeEmail)){
  secondOfficeAddress+='|'+invoice.officeEmail;
}
if (HasValue(secondOfficeAddress))
{
  secondOfficeAddress+=('| www.ifac.ie');
}else{
  secondOfficeAddress='www.ifac.ie';
}             
  

//check how many pages are needed for invoice

let invoiceDescriptionRows=invoice.description.split(/\r\n|\r|\n/);     
let invoiceDescriptionRowCount=invoiceDescriptionRows.length;
let rowsPerLastPage=25;// on last page there is more text to be added Net
let rowsPerPage=30;
var pageCount=1;// the last page/Math.ceil(invoiceDescriptionRowCount/rowsPerPage);
var remainingDescriptionRowCount=invoiceDescriptionRowCount;
let pageRows = [];
if (invoiceDescriptionRowCount>rowsPerLastPage){//at least one page
  while(remainingDescriptionRowCount>rowsPerLastPage){

    remainingDescriptionRowCount-=rowsPerPage;
    pageRows.push(invoiceDescriptionRows.slice((pageCount-1)*rowsPerPage,(pageCount-1)*rowsPerPage+rowsPerPage));
    pageCount++;
  }
  pageRows.push(invoiceDescriptionRows.slice((pageCount-1)*rowsPerPage,(pageCount-1)*rowsPerPage+rowsPerLastPage))
}else{
  pageCount=1;
  pageRows[0]=invoiceDescriptionRows;
}

  return (
    <Document title={`Invoice ${invoice.invoiceNumber}`}>
     {pageRows.map((currentRows, index) => (
      <Page size={'A4'} style={styles.page}>    
        <View style={{ height: "100%" }}>
          <View>
            <View style={{minHeight:"50px"}}>
              <Image src={_imgIfacLogoLong}></Image>
            </View>
            <View style={{ flexDirection: "row", justifyContent: "space-between", marginTop: "15px", marginBottom: "20px" }}>
              <View style={{ width: "25%" }}></View>
              <Text>Ref:   {invoice.ref}</Text>
              <Text>Date: {new Date(invoice.dateCreated).toLocaleDateString("en-GB")}</Text>
            </View>
            <View style={{minHeight:"30px", backgroundColor: "#00ABC7", padding: "5px", paddingBottom: "2px", flexDirection: "row", justifyContent: "center", border: "1px" }}>
              <Text style={{ color: "white", fontSize: "20", fontWeight: "bold" }}>{titleText}</Text>
            </View>

            <View style={{ width: "100%", flexDirection: "row", justifyContent: "flex-end", marginTop: "5px", minHeight:"12px" }}>
              <Text style={{minHeight:"12px"}}>Number: {invoice.invoiceNumber}</Text>
            </View>
            <View style={{marginLeft: "40px", minHeight:"84px"}}>
              <Text style={{minHeight:"12px"}}>{invoice.clientTitle} {invoice.clientFirstName} {invoice.clientSurname}</Text>
              {HasValue(invoice.clientAddress1) &&
                <Text style={{minHeight:"12px"}}>{invoice.clientAddress1}</Text>}
              {HasValue(invoice.clientAddress2) &&
                <Text style={{minHeight:"12px"}}>{invoice.clientAddress2}</Text>}
              {HasValue(invoice.clientAddress3) &&
                <Text style={{minHeight:"12px"}}>{invoice.clientAddress3}</Text>}
              {HasValue(invoice.clientAddress4) &&
                <Text style={{minHeight:"12px"}}>{invoice.clientAddress4}</Text>}
              {HasValue(invoice.clientAddress5) &&
                <Text style={{minHeight:"12px"}}>{invoice.clientAddress5}</Text>}
                <View style={{minHeight:variableSpace, height:variableSpace}}></View>
            </View>
            
          </View>
          <View style={{ flexDirection: "column", justifyContent: "flex-end" }}>
            <View style={{minHeight:"25px", backgroundColor: "#00ABC7", padding: "5px", justifyContent: "start", marginBottom: "10px", border: "1px" }}>
              <Text style={{ color: "white", fontWeight: "bold" }}>{serviceText}</Text>
            </View>
            <View style={{ height: "80%", width: "100%", flexDirection: "row", border: "1px" }}>
              {
                !invoice.isBadDebtCN &&
                <View style={{ width: "85%", flexDirection: "column", justifyContent: "space-between", padding: "5px", paddingBottom:"0px", borderRight: "1px" }}>
                  <Text style={{marginTop:"10px"}}>{currentRows.join("\n")}</Text>
                  {
                    draft &&
                    <View style={styles.watermarkDraft}>
                      <Text>DRAFT</Text>
                    </View> 
                  }
                  { !draft && HasValue(invoice.dateCancelled) &&
                  <View style={styles.watermarkCancelled}>
                    <Text>CANCELLED {new Date(invoice.dateCancelled).toLocaleDateString("en-GB")}</Text>
                  </View> 
                  }

                 
                  { index==(pageRows.length-1) && (
                  invoice.isMultipleVatRate ? (
                    <View>
                       { invoice.outlayDescription&&
                        <View style={{alignItems:"left", flexDirection: "column"}}>
                            <View style={{alignItems:"left", flexDirection: "row"}}>
                              <Text style={{textDecoration: 'underline'}}>Outlay</Text> 
                            </View> 
                            <View style={{alignItems:"left", flexDirection: "row"}}>
                              <Text style={{}}>{invoice.outlayDescription}</Text> 
                            </View>                                                     
                          </View>                                                    
                       }

                    <View style={{ flexDirection: "row", justifyContent: "space-between"}}>
                      <View style={{width:"50%", height:"100%", flexDirection: "column", justifyContent:"space-evenly", alignItems: "flex-start", marginLeft:"-5px" }}>
                        <View style={{width:"100%", flexDirection: "row", justifyContent: "center", borderTop:"1px", borderRight:"1px"}}>
                          <Text style={{fontWeight:"bold", marginTop:"5px"}}>VAT Summary</Text>
                        </View>
                        <View style={{width:"100%", flexDirection: "row"}}>
                          <View style={{width:"25%", flexDirection: "column", justifyContent:"space-evenly", alignItems:"center"}}>
                            <View style={{flexDirection: "row", justifyContent: "center", width:"100%", borderTop:"1px", borderRight:"1px"}}>
                            <Text style={{fontWeight:"bold", marginTop:"5px"}}>Rate</Text>
                            </View>
                            
                            <View style={{flexDirection: "row", justifyContent: "center", width:"100%", borderTop:"1px", borderRight:"1px"}}>
                              {    (returnFeeText === "Share Capital")?
                                  <Text style={{marginTop:"5px"}}> Exempt </Text>
                                  :(invoice.outlayAmountString?
                                    <Text style={{marginTop:"5px"}}> N/A</Text>:
                                  <Text style={{marginTop:"5px"}}> 0%</Text>
                                  )
                            }
                           
                            </View>
                            <View style={{flexDirection: "row", justifyContent: "center", width:"100%", borderTop:"1px", borderRight:"1px"}}>
                            <Text style={{marginTop:"5px"}}>{invoice.vat}%</Text>
                            </View>
                            <View style={{flexDirection: "row", justifyContent: "center", width:"100%", borderTop:"1px", borderRight:"1px"}}>
                            <Text style={{marginTop:"5px", borderBottom: "1px", textDecoration: "underline"}}>Total</Text>
                            </View>
                          </View>
                          <View style={{width:"25%", flexDirection: "column", justifyContent:"space-evenly", alignItems:"center"}}>
                            <View style={{flexDirection: "row", justifyContent: "center", width:"100%", borderTop:"1px", borderRight:"1px"}}>
                            <Text style={{fontWeight:"bold", marginTop:"5px"}}>Net</Text>
                            </View>
                           
                            <View style={{flexDirection: "row", justifyContent: "center", width:"100%", borderTop:"1px", borderRight:"1px"}}>
                            <Text style={{marginTop:"5px"}}>{invoice.outlayAmountString??invoice.annualReturnFeeString}</Text>
                            </View>
                            <View style={{flexDirection: "row", justifyContent: "center", width:"100%", borderTop:"1px", borderRight:"1px"}}>
                            <Text style={{marginTop:"5px", textDecoration: "underline"}}>{invoice.loFeeString}</Text>
                            </View>
                            <View style={{flexDirection: "row", justifyContent: "center", width:"100%", borderTop:"1px", borderRight:"1px"}}>
                            <Text style={{marginTop:"5px", borderBottom: "1px", textDecoration: "underline"}}>{invoice.netString}</Text>
                            </View>
                          </View>
                          <View style={{width:"25%", flexDirection: "column", justifyContent:"space-evenly", alignItems:"center"}}>
                            <View style={{flexDirection: "row", justifyContent: "center", width:"100%", borderTop:"1px", borderRight:"1px"}}>
                            <Text style={{fontWeight:"bold", marginTop:"5px"}}>VAT</Text>
                            </View>                          
                            <View style={{flexDirection: "row", justifyContent: "center", width:"100%", borderTop:"1px", borderRight:"1px"}}>
                            <Text style={{marginTop:"5px"}}>0.00</Text>
                            </View>
                            <View style={{flexDirection: "row", justifyContent: "center", width:"100%", borderTop:"1px", borderRight:"1px"}}>
                            <Text style={{marginTop:"5px", textDecoration: "underline"}}>{invoice.vatAmountString}</Text>
                            </View>
                            <View style={{flexDirection: "row", justifyContent: "center", width:"100%", borderTop:"1px", borderRight:"1px"}}>
                            <Text style={{marginTop:"5px", borderBottom: "1px", textDecoration: "underline"}}>{invoice.vatAmountString}</Text>
                            </View>
                          </View>
                          <View style={{width:"25%", flexDirection: "column", justifyContent:"space-evenly", alignItems:"center"}}>
                            <View style={{flexDirection: "row", justifyContent: "center", width:"100%", borderTop:"1px", borderRight:"1px"}}>
                            <Text style={{fontWeight:"bold", marginTop:"5px"}}>Gross</Text>
                            </View>                         
                            <View style={{flexDirection: "row", justifyContent: "center", width:"100%", borderTop:"1px", borderRight:"1px"}}>
                            <Text style={{marginTop:"5px"}}>{invoice.outlayAmountString??invoice.annualReturnFeeString}</Text>
                            </View>
                            <View style={{flexDirection: "row", justifyContent: "center", width:"100%", borderTop:"1px", borderRight:"1px"}}>
                            <Text style={{marginTop:"5px", textDecoration: "underline"}}>{invoice.loFeeGrossString}</Text>
                            </View>
                            <View style={{flexDirection: "row", justifyContent: "center", width:"100%", borderTop:"1px", borderRight:"1px"}}>
                            <Text style={{marginTop:"5px", borderBottom: "1px", textDecoration: "underline"}}>{invoice.amountDueString}</Text>
                            </View>
                          </View>
                        </View>
                      </View>
                      <View style={{ flexDirection: "column", justifyContent: "flex-end", alignItems: "flex-end", paddingBottom:"5px" }}>
                        {invoice.outlayDescription&& invoice.outlayAmountString&& <Text style={{ marginTop: "5px" }}>Outlay</Text>}
                        {returnFeeText&& <Text style={{ marginTop: "5px" }}>{returnFeeText}</Text>}
                        <Text style={{ marginTop: "5px" }}>Net</Text>
                        <Text style={{ marginTop: "5px" }}>Sub-Total</Text>
                        <Text style={{ marginTop: "5px" }}>VAT</Text>
                        <Text style={{ marginTop: "5px", fontWeight: "bold" }}>Balance Due</Text>
                      </View> 
                    </View>
                    </View>)
                    :

                    <View style={{ flexDirection: "column", justifyContent: "flex-end", alignItems: "flex-end", paddingBottom:"5px"  }}>
                      <Text style={{ marginTop: "5px" }}>Net</Text>
                      <Text style={{ marginTop: "5px" }}>Vat ({invoice.vat}%)</Text>
                      <Text style={{ marginTop: "5px", fontWeight: "bold" }}>Balance Due</Text>
                    </View>

                  )              
}
                </View>
              }
              {
                 index==(pageRows.length-1) && (invoice.isBadDebtCN &&
                <View style={{ width: "85%", flexDirection:"column", justifyContent:"space-between", padding: "5px", paddingBottom:"0px", borderRight: "1px" }}>
                    <View style={{ flexDirection:"row", justifyContent:"flex-start", height:"50%"}}>
                        <View style={{ flexDirection: "column", justifyContent: "space-around", marginRight:"40px" }}>
                                <View style={{ flexDirection: "column", justifyContent: "flex-start" }}>
                                  <Text style={{fontWeight: "bold"}}>Total Written Off:</Text>
                                </View> 
                                <Text style={{fontWeight: "bold"}}>In respect of:</Text>
                        </View>                
                        <View style={{ flexDirection: "column", justifyContent: "space-around" }}>
                                <View style={{ flexDirection: "column", justifyContent: "flex-start" }}>
                                  <Text>{`€${invoice.amountDueString}`}</Text>
                                </View> 
                                <Text>Invoice {invoice.reference}</Text>
                        </View> 
                    </View>   

                    <View style={{ flexDirection: "column", justifyContent: "flex-end", alignItems: "flex-end", paddingBottom:"5px"  }}>
                      <Text style={{ marginTop: "5px" }}>Net</Text>
                      <Text style={{ marginTop: "5px" }}>Vat ({invoice.vat}%)</Text>
                      <Text style={{ marginTop: "5px", fontWeight: "bold" }}>Balance Due</Text>
                    </View>
                </View>  
                 ) 
              }
              <View style={{ width: "15%", flexDirection: "column", justifyContent: "space-between", padding: "5px" }}>
                <View style={{ flexDirection: "row", justifyContent: "center" }}>
                  <Text>€</Text>
                </View>
                { index==(pageRows.length-1) && (
                  invoice.isMultipleVatRate ? 

                  <View style={{ width: "100%", flexDirection: "column", justifyContent: "flex-end", alignItems: "flex-end" }}>
                    {invoice.outlayDescription&& invoice.outlayAmountString&&<Text style={{ marginTop: "5px" }}>{invoice.outlayAmountString}</Text>}
                    {returnFeeText&& <Text style={{ marginTop: "5px" }}>{invoice.annualReturnFeeString}</Text>}
                    <Text style={{ textDecoration: "underline", marginTop: "5px" }}>{invoice.loFeeString}</Text>
                    <Text style={{ marginTop: "5px" }}>{invoice.netString}</Text>
                    <Text style={{ textDecoration: "underline", marginTop: "5px" }}>{invoice.vatAmountString}</Text>
                    <Text style={{ borderBottom: "1px", textDecoration: "underline", marginTop: "5px", fontWeight: "bold" }}>{invoice.amountDueString}</Text>
                  </View>
                  
                  :

                  <View style={{ width: "100%", flexDirection: "column", justifyContent: "flex-end", alignItems: "flex-end" }}>
                    <Text style={{ marginTop: "5px" }}>{invoice.netString}</Text>
                    <Text style={{ textDecoration: "underline", marginTop: "5px" }}>{invoice.vatAmountString}</Text>
                    <Text style={{ borderBottom: "1px", textDecoration: "underline", marginTop: "5px", fontWeight: "bold" }}>{invoice.amountDueString}</Text>
                  </View>
                )
                }
                
              </View>
            </View>

            <View>
              <View style={{ flexDirection: "row", justifyContent: "space-between", marginTop: "3px", fontWeight: "bold" }}>
                <Text>E. &amp; O.E.</Text>   
                {
                  !invoice.isCreditNote &&
                  <Text style={{fontSize:"14px"}}>Please remit to your Local Office by {new Date(invoice.dateDue).toLocaleDateString("en-GB")}</Text>
                }
                <View style={{width:"40px"}}/>
              </View>
              <View style={{ flexDirection: "row", justifyContent: "center"}}>
              {
                  !invoice.isCreditNote &&
                <Text>Payable by Direct Debit, Cheque, Draft, Card, Bank Transfer or on <Link src="https://www.ifac.ie" target="_blank" style={styles.link}>www.ifac.ie</Link> using the <Link src="https://www.ifac.ie/pay-invoice/" style={styles.link}>“Pay Invoice”</Link> link.</Text>
              }
              </View>
              <View style={{ flexDirection: "row", marginTop: "2px", marginBottom:"7px"}}>
                <View style={{ flexDirection: "row"}}>
                  <Text style={{fontWeight:"bold"}}>IBAN: </Text>
                  <Text>{invoice.iban}</Text>
                </View>
                <View style={{ flexDirection: "row", marginLeft:"10px"}}> 
                  <Text style={{fontWeight:"bold"}}>BIC: </Text>
                  <Text>{invoice.bic}</Text>
                </View>
              </View>
              <View style={{ flexDirection: "column", marginTop: "4px", marginBottom:"1px"}}>             
                  <Text > {officeAddress}</Text>                 
             
                  <Text style={{fontSize:"9px"}} >{secondOfficeAddress} </Text>
              </View> 
                <Text style={{ fontSize:"10px"}}>Ifac - Registered in Ireland No. 3528R. Registered office: Danville Business Park, Ring Road, Kilkenny, R95 N156. VAT No. IE2803507F</Text>  
               <Text style={{marginBottom:"5px",textAlign:"right", fontSize:"7px"}}>pag {(index+1).toString()}/{pageCount.toString()}</Text> 
            </View>
          </View>
        </View>
      </Page>
))}
    </Document>
        );
}

export default PDFInvoice;