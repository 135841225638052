import { useEffect, useState } from "react";
import { Spin, Table, Input, Tabs, Button, Modal } from "antd";
import { useMsal } from "@azure/msal-react";
import { GetAccessToken } from "../Helper/JWTToken";
import { GetStandardMonthlyFees, UpdateStandardMonthlyFees } from "../Data Layer/Data";
import SaveButton from "../Components/SaveButton";
import CancelButton from "../Components/CancelButton";
import { HasValue } from "../Helper/JSHelper";
import { Menu, Dropdown } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import { Authorize, CheckAuthorization } from "../Helper/Authorization";

const { TabPane } = Tabs;
const { confirm } = Modal;

function StandardMonthlyFeesPage() {
    const msal = useMsal();
    const [isLoading, setIsLoading] = useState(false);
    const [errorText, setErrorText] = useState("");
    const [selectedYear, setSelectedYear] = useState((new Date()).getFullYear());
    const [years, setYears] = useState(null);
    const [apiData, setApiData] = useState(null);
    const [bookkepingRows, setBookkepingRows] = useState(null);
    const [bookkepingHandlers, setBookkepingHandlers] = useState(null);
    const [farmProRows, setFarmProRows] = useState(null);
    const [farmProHandlers, setFarmProHandlers] = useState(null);
    const [payrollRows, setPayrollRows] = useState(null);
    const [payrollHandlers, setPayrollHandlers] = useState(null);
    const [unpaidDD, setUnpaidDD] = useState(null);
    const [unpaidDDData, setUnpaidDDData] = useState(null);
    const [editAuthorized, setEditAuthorized] = useState(false);
    const [emptyFieldMessage, setEmptyFieldMessage] = useState(null);
    const [isDirty, setIsDirty] = useState(false);
    

    useEffect(async () => {

        setIsLoading(true);
        const token = await GetAccessToken(msal);
        const data = await GetStandardMonthlyFees(token);
        const auth = await CheckAuthorization(["Standard Monthly Fees - Edit"], msal);

        setApiData(data);
        setYears(data.years);
        setEditAuthorized(auth);
        setEmptyFieldMessage(data.emptyFieldMessage);

        await CreateBookkepingTableRows(data.subServices, auth);
        await CreateFarmProTableRows(data.subServices, auth);
        await CreatePayrollTableRows(data.subServices, auth);
        await CreateUnpaidDDRow(data.subServices, auth);

        setIsLoading(false);
    }, []);

    useEffect(async () => {
        if (apiData != null) {
            await CreateBookkepingTableRows(apiData.subServices, editAuthorized);
            await CreateFarmProTableRows(apiData.subServices, editAuthorized);
            await CreatePayrollTableRows(apiData.subServices, editAuthorized);
            await CreateUnpaidDDRow(apiData.subServices, editAuthorized);
        }
    }, [selectedYear, apiData]);

    const CreateUnpaidDDRow = async (data) => {
        data.forEach(fee => {
            if (fee.serviceID === 29 && fee.year === selectedYear) {
                setUnpaidDD(fee.price?.toLocaleString(undefined, { minimumFractionDigits: 2 }));
                setUnpaidDDData({serviceFeeMatrixID: fee.serviceFeeMatrixID, subServiceID: fee.subServiceID});
            }
        });
    }

    const CreateBookkepingTableRows = async (data, editable) => {
        let bookeppingList = [];
        let bookeppingHandlerList = [];

        data.forEach(fee => {
            if (fee.serviceID === 22 && fee.year === selectedYear) {
                if (fee.name.split('-')[1] == null)
                    debugger;
                const rowName = fee.name.split('-')[0].trim();
                const colName = fee.name.split('-')[1].trim();

                let rowIndex = GetRowIndex(bookeppingList, rowName);

                if (rowIndex == -1) //Create new row item if the name hasn't been used yet
                {
                    bookeppingList.push({
                        rowName: rowName,
                        col1Val: null,
                        col1Editable: editable,
                        col1MatrixID: null,
                        col1SubServiceID: null,
                        col2Val: null,
                        col2Editable: editable,
                        col2MatrixID: null,
                        col2SubServiceID: null,
                        col3Val: null,
                        col3Editable: false,
                        col4Val: null,
                        col4Editable: false, //only some of these are editable, determined by the presence of the value in the data
                        col4MatrixID: null,
                        col4SubServiceID: null,
                        rowIndex: null
                    });

                    bookeppingHandlerList.push({
                        col1: null,
                        col2: null,
                        col4: null
                    });

                    rowIndex = bookeppingList.length - 1;
                    bookeppingList[rowIndex].rowIndex = rowIndex;
                }

                switch (colName) {
                    case "POAC": {
                        bookeppingList[rowIndex].col1Val = (HasValue(fee.price)) ? Number(fee.price).toLocaleString(undefined, { minimumFractionDigits: 2 }) : null;
                        bookeppingList[rowIndex].col1MatrixID = fee.serviceFeeMatrixID;
                        bookeppingList[rowIndex].col1SubServiceID = fee.subServiceID;
                        break;
                    }
                    case "PrePayment": {
                        bookeppingList[rowIndex].col2Val = (HasValue(fee.price)) ? Number(fee.price).toLocaleString(undefined, { minimumFractionDigits: 2 }) : null;
                        bookeppingList[rowIndex].col2MatrixID = fee.serviceFeeMatrixID;
                        bookeppingList[rowIndex].col2SubServiceID = fee.subServiceID;
                        break;
                    }
                    case "ShareCapital": {

                        bookeppingList[rowIndex].col4Val = (HasValue(fee.price)) ? Number(fee.price).toLocaleString(undefined, { minimumFractionDigits: 2 }) : null;
                        bookeppingList[rowIndex].col4MatrixID = fee.serviceFeeMatrixID;
                        bookeppingList[rowIndex].col4SubServiceID = fee.subServiceID;
                        bookeppingList[rowIndex].col4Editable = editable;
                        break;
                    }
                }

                if (HasValue(bookeppingList[rowIndex].col1Val) || HasValue(bookeppingList[rowIndex].col2Val)) { //calculate totals coumn if 1 and 2 arepresent
                    bookeppingList[rowIndex].col3Val = Math.round((Number(bookeppingList[rowIndex].col1Val) + Number(bookeppingList[rowIndex].col2Val)) * 100) / 100;
                    bookeppingList[rowIndex].col3Val = bookeppingList[rowIndex].col3Val?.toLocaleString(undefined, { minimumFractionDigits: 2 })
                }
                else
                bookeppingList[rowIndex].col3Val = "0.00";
            }
        });

        setBookkepingRows(bookeppingList);

        for (let i = 0; i < bookeppingHandlerList.length; i++) {
            bookeppingHandlerList[i].col1 = (e) => {
                e.preventDefault();

                if (isNaN(e.target.value) || e.target.value < 0)
                    return;

                let rows = bookeppingList.slice();
                rows[i].col1Val = e.target.value;
                rows[i].col3Val = Math.round((Number(rows[i].col1Val) + Number(rows[i].col2Val)) * 100) / 100;
                setBookkepingRows(rows);
                setIsDirty(true);
            }

            bookeppingHandlerList[i].col2 = (e) => {
                e.preventDefault();

                if (isNaN(e.target.value) || e.target.value < 0)
                    return;

                let rows = bookeppingList.slice();
                rows[i].col2Val = e.target.value;
                rows[i].col3Val = Math.round((Number(rows[i].col1Val) + Number(rows[i].col2Val)) * 100) / 100;
                setBookkepingRows(rows);
                setIsDirty(true);
            }

            bookeppingHandlerList[i].col4 = (e) => {
                e.preventDefault();

                if (isNaN(e.target.value) || e.target.value < 0)
                    return;

                let rows = bookeppingList.slice();
                rows[i].col4Val = e.target.value;
                setBookkepingRows(rows);
                setIsDirty(true);
            }
        }

        setBookkepingHandlers(bookeppingHandlerList);
    }

    const CreateFarmProTableRows = async (data, editable) => {
        let farmProList = [];
        let farmProHandlerList = [];

        data.forEach(fee => {
            if (fee.serviceID === 28 && fee.year === selectedYear) {

                const words = fee.name.trim().split(' ');
                let colName;
                let rowName;

                if (fee.subServiceID == 34 || fee.subServiceID == 35)
                {
                    rowName = words.join(" ").trim();
                    colName = "Gold"
                }
                else
                {
                    colName = words[words.length - 1].trim();
                    words.pop();
                    rowName = words.join(" ").trim();
                }

                let rowIndex = GetRowIndex(farmProList, rowName);

                if (rowIndex == -1) //Create new row item if the name hasn't been used yet
                {
                    farmProList.push({
                        rowName: rowName,
                        col1Val: null,
                        col1Editable: false,
                        col1MatrixID: null,
                        col1SubServiceID: null,
                        col2Val: null,
                        col2Editable: false,
                        col2MatrixID: null,
                        col2SubServiceID: null,
                        col3Val: null,
                        col3Editable: editable,
                        col3MatrixID: null,
                        col3SubServiceID: null,
                        rowIndex: null
                    });

                    farmProHandlerList.push({
                        col1: null,
                        col2: null,
                        col3: null
                    });

                    rowIndex = farmProList.length - 1;
                    farmProList[rowIndex].rowIndex = rowIndex;
                }

                switch (colName) {
                    case "Bronze": {
                        farmProList[rowIndex].col1Val = (HasValue(fee.price)) ? Number(fee.price).toLocaleString(undefined, { minimumFractionDigits: 2 }) : null;
                        farmProList[rowIndex].col1MatrixID = fee.serviceFeeMatrixID;                     
                        farmProList[rowIndex].col1SubServiceID = fee.subServiceID;
                        farmProList[rowIndex].col1Editable = editable;
                        break;
                    }
                    case "Silver": {
                        farmProList[rowIndex].col2Val = (HasValue(fee.price)) ? Number(fee.price).toLocaleString(undefined, { minimumFractionDigits: 2 }) : null;
                        farmProList[rowIndex].col2MatrixID = fee.serviceFeeMatrixID;
                        farmProList[rowIndex].col2SubServiceID = fee.subServiceID;
                        farmProList[rowIndex].col2Editable = editable;
                        break;
                    }
                    case "Gold": {

                        farmProList[rowIndex].col3Val = (HasValue(fee.price)) ? Number(fee.price).toLocaleString(undefined, { minimumFractionDigits: 2 }) : null;
                        farmProList[rowIndex].col3MatrixID = fee.serviceFeeMatrixID;
                        farmProList[rowIndex].col3SubServiceID = fee.subServiceID;
                        break;
                    }
                }
            }
        });

        setFarmProRows(farmProList);

        for (let i = 0; i < farmProHandlerList.length; i++) {
            farmProHandlerList[i].col1 = (e) => {
                e.preventDefault();

                if (isNaN(e.target.value) || e.target.value < 0)
                    return;

                let rows = farmProList.slice();
                rows[i].col1Val = e.target.value;
                setFarmProRows(rows);
                setIsDirty(true);
            }

            farmProHandlerList[i].col2 = (e) => {
                e.preventDefault();

                if (isNaN(e.target.value) || e.target.value < 0)
                    return;

                let rows = farmProList.slice();
                rows[i].col2Val = e.target.value;
                setFarmProRows(rows);
                setIsDirty(true);
            }

            farmProHandlerList[i].col3 = (e) => {
                e.preventDefault();

                if (isNaN(e.target.value) || e.target.value < 0)
                    return;

                let rows = farmProList.slice();
                rows[i].col3Val = e.target.value;
                setFarmProRows(rows);
                setIsDirty(true);
            }
        }

        setFarmProHandlers(farmProHandlerList);
    }

    const CreatePayrollTableRows = async (data, editable) => {
        let payrollList = [];
        let payrollHandlerList = [];

        data.forEach(fee => {
            if (fee.serviceID === 5 && fee.year === selectedYear) {

                const words = fee.name.trim().split(' ');

                const colName = words[words.length - 1].trim();
                words.pop();
                const rowName = words.join(" ").trim();

                let rowIndex = GetRowIndex(payrollList, rowName);

                if (rowIndex == -1) //Create new row item if the name hasn't been used yet
                {
                    payrollList.push({
                        rowName: rowName,
                        col1Val: null,
                        col1Editable: false,
                        col1MatrixID: null,
                        col1SubServiceID: null,
                        col2Val: null,
                        col2Editable: editable,
                        col2MatrixID: null,
                        col2SubServiceID: null,
                        col3Val: null,
                        col3Editable: editable,
                        col3SubServiceID: null,
                        rowIndex: null
                    });

                    payrollHandlerList.push({
                        col1: null,
                        col2: null,
                        col3: null
                    });

                    rowIndex = payrollList.length - 1;
                    payrollList[rowIndex].rowIndex = rowIndex;
                }

                switch (colName) {
                    case "Bronze": {
                        payrollList[rowIndex].col1Val = (HasValue(fee.price)) ? Number(fee.price).toLocaleString(undefined, { minimumFractionDigits: 2 }) : null;
                        payrollList[rowIndex].col1MatrixID = fee.serviceFeeMatrixID;
                        payrollList[rowIndex].col1SubServiceID = fee.subServiceID;
                        payrollList[rowIndex].col1Editable = editable;
                        break;
                    }
                    case "Silver": {
                        payrollList[rowIndex].col2Val = (HasValue(fee.price)) ? Number(fee.price).toLocaleString(undefined, { minimumFractionDigits: 2 }) : null;
                        payrollList[rowIndex].col2MatrixID = fee.serviceFeeMatrixID;
                        payrollList[rowIndex].col2SubServiceID = fee.subServiceID;
                        break;
                    }
                    case "Gold": {

                        payrollList[rowIndex].col3Val = (HasValue(fee.price)) ? Number(fee.price).toLocaleString(undefined, { minimumFractionDigits: 2 }) : null;
                        payrollList[rowIndex].col3MatrixID = fee.serviceFeeMatrixID;
                        payrollList[rowIndex].col3SubServiceID = fee.subServiceID;
                        break;
                    }
                }
            }
        });

        setPayrollRows(payrollList);

        for (let i = 0; i < payrollHandlerList.length; i++) {
            payrollHandlerList[i].col1 = (e) => {
                e.preventDefault();

                if (isNaN(e.target.value) || e.target.value < 0)
                    return;

                let rows = payrollList.slice();
                rows[i].col1Val = e.target.value;
                setPayrollRows(rows);
                setIsDirty(true);
            }

            payrollHandlerList[i].col2 = (e) => {
                e.preventDefault();

                if (isNaN(e.target.value) || e.target.value < 0)
                    return;

                let rows = payrollList.slice();
                rows[i].col2Val = e.target.value;
                setPayrollRows(rows);
                setIsDirty(true);
            }

            payrollHandlerList[i].col3 = (e) => {
                e.preventDefault();

                if (isNaN(e.target.value) || e.target.value < 0)
                    return;

                let rows = payrollList.slice();
                rows[i].col3Val = e.target.value;
                setPayrollRows(rows);
                setIsDirty(true);
            }
        }

        setPayrollHandlers(payrollHandlerList);
    }

    function GetRowIndex(list, name) {
        if (!HasValue(list) || list.length < 1)
            return -1;

        for (let i = 0; i < list.length; i++) {
            if (list[i].rowName === name)
                return i;
        }

        return -1;
    }

    const unpaidDDHandler = (e) => {
        e.preventDefault();
        setUnpaidDD(e.target.value);
        setIsDirty(true);
    }

    const yearChanged = async (year) => {

        if (year !== selectedYear) {
        setSelectedYear(year);
        setErrorText("");    
        }
    }

    const generateDropdown = () => {

        if (!HasValue(years))
        return null;

        else return (
            <Menu style={{maxHeight: '400px', overflowY:'auto'}}>
                {
                    years.map((year, i) =>
                        <Menu.Item key={i}>
                            <a onClick={async () => {await yearChanged(year)}}>
                                {year}
                            </a>
                        </Menu.Item>
                    )
                }
            </Menu>
        )
    }

    const cancelBookkeping = async () => {
        await CreateBookkepingTableRows(apiData.subServices, editAuthorized);
        setIsDirty(false);
    }

    const cancelFarmPro = async () => {
        await CreateFarmProTableRows(apiData.subServices, editAuthorized);
        setIsDirty(false);
    }

    const cancelPayroll = async () => {
        await CreatePayrollTableRows(apiData.subServices, editAuthorized);
        setIsDirty(false);
    }

    const cancelUnpaidDD = async () => {
        await CreateUnpaidDDRow(apiData.subServices, editAuthorized);
        setIsDirty(false);
    }

    const saveBookkeping = async () => {
        let data = {
            fees: []
        };

        let emptyFieldWarning = false;

        const rows = bookkepingRows;
        const year = selectedYear;

        rows.forEach(row => {
            let fee1 = {
                serviceFeeMatrixId: row.col1MatrixID,
                subServiceId: row.col1SubServiceID,
                year: year,
                fee: (HasValue(row.col1Val)) ? Number(row.col1Val) : 0         
            };
            data.fees.push(fee1);
            if (!HasValue(fee1.fee) || fee1.fee === 0)
                    emptyFieldWarning = true;

            let fee2 = {
                serviceFeeMatrixId: row.col2MatrixID,
                subServiceId: row.col2SubServiceID,
                year: year,
                fee: (HasValue(row.col2Val)) ? Number(row.col2Val) : 0 
            };
            data.fees.push(fee2); 
            if (!HasValue(fee2.fee) || fee2.fee === 0)
                    emptyFieldWarning = true;

            if (row.col4SubServiceID != null) {
                let fee3 = {
                    serviceFeeMatrixId: row.col4MatrixID,
                    subServiceId: row.col4SubServiceID,
                    year: year,
                    fee: (HasValue(row.col4Val)) ? Number(row.col4Val) : 0 
                };
                data.fees.push(fee3);
                if (!HasValue(fee3.fee) || fee3.fee === 0)
                    emptyFieldWarning = true;
            }       
        });

        await Save(data, emptyFieldWarning);
    }

    const saveFarmPro = async () => {
        let data = {
            fees: []
        };

        let emptyFieldWarning = false;

        const rows = farmProRows;
        const year = selectedYear;

        rows.forEach(row => {
            if (row.col1SubServiceID != null) {
                let fee1 = {
                    serviceFeeMatrixID: row.col1MatrixID,
                    subServiceID: row.col1SubServiceID,
                    year: year,
                    fee: (HasValue(row.col1Val)) ? Number(row.col1Val) : 0            
                };
                data.fees.push(fee1);
                if (!HasValue(fee1.fee) || fee1.fee === 0)
                    emptyFieldWarning = true;
            }

            if (row.col2SubServiceID != null) {
                let fee2 = {
                    serviceFeeMatrixID: row.col2MatrixID,
                    subServiceID: row.col2SubServiceID,
                    year: year,
                    fee: (HasValue(row.col2Val)) ? Number(row.col2Val) : 0
                };
                data.fees.push(fee2);
                if (!HasValue(fee2.fee) || fee2.fee === 0)
                    emptyFieldWarning = true;
            }

            let fee3 = {
                serviceFeeMatrixID: row.col3MatrixID,
                subServiceID: row.col3SubServiceID,
                year: year,
                fee: (HasValue(row.col3Val)) ? Number(row.col3Val) : 0
            };                 
            data.fees.push(fee3);
            if (!HasValue(fee3.fee) || fee3.fee === 0)
                    emptyFieldWarning = true;
        });

        await Save(data, emptyFieldWarning);
    }

    const savePayroll = async () => {
        let data = {
            fees: []
        };

        const rows = payrollRows;
        const year = selectedYear;

        let emptyFieldWarning = false;

        rows.forEach(row => {
            if (row.col1SubServiceID != null) {
                let fee1 = {
                    serviceFeeMatrixID: row.col1MatrixID,
                    subServiceID: row.col1SubServiceID,
                    year: year,
                    fee: (HasValue(row.col1Val)) ? Number(row.col1Val) : 0              
                };
                data.fees.push(fee1);

                if (!HasValue(fee1.fee) || fee1.fee === 0)
                    emptyFieldWarning = true;
            }

            let fee2 = {
                serviceFeeMatrixID: row.col2MatrixID,
                subServiceID: row.col2SubServiceID,
                year: year,
                fee: (HasValue(row.col2Val)) ? Number(row.col2Val) : 0
                
            };
            data.fees.push(fee2);
            if (!HasValue(fee2.fee) || fee2.fee === 0)
                    emptyFieldWarning = true;

            let fee3 = {
                serviceFeeMatrixID: row.col3MatrixID,
                subServiceID: row.col3SubServiceID,
                year: year,
                fee: (HasValue(row.col3Val)) ? Number(row.col3Val) : 0
            };
            data.fees.push(fee3);
            if (!HasValue(fee3.fee) || fee3.fee === 0)
                    emptyFieldWarning = true;
        });

        await Save(data, emptyFieldWarning);
    }

    const saveUnpaidDD = async () => {
        let data = {
            fees: []
        };

        const row = unpaidDDData;
        const year = selectedYear;

        let fee = {
            serviceFeeMatrixID: row.serviceFeeMatrixID,
            subServiceID: row.subServiceID,
            year: year,
            fee: (HasValue(unpaidDD)) ? Number(unpaidDD) : 0
        };

        data.fees.push(fee);

        await Save(data);
    }

    const Save = async (data, warning) => {
        if (warning && HasValue(emptyFieldMessage))
            await showConfirm(data);
        else{
            await UploadData(data);
            setIsDirty(false);
        }
    }

    const UploadData = async (data) => {
        setErrorText("");

        const token = await GetAccessToken(msal);
        const result = await UpdateStandardMonthlyFees(token, data);
        if (result == null) 
            setErrorText("Failed to update fees!");
        else {
            setApiData(result);
        }
    }

    const yearSelector = {
        right: <div style={{ display: "flex", width:"100px", flexDirection: "row", justifyContent: "center", position:"relative"}}>
                    <p>Year:</p>
                    <Dropdown overlay={generateDropdown()}>
                        <div style={{width: "70px"}}>
                            <p>{selectedYear} <DownOutlined /></p>
                        </div>
                    </Dropdown>
                </div>
      };
            
    const showConfirm = async (data) => {      
            confirm({
              content: 
                <div>
                    <p>{emptyFieldMessage}</p>
                    <div style={{marginTop:"20px", width:"100%", display: "flex", justifyContent: "flex-end"}}>
                        <SaveButton style={{marginRight:"10px"}} text="Yes" callback={async () =>{await UploadData(data); Modal.destroyAll();}} callbackProps={data}/>
                        <CancelButton text="No" callback={() => Modal.destroyAll()}/>
                    </div>
                </div>,
              okButtonProps: {style:{display:"none"}},
              cancelButtonProps: {style:{display:"none"}},
            });
    } 

    if (isLoading)
        return (
            <div style={{ width: "100%", height: "100%", minHeight: "inherit", display: "flex", alignItems: "center", justifyContent: "center" }}>
                <Spin size="large" />
            </div>
        )
    else return (
        <div className="div-payroll" style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
            <Tabs defaultActiveKey="1" tabBarExtraContent={yearSelector} onChange={() => setErrorText("")}>
                <TabPane tab="Bookkeping Recorder" key="1">
                    
                        <div className="div-ant-table">
                            <form>
                            <StandardFeeTab rows={bookkepingRows} columnNames={["POAC", "Prepayment", "Total", "Share Capital"]} handlers={bookkepingHandlers} />
                            </form>
                        <div style={{margin:"20px", width:"100%", display: "flex", justifyContent: "space-between"}}>
                            <p className="error-text">{errorText}</p>
                            <div style={{paddingRight:"20px"}}>
                                <Authorize permissions={["Standard Monthly Fees - Edit"]}>
                                    <SaveButton style={{marginRight:"10px"}} callback={saveBookkeping} disabled={!isDirty}/>
                                    <CancelButton callback={cancelBookkeping} disabled={!isDirty}/>
                                </Authorize>
                            </div>
                        </div>
                        </div>
                    
                </TabPane>
                <TabPane tab="FarmPro" key="2">
                        <div className="div-ant-table">
                        <form>
                            <StandardFeeTab rows={farmProRows} columnNames={["Bronze", "Silver", "Gold"]} handlers={farmProHandlers} />
                        </form>
                        <div style={{margin:"20px", width:"100%", display: "flex", justifyContent: "space-between"}}>
                            <p className="error-text">{errorText}</p>
                            <div style={{paddingRight:"20px"}}>
                            <Authorize permissions={["Standard Monthly Fees - Edit"]}>
                                <SaveButton style={{marginRight:"10px"}} callback={saveFarmPro} disabled={!isDirty}/>
                                <CancelButton callback={cancelFarmPro} disabled={!isDirty}/>
                            </Authorize>
                            </div>
                        </div>
                        </div>                    
                </TabPane>
                <TabPane tab="Payroll by Ifac" key="3">            
                    <div className="div-ant-table">      
                        <form>            
                            <StandardFeeTab rows={payrollRows} columnNames={["Bronze", "Silver", "Gold"]} handlers={payrollHandlers} />
                        </form>
                        <div style={{margin:"20px", width:"100%", display: "flex", justifyContent: "space-between"}}>
                            <p className="error-text">{errorText}</p>
                            <div style={{paddingRight:"20px"}}>
                            <Authorize permissions={["Standard Monthly Fees - Edit"]}>
                                <SaveButton style={{marginRight:"10px"}} callback={savePayroll} disabled={!isDirty}/>
                                <CancelButton callback={cancelPayroll} disabled={!isDirty}/>
                            </Authorize>
                            </div>
                        </div>
                    </div>
                
                </TabPane>
                <TabPane tab="Unpaid DD" key="4">
                    <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-start", alignItems: "center", margin: "20px" }}>
                        <p>Unpaid DD:</p>
                        {
                            editAuthorized ?
                            <Input style={{ width: "80px", margin: "0px 20px", textAlign: "center" }} value={unpaidDD} onChange={unpaidDDHandler}></Input>
                            :
                            <p style={{ width: "80px", margin: "0px 20px", textAlign: "center" }}>{unpaidDD}</p>
                        }
                        <Authorize permissions={["Standard Monthly Fees - Edit"]}>
                            <SaveButton style={{marginRight:"10px"}} callback={saveUnpaidDD} disabled={!isDirty}/>
                            <CancelButton callback={cancelUnpaidDD} disabled={!isDirty}/>
                        </Authorize>
                    </div>
                </TabPane>
                
            </Tabs>
            
        </div>
    );
}

function StandardFeeTab({ rows, columnNames, handlers }) {

    function GenerateColumns(columnNames) {
        let columns = [];

        let firstCol = {
            title: '',
            dataIndex: 'rowName',
            key: 'rowName',
        };

        columns.push(firstCol);

        for (let i = 0; i < columnNames.length; i++) {
            let col = {
                title: columnNames[i],
                dataIndex: `col${i + 1}Val`,
                key: `col${i + 1}Val`,
                render: (text, record) => {

                    return (
                        (record[`col${i + 1}Editable`]) ?
                            <Input value={record[`col${i + 1}Val`]} onChange={handlers[record.rowIndex][`col${i + 1}`]} />
                            :
                            <p>{record[`col${i + 1}Val`] ?? "N/A"}</p>
                    )
                }
            };

            columns.push(col);
        }

        return columns;
    }

    return (
        <Table dataSource={rows} columns={GenerateColumns(columnNames)} pagination={false} />
    )
}

export default StandardMonthlyFeesPage;