import { useEffect, useState, useContext } from "react";
import { Spin, Table, Button, Input, Pagination, DatePicker} from "antd";
import { useMsal } from "@azure/msal-react";
import { GetAccessToken } from "../Helper/JWTToken";
import { GetReceiptList  } from "../Data Layer/Data";
import { MainContext } from "../Components/Context";
import { HasValue } from "../Helper/JSHelper";
import ColumnSearchProps from "../Components/AntdExtended/ColumnSearchProps";
import { pdf } from '@react-pdf/renderer'
import moment from "moment";
import PDFReceipt from "../Components/PDFReceipt";

const { RangePicker } = DatePicker;

function ReceiptsListPage() {
    //Default date ranges for client and office selection
    const defRangeClient = null;
    const defRangeOffice = [moment().subtract(30, 'days'), moment()]

    const context = useContext(MainContext);
    const msal = useMsal();
    const [receipts, setReceipts] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [dateRange, setDateRange] = useState(defRangeClient);
    const [initialGenerate, setInitialGenerate] = useState(true);

    useEffect(async () => {
      if(HasValue(context.client.code)) {
        setDateRange(defRangeClient);
      }
      else if(HasValue(context.office.code) && context.office.code != -1) {
        setDateRange(defRangeOffice);
      }
      generateTable();
    }, []);
    useEffect(async () => {
        if (!initialGenerate)
        {
          if(HasValue(context.client.code)) {
            //if date range is already at default generate table
            if (dateRange == defRangeClient)
              generateTable();
            //else change date range, which will then generate table
            else
              setDateRange(defRangeClient);
          }
          else if (context.office.code != -1 )
          {
            if (dateRange == defRangeOffice)
              generateTable();
            //else change date range, which will then generate table
            else
              setDateRange(defRangeOffice);
          }
          else if (!HasValue(context.office.code) || context.office.code == -1 || !HasValue(context.client.code))
            setReceipts(null)
        }
    }, [context.client.code]);

    useEffect(async () => {
      if (!initialGenerate)
      {
        if(HasValue(context.office.code) && context.office.code != -1) {
          //if date range is already at default generate table
          if (dateRange == defRangeOffice)
          generateTable();
          //else change date range, which will then generate table
          else
            setDateRange(defRangeOffice);
        }
        else if (!HasValue(context.office.code) || context.office.code == -1 || !HasValue(context.client.code))
          setReceipts(null)
      }
    }, [context.office.code]);

    useEffect(async () => {
      if (!initialGenerate)
        {
          generateTable();
        }
    }, [dateRange]);

    const generateTable = async() => {
      if ((HasValue(context.office.code) && context.office.code != -1) || HasValue(context.client.code))
        {
            let startDate = null;
            let endDate = null;

            let code = context.office.code;
            if (code === -1 || HasValue(context.client.code))
              code = null;

            if (HasValue(dateRange)){
              startDate = dateRange[0]?.format();
              endDate = dateRange[1]?.format();
            }

            setIsLoading(true);
            const token = await GetAccessToken(msal);
            const data = await GetReceiptList(token, context.client.code, code, startDate, endDate);
 
            setReceipts(data.receipts);         
            setIsLoading(false);
        }
      else 
        setReceipts(null);

      setInitialGenerate(false);
    }
    
    const mapReceipts = (receipts) => {
      const receiptItems = receipts.map((item, i) => {
        return {
            key: i,
            number: item.receiptNo,
            clientString: `${item.clientCode} - ${item.clientName}`,             
            createdDate: new Date(item.receiptDate),
            createdDateString: item.receiptDateStr, 
            balanceDue: item.totalCredit,
            balanceDueString: `€${item.totalCredit?.toLocaleString(undefined, { minimumFractionDigits: 2 })}`
        }
      });

      return receiptItems;
    };

    
    const columns = [
        {
          title: 'Receipt Number',
          dataIndex: 'number',
          key: 'number',
          sorter: (a,b) => (a.number < b.number) ? -1 : 1,
          ...ColumnSearchProps("number", "Receipt Number"),
          render: (text, record) => {
            return (
              <>
              { 
                (record.isCancelled) ?
                <p className="p-cancelled">{text}</p> :
                <p>{text}</p> 
              }
              </>
            )
          }
                  
        },
        {
          title: 'Client',
          dataIndex: 'clientString',
          key: 'clientString',
          ...ColumnSearchProps("clientString", "Client")
        },
        {
          title: 'Date Created',
          dataIndex: 'createdDateString',
          key: 'createdDate',
          sorter: (a,b) => (a.createdDate < b.createdDate) ? -1 : 1,
          ...ColumnSearchProps("createdDateString", "Date Created"),         
          render: (text, record) => <p className="text-center">{text}</p>
        },
        {
          title: 'Amount',
          dataIndex: 'balanceDueString',
          key: 'balanceDue',
          sorter: (a,b) => (a.balanceDue < b.balanceDue) ? -1 : 1,
          render: (text, record) => <p className="text-right">{text}</p>
        },
        {
          title: '',
          dataIndex: '',
          key: '',
          render: (text, record) =>
            <div className="text-center"><Button type="primary" onClick={() => { showReceipt(record.number);}}>View</Button></div>
        },
      ];

    const showReceipt = async (number) => {
      const receipt = getSelectedReceipt(number);     
      const blob = await pdf(<PDFReceipt receipt={receipt}/>).toBlob();
      let url = URL.createObjectURL(blob)
      window.open(url, "_blank");
    }

    const dateChanged = (value) => {
        setDateRange(value);
    }

    function setTitle () {
        let text = "";

        if (HasValue(context.client.code)){
          text = `${context.client.code} ${context.client.firstName ?? ''} ${context.client.surname ?? ''}, from ${context.client.officeCode} - ${context.client.officeName ?? ''}`;
        }       
        else if (HasValue(context.office.code) && context.office.code != -1)
          text += `${context.office.code} - ${context.office.name}`;

        return (
          <div style={{display:"flex", flexDirection:"row", justifyContent:"space-between"}}>
            <h3>Receipts for: {text}</h3>
            <RangePicker value={dateRange} onChange={dateChanged} format="DD-MM-YYYY" allowEmpty={[true,true]} allowClear={true}></RangePicker>
          </div>
        );
    }

    const getSelectedReceipt = (number) => {
      if (HasValue(number)) {
        for (let i = 0; i < receipts.length; i++)
          if (receipts[i].receiptNo === number)
            return receipts[i];
      }

      return null;
    }
    
    if (isLoading)
      return (
        <div style={{width:"100%", height:"100%", minHeight:"inherit", display:"flex", alignItems:"center", justifyContent:"center"}}>
            <Spin size="large" />
        </div>
      )
    if (receipts === null)
        return (
            <p style={{padding:"20px"}}>Select a client or an office to view receipts.</p>
        )
    else
        return (
          
          <div className="div-ant-table" style={{maxHeight:"100%", width: "100%", overflow:"auto"}}>
            <Table title={setTitle} dataSource={mapReceipts(receipts)} columns={columns} pagination={{showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`}}/>
          </div>
        )
}

export default ReceiptsListPage;