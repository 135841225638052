import "../Style/LodgementsStyle.css"
import { useMsal } from '@azure/msal-react';
import { Spin, Button, Table, Input,Select } from 'antd';
import { useContext, useEffect, useRef, useState } from 'react';
import { Find, FindIndex, HasValue } from '../Helper/JSHelper';
import moment from 'moment';
import { GetJournalInfo, GetMICData, GetVatRate, PostCreditNote, PostJournalData, PostMICData } from '../Data Layer/Data';
import { GetAccessToken } from '../Helper/JWTToken';
import NumericInput from '../Components/NumericInput';
import { MainContext } from "../Components/Context";
import SaveButton from "../Components/SaveButton";
import AlertModal from "../Components/AlertModal";
import StandardSelect from "../Components/StandardSelect";
import ClientCodeSelect from "../Components/ClientCodeSelect";
import CancelButton from "../Components/CancelButton";
import TextArea from "antd/lib/input/TextArea";

const { Option } = Select;
const ledgerTypes=[{typeId:1,name:'Debtors'},{typeId:6,name:'Bank'},{typeId:11,name:'PSWT '}];
function CreateRow (index) {
    var newRow = {
        clientCode: null,
        clientName: null,
        ledgerTypeId:null,
        selectedNumber: null,
        date: null,
        currentBalance: null,
        debit: null,
        credit: null,
        index: index,
        transactions: []
    }

    return newRow;
}

function InitData() {
    var dataRows = [];

    dataRows.push(CreateRow(0));
    return dataRows;
}

function CreateJournalPage() {
    const msal = useMsal();
    const context = useContext(MainContext);

    const [isLoading, setIsLoading] = useState(false);
    const [dataRows, setDataRows] = useState(InitData());
    const [reason, setReason] = useState("");

    const codeSelected = async(index, code) => {
        var copy = dataRows.slice();
        copy[index].clientCode = code;

        const token = await GetAccessToken(msal);
        const data = await GetJournalInfo(token, code);

        copy[index].clientName = data.clientName;
        copy[index].transactions = data.transactions;
        copy[index].ledgerTypeId=1;
        copy[index].selectedNumber = null;
        copy[index].date = null;
        copy[index].currentBalance = null;
        copy[index].debit = null;
        copy[index].credit = null;

        setDataRows(copy);
    };



    const getNumbersList = (row) =>
    {
      var list = [];
      row.transactions.forEach(transaction => {
     //   if (!checkIfUsed(row.index, row.clientCode, transaction.number))
          list.push(transaction.number);
      });
      return list;
    }

    const checkIfUsed = (index, clientCode, number) => {
      var used = false;

      dataRows.forEach(row => {
        if (row.index != index && row.clientCode == clientCode && row.selectedNumber == number) {
          used = true;
          return;
        }
      });

      return used;
    }
    const onLedgerTypeChanged=(index,value)=>{
      var copy=dataRows.slice();
      copy[index].ledgerTypeId=value;
      copy[index].currentBalance=getCurrentBalanceValue(copy[index]);
      copy[index].newBalance= calculateCurrentBalance(copy[index]);
      setDataRows(copy);
    }

    const getCurrentBalanceValue=(row)=>{
      if  (row.ledgerTypeId!==1){
          return null;
      }else{
        var transaction = Find(row.transactions, "number", row.selectedNumber);
        if (transaction){
          var currentbalance= transaction.currentBalance;
          return currentbalance;
        }
        return null;
      }
  }

    const numberSelected = (index, value) =>
    {
      var copy = dataRows.slice();
      copy[index].selectedNumber = value;

      var transaction = Find(copy[index].transactions, "number", value)
      copy[index].date = new Date(transaction.dateCreated).toLocaleDateString("en-GB");
      copy[index].currentBalance =getCurrentBalanceValue(copy[index]);
      copy[index].debit = null;
      copy[index].credit = null;

      setDataRows(copy);
    }

    const onDebitChanged = (index, value) =>
    {
      if (isNaN(value))
            return;

        if (value < 0)
            return;

      var copy = dataRows.slice();
      copy[index].debit = value;
      if (HasValue(value))
        copy[index].credit = null;

      if (index === copy.length - 1)
        copy.push(CreateRow(index + 1));

      setDataRows(copy);
    }

    const onCreditChanged = (index, value) =>
    {
      if (isNaN(value))
            return;

        if (value < 0)
            return;

      var copy = dataRows.slice();
      copy[index].credit = value;
      if (HasValue(value))
        copy[index].debit = null;

      if (index === copy.length - 1)
        copy.push(CreateRow(index + 1));

      setDataRows(copy);
    }

    const calculateCurrentBalance = (row) =>
    {
      //e.	The Current Balance and New Balance fields will be populated only on rows where the Debtors ledger is selected.
      if (row.ledgerTypeId!==1)
        return null;
      var result = Number(row.currentBalance ?? 0);

      if (HasValue(row.debit) && !isNaN(row.debit))
          result += Number(row.debit);

      if (HasValue(row.credit) && !isNaN(row.credit))
          result -= Number(row.credit);

      return result.toLocaleString(undefined, { minimumFractionDigits: 2 });
    }

    const calculateDebitTotal = () => {
      var sum = 0;
      dataRows.forEach(row => {
        if (HasValue(row.debit))
          sum += Number(row.debit);
      });
      return Math.round(Number(sum) * 100) / 100;
    }

    const calculateCreditTotal = () => {
      var sum = 0;
      dataRows.forEach(row => {
        if (HasValue(row.credit))
          sum += Number(row.credit);
      });
      return Math.round(Number(sum) * 100) / 100;
    }

    const onEnter = (index, event) => {

    }

    const columns = [
        {
          title: 'Client Code',
          dataIndex: 'clientCode',
          key: 'clientCode', 
          render: (text, record) => <ClientCodeSelect clientCode={record.clientCode} index={record.index} onSelect={codeSelected} onEnter={(e) => onEnter(record.index, e)}/>,     
        },
        {
          title: 'Client Name',
          dataIndex: 'clientName',
          key: 'clientName'            
        },
        {
          title:'Ledger',
          dataIndex:'ledgerTypeId',
          key:'ledgerTypeId',
          render:(text,record)=>{
           return  <Select value={record.ledgerTypeId} style={{ minWidth: "164px" }} onChange={(a,b)=>onLedgerTypeChanged(record.index,b.value)}>
                    {ledgerTypes.map(type => (
                        <Option key={type.typeId} value={type.typeId}>{type.name}</Option>
                        ))}
           </Select>
           }
        },
        {
          title: 'Number',
          dataIndex: 'selectedNumber',
          key: 'selectedNumber',
          render: (text, record) =>{

                  if (HasValue(record.clientCode))
                    return <StandardSelect selectedItem={record.selectedNumber} items={getNumbersList(record)} onChange={(a,b) => numberSelected(record.index, b.children)} style={{width:"200px"}} focusOnAppear={true}/>
                  else
                    return null;
                }         
        },
        {
          title: 'Date',
          dataIndex: 'date',
          key: 'date'            
        },
        {
          title: 'Current Balance',
          dataIndex: 'currentBalance',
          key: 'currentBalance',
          render: (text, record) => {
            if (HasValue(record.currentBalance) && Number(record.currentBalance) < 0)
              return <p className="p-red">{record.currentBalance?.toLocaleString(undefined, { minimumFractionDigits: 2 })}</p>  
            else           
              return <p>{record.currentBalance?.toLocaleString(undefined, { minimumFractionDigits: 2 })}</p>    
          }
        },
        {
          title: 'Debit',
          dataIndex: 'debit',
          key: 'debit',
          render: (text, record) =>{

                  if (HasValue(record.selectedNumber))
                    return <NumericInput value={record.debit} setValue={(value) => onDebitChanged(record.index, value)} style={{width:"100px"}}/>
                  else
                    return null;
                }             
        },
        {
            title: 'Credit',
            dataIndex: 'credit',
            key: 'credit',
            render: (text, record) =>{
  
                    if (HasValue(record.selectedNumber))
                      return <NumericInput value={record.credit} setValue={(value) => onCreditChanged(record.index, value)} style={{width:"100px"}}/>
                    else
                      return null;
                  }             
        },
        {
          title: 'New Balance',
          dataIndex: 'newBalance',
          key: 'newBalance'  ,
          render: (text, record) => {
            if (!HasValue(record.selectedNumber))
              return null;
            var balance  = calculateCurrentBalance(record);
            if (balance < 0)
              return <p className="p-red">{balance}</p>  
            else           
              return <p>{balance}</p>    
          }        
        },
      ];

      function setTitle () {
        return (
            <h3>Create Journal</h3>
        );
    }

    const saveDisabled = () => {
      if (!HasValue(reason))
        return true;

      var hasData = false;

      dataRows.forEach(row => {
        if (HasValue(row.debit) && row.debit > 0)
          hasData = true;

        if (HasValue(row.credit) && row.credit > 0)
          hasData = true;

        if (HasValue(row.clientCode) && !HasValue(row.debit) && !HasValue(row.credit))
        {
          hasData = false;
          return;
        }
      });

      if (!hasData)
        return true;

      if (calculateDebitTotal() !== calculateCreditTotal())
        return true;

      return false;
    }

    const saveJournal = async () => {
        let data = {
          reason: reason,
          transactions: []
        };

        dataRows.forEach(row => {
          if (HasValue(row.clientCode))
          {
            var transaction = {
              clientCode: row.clientCode,
              reference: row.selectedNumber,
              debit: row.debit,
              credit: row.credit,
              ledgerTypeId:row.ledgerTypeId
            }

            data.transactions.push(transaction);
          }
        });

        const token = await GetAccessToken(msal);
        const result = await PostJournalData(token, data);

        if (result.status == 200) {
            AlertModal("Journal created.");
            setDataRows(InitData());
            setReason("");
        }
        else
            AlertModal("Failed to create journal.");
    }

    const cancelDisabled = () => {
      if (dataRows.length < 2)
        return true;

      else return false;
    }

    const cancelClicked = () => {
      setDataRows(InitData());
      setReason("");
    }

    const totalsRow = (pageData) => {
      
      return (
          <Table.Summary.Row>
            <Table.Summary.Cell className="text-align-center">Total</Table.Summary.Cell>
            <Table.Summary.Cell></Table.Summary.Cell>
            <Table.Summary.Cell></Table.Summary.Cell>
            <Table.Summary.Cell></Table.Summary.Cell>
            <Table.Summary.Cell></Table.Summary.Cell>
            <Table.Summary.Cell></Table.Summary.Cell>
            <Table.Summary.Cell>{calculateDebitTotal().toLocaleString(undefined, { minimumFractionDigits: 2 })}</Table.Summary.Cell>
            <Table.Summary.Cell>{calculateCreditTotal().toLocaleString(undefined, { minimumFractionDigits: 2 })}</Table.Summary.Cell>
            <Table.Summary.Cell></Table.Summary.Cell>
          </Table.Summary.Row>
      );
  };

    return (
      <>
        <div className="div-ant-table center-cells" style={{ maxHeight: "100%", width: "100%", overflow: "auto" }}>
          <form>
            <Table title={setTitle} dataSource={dataRows} columns={columns} pagination={{position: ['none','none']}} summary={totalsRow}/>
          </form>
        </div>
        <div style={{display: "flex", justifyContent: "space-between", alignItems:"flex-start", marginTop: "15px"}}>
        <div style={{display: "flex", justifyContent: "flex-start", alignItems:"flex-start"}}>
            <p style={{fontWeight:"bold", width:"max-content"}}>Reason:</p>
            <TextArea value={reason} maxLength={500} onChange={(val) => setReason(val.target.value)} rows={3} style={{width:"500px", marginLeft: "15px"}}/>
        </div>
        <div style={{ width: "100%", display: "flex", justifyContent: "flex-end" }}>
            <SaveButton text="Save" disabled={saveDisabled()} callback={saveJournal}/>
            <CancelButton style={{ marginLeft: "10px" }} text="Cancel" disabled={cancelDisabled()} callback={cancelClicked}/>
        </div>
        </div>
      </>
    )
}

export default CreateJournalPage;