import "../Style/MonthlyARStyle.css"
import { Button, DatePicker ,Modal} from "antd";
import { PlusCircleOutlined, CheckCircleOutlined, UploadOutlined, RedoOutlined,MailOutlined  } from '@ant-design/icons';
import StandardSelect from "../Components/StandardSelect";
import { useEffect, useState,useRef } from "react";
import { HasValue } from "../Helper/JSHelper";
import moment from "moment";
import locale from 'antd/lib/locale/en_GB';
import ConfirmModal from "../Components/ConfirmModal";
import AlertModal from "../Components/AlertModal";
import { AllocateReceipts, CreateMonthlyInvoices, GenerateDirectDebits, GetMonthlyAR, IgnoreFailedInvoices, PutGenerateDueDate, UpdateUnpaidDD,OffsetCredits, UploadFile } from "../Data Layer/Data";
import { useMsal } from "@azure/msal-react";
import { GetAccessToken } from "../Helper/JWTToken";
import WaitModal from "../Components/WaitModal";
import CancelButton from "../Components/CancelButton";
import { EventSourcePolyfill } from 'event-source-polyfill';

import ProgressBar from "../Components/ProgressBar";
const config = window['appSettings'];
const monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];

function DateInUTC (date){
    return new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()));
}


  
function defaultMonthDates (monthlyAR) {
    var date = new Date(monthlyAR.month);
    var month =  DateInUTC(date);
    let defGenerateDueDate = new Date(month.getFullYear(), month.getMonth(), 22);

    if (moment(defGenerateDueDate) < moment().subtract(2, "days") && month.getMonth() === (new Date()).getMonth()){
        if (moment().add(2, "days") > moment().endOf("month"))
            defGenerateDueDate = moment().endOf("month").startOf("day").toDate();
        else
            defGenerateDueDate = moment().add(2, "days").startOf("day").toDate();
    }
    
    return {
        month: month,
        generateDue: monthlyAR.ddDueDate ?? defGenerateDueDate,
        createCompleted: monthlyAR.createInvoicesCompleted,
        generateComleted: monthlyAR.generateDDCompleted,
        updateCompleted: monthlyAR.updateUnpaidCompleted,
        allocateCompleted: monthlyAR.allocateReceiptsCompleted,
        invoicesCreated: monthlyAR.invoicesCreated,
        invoicesFailed: monthlyAR.invoicesFailed,       
        ddProcessed: monthlyAR.ddProcessed,
        ddFailed: monthlyAR.ddFailed,
        unpaidProcessed: monthlyAR.unpaidProcessed,
        unpaidFailed: monthlyAR.unpaidFailed,
        receiptsProcessed: monthlyAR.receiptsProcessed,
        receiptsFailed: monthlyAR.receiptsFailed,
        offsetCreditsCompleted:monthlyAR.offsetCreditsCompleted,
	    creditsProcessed:monthlyAR.creditsProcessed,
	    creditsFailed:monthlyAR.creditsFailed,
        createFeeStatsCompleted:monthlyAR.createFeeStatsCompleted,
        feeStatsProcessed:monthlyAR.feeStatsProcessed,
        feeStatsFailed:monthlyAR.feeStatsFailed,
        emailFeeStatsCompleted:monthlyAR.emailFeeStatsCompleted ,
        emailsProcessed:monthlyAR.emailsProcessed,
        emailsFailed:monthlyAR.emailsFailed
    };
}



function MonthlyARPage () {
    const msal = useMsal();
    //Update button triggers Input file componenet
    const inputFileRef = useRef();//stores input file object

    const [selectedMonth, setSelectedMonth] = useState(0);
    const [displayMonths, setDisplayMonths] = useState([])
    const [monthDates, setMonthDates] = useState([]);
    const [progressText,setProgressText  ]=useState("Starting");
    const [percent,setPercent  ]=useState(0);
    const [isModalOpen, setIsModalOpen] = useState(false);


  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };
  
  
    useEffect(() => {
      async function fetchData() {
        const token = await GetAccessToken(msal);
        const data = await GetMonthlyAR(token);

        setData(data);
        setSelectedMonth(0);
        
      };
      fetchData();
    }, [msal]);

    const setData = (monthlyARs) => {
        let monthDatesArr = [];
        let displayMonthsArr = [];

        monthlyARs.forEach(monthlyAR => {
            var month = new Date(monthlyAR.month);
            monthDatesArr.push(defaultMonthDates(monthlyAR));

            displayMonthsArr.push(`${monthNames[month.getMonth()]} ${month.getFullYear()}`);
        });

        setMonthDates(monthDatesArr);
        setDisplayMonths(displayMonthsArr);
    }

    const onMonthChanged = (val) => {
        setSelectedMonth(val);
    } 

    const setDate = (prop, val = null) => {
        let copy = [...monthDates];
        if (val == null)
            val = new Date();

        copy[selectedMonth][prop] = val;

        setMonthDates(copy);
    }

    const createClicked = () => {
        const callback = async () => {
            const modal = WaitModal("Please wait for the process to finish. This may take a few minutes.");

            const token = await GetAccessToken(msal);
            await CreateMonthlyInvoices(token);

            const data = await GetMonthlyAR(token);
            setData(data);
            setSelectedMonth(1);

            modal.then((x) => x.destroy());
        }
        ConfirmModal ("You are about to create the monthly invoices for all relevant clients. Do you wish to continue? Yes/No.", callback)
    }

    const generateClicked = () => {
        const callback = async () => {
            const modal = WaitModal("Please wait for the process to finish. This may take a few minutes.");

            const token = await GetAccessToken(msal);
            const csvData = await GenerateDirectDebits(token);

            downloadCsvFile(csvData.csvTitle, csvData.csvContent);

            const data = await GetMonthlyAR(token);
            setData(data);

            modal.then((x) => x.destroy());
        }
        ConfirmModal ("You are about to generate the monthly direct debits for all relevant clients. Do you wish to continue? Yes/No.", callback)
    }

    function downloadCsvFile(title, content) {                    
        var hiddenElement = document.createElement('a');  
        hiddenElement.href = 'data:text/csv;charset=utf-8,' + encodeURI(content);  
        hiddenElement.target = '_blank';  
          
        //provide the name for the CSV file to be downloaded  
        hiddenElement.download = title;  
        hiddenElement.click();  
    } 
    const saveFile=(e)=>{
        //setFilesToUpload(Array.from(e.target.files));
        updateClicked(Array.from(e.target.files));
    }
    const onUpdateClick = () => {
        /*Collecting node-element and performing click*/
        inputFileRef.current.click();
      };

    const updateClicked  = async (filesToUpload) => {
      //check if any file is selected
      if (filesToUpload.length === 0) {
        AlertModal("No file selected!");
        return;
      }

      const callback = async () => {
        const modal = WaitModal(
          "Please wait for the process to finish. This may take a few minutes."
        );
        const token = await GetAccessToken(msal);
        var resultUpload = await UploadFile(token, filesToUpload);
        if (resultUpload === "") {
          // const token = await GetAccessToken(msal);
          const result = await UpdateUnpaidDD(token);
          if (result === "") {
            const data = await GetMonthlyAR(token);
            setData(data);
            modal.then((x) => x.destroy());
          } else {
            modal.then((x) => x.destroy());
            AlertModal(result);
          }
        } else {
          modal.then((x) => x.destroy());
          AlertModal(resultUpload);
        }
      };

      ConfirmModal(
        "You are about to update the monthly unpaid direct debits for all relevant clients. Do you wish to continue? Yes/No.",
        callback
      );
    }

    const allocateClicked = () => {
        const callback = async () => {
            const modal = WaitModal("Please wait for the process to finish. This may take a few minutes.");

            const token = await GetAccessToken(msal);
            const result = await AllocateReceipts(token);         

            if (result === '')
            {
                const data = await GetMonthlyAR(token);
                setData(data);
                modal.then((x) => x.destroy());
            }
            else {
                modal.then((x) => x.destroy());
                AlertModal(result);
            }            
        }

        ConfirmModal ("You are about to allocate the monthly direct debit receipts for all relevant clients. Do you wish to continue? Yes/No.", callback)
    }
    const offsetCreditsClicked=()=>{
        const callback=async()=>{
                const modal=WaitModal("Please wait for the offset credits to finish. This may take a few minutes.");
                const token = await GetAccessToken(msal);
                const result = await OffsetCredits(token);         

            if (result === '')
            {
                const data = await GetMonthlyAR(token);
                setData(data);
                modal.then((x) => x.destroy());
            }
            else {
                modal.then((x) => x.destroy());
                AlertModal(result);
            }  
        }


        ConfirmModal("You are about to offset credits for all relevant clients. Do you wish to continue? Yes/No.",callback)
    }

    const issueFeeStatementClicked=()=>{
        const callback=async()=>{
                //const modal=WaitModal("Please wait for the issue fee statments to finish. This may take a few minutes.");
                showModal();
                const token = await GetAccessToken(msal);
                var authorizationToken =sessionStorage.getItem("authorizationToken");
                var eventSource = new EventSourcePolyfill(
                    config.API_URL + "Fees/IssueFeeStatements",
                  {
                    headers: {
                      authorization: `Bearer ${token}`,
                      authorization2: `${authorizationToken}`, },
                    heartbeatTimeout: 10 * 60 * 1000,
                });
                eventSource.onopen = (e) => {
                  console.log("Connection Opened");
                  setProgressText("Started");                 
                };
                eventSource.onmessage =async (e) => {
                  e = JSON.parse(e.data);
                  if (e.eventId === "-1") {
                    // This is the end of the stream
                    try {
                        console.log(e.desc);
                        const data = await GetMonthlyAR(token);
                        setData(data);
                        setIsModalOpen(false);

                        eventSource.close();
                     // modal.then((x) => x.destroy());
                    } catch (e) {}
                  }else if (e.eventId==="990"){/// error from web api
                    try {
                        await feeStatementFailedAction(e.desc, eventSource, token);
                       
                      } catch (e) {}
                  } 
                  else {
                    // Process message that isn't the end of the stream...
                    //getRealtimeData(e.desc);
                    //update progress bar;
                    setProgressText(e.desc);
                    setPercent(e.count);
                    console.log(e.desc);
                  }
                };
                eventSource.onerror = (r) => {
                    console.log("onerror");
                    eventSource.close();
                    console.log(r);
          
                    setIsModalOpen(false);
                   
              };
              eventSource.onclose=(r)=>{
                console.log("Connection closed");
            }
           
        }


        ConfirmModal("You are about to create fee statements for all relevant clients. Do you wish to continue? Yes/No.",callback)
    }

    const sendEmailStatementClicked=()=>{
        const callback=async()=>{
                //const modal=WaitModal("Please wait for the issue fee statments to finish. This may take a few minutes.");
                showModal();
                const token = await GetAccessToken(msal);
                var authorizationToken =sessionStorage.getItem("authorizationToken");
                var eventSource = new EventSourcePolyfill(
                    config.API_URL + "Fees/SendEmails",
                  {
                    headers: {
                      authorization: `Bearer ${token}`,
                      authorization2: `${authorizationToken}`, },
                    heartbeatTimeout: 10 * 60 * 1000,});
                eventSource.onopen = (e) => {
                  console.log("Connection Opened");
                  setProgressText("Started");
                  
                };
                eventSource.onmessage =async (e) => {
                  e = JSON.parse(e.data);
                  if (e.eventId === "-1") {
                    // This is the end of the stream
                    try {
                        console.log(e.desc);
                        const data = await GetMonthlyAR(token);
                        setData(data);
                        setIsModalOpen(false);

                        eventSource.close();
                     // modal.then((x) => x.destroy());
                    } catch (e) {}
                  }else if (e.eventId==="990"){
                    try {
                        AlertModal(e.desc)
                        eventSource.close();
                        console.log(e.desc);
                        setProgressText(e.desc);
                        const data = await GetMonthlyAR(token);
                        setData(data);
                        setIsModalOpen(false);
                       
                      } catch (e) {}
                  } 
                  else {
                    // Process message that isn't the end of the stream...
                    setProgressText(e.desc);
                    setPercent(e.count);
                    console.log(e.desc);
                  }
                };
                eventSource.onerror = (r) => {
                    // error log here 
                    console.log(`error in send mail  ${r}`);
                    eventSource.close();
                    setIsModalOpen(false);
              };
              eventSource.onclose=(r)=>{
                console.log("Connection closed");
            }   
        }
        ConfirmModal("You are about to email the monthly fee statements for all relevant clients. Do you wish to continue? Yes/No.",callback)
    }

    const ignoreInvoicesClicked = () => {
        const callback = async () => {
            const token = await GetAccessToken(msal);
            await IgnoreFailedInvoices(token);

            const data = await GetMonthlyAR(token);
            setData(data);
        }
        ConfirmModal ("The Create Invoices process will be completed for the month without the failed invoices. Do you wish to continue? Yes/No.", callback)
    }

    const ignoreColumnVisible = () => {
        if ((monthDates.invoicesFailed ?? 0) > 0)
            return true;

        return false;
    }

    const generateDueChanged = async (val) => {
        
        const token = await GetAccessToken(msal);

        const data = {
                month: monthDates[selectedMonth]?.month,
                date: DateInUTC(val.toDate())
        }
        var result = await PutGenerateDueDate(token, data);

        setDate("generateDue", val);
    }

    const offsetDisabled = () => {
        //the Offset button is active for all months prior to this month, so for the live deployment can you either hide it for all months prior to Dec 2022 or make inactive. 
        //if date < Dec 2022 return true
        //get selected text displayed from dropdown
        if (displayMonths){
            var selectedMonthFromDD= displayMonths[selectedMonth];
            //split December 2022 to December and 2022
            if (selectedMonthFromDD){
                var [month,year]=selectedMonthFromDD.split(" ");
                    if (year<=2022&&month!=="December"){
                        return true;
                    }
                }
        }
      
        if ( HasValue(monthDates[selectedMonth]?.offsetCreditsCompleted) || !HasValue(monthDates[selectedMonth]?.allocateCompleted))
        {
            return true;
        }else{
            return false;
        }
    };
    const issueFeeDisabled = () => {
       
        if (displayMonths){
            var selectedMonthFromDD= displayMonths[selectedMonth];
            //disable befeore 2023 April"January", "February", "March",
            if (selectedMonthFromDD){
                var [month,year]=selectedMonthFromDD.split(" ");
                    if (year<=2023&&((month==="January")||(month==="February")||(month==="March"))){
                        return true;
                    }
                    //disable if //c.	Today’s date is on/before the last day of the Monthly AR month displayed.
                    let currentDate=DateInUTC(new Date());
                    var lastDayMonth= moment(monthDates[selectedMonth].month).endOf('month');
                    //console.log(`currentDate=${currentDate} lastDayMonth=${lastDayMonth}`);
                    if (currentDate<=lastDayMonth){
                       // console.log("true");
                            return true;
                    }
                }
           
        }
       // 2.	The Issue button will be inactive where:
//a.	The Offset Credits Completed field is blank i.e. must offset credits for that month before the fee statements can be issued.
//b.	The Issue Fee Statements Completed field is not blank i.e. can issue fee statements only once for that month.
//c.	Today’s date is on/before the last day of the Monthly AR month displayed.

      
        if ( HasValue(monthDates[selectedMonth]?.createFeeStatsCompleted) || !HasValue(monthDates[selectedMonth]?.offsetCreditsCompleted))
        {
            return true;
        }else{
            return false;
        }
    };

    const sendEmailDisabled = () => {
       
        if (displayMonths){
            var selectedMonthFromDD= displayMonths[selectedMonth];
            //disable befeore 2023 April"January", "February", "March",
            if (selectedMonthFromDD){
                var [month,year]=selectedMonthFromDD.split(" ");
                    if (year<=2023&&((month==="January")||(month==="February")||(month==="February"))){
                        return true;
                    }
                    //disable if //c.	Today’s date is on/before the last day of the Monthly AR month displayed.
                    let currentDate=DateInUTC(new Date());
                    var lastDayMonth= moment(monthDates[selectedMonth].month).endOf('month');
                    //console.log(`currentDate=${currentDate} lastDayMonth=${lastDayMonth}`);
                    if (currentDate<=lastDayMonth){
                       // console.log("true");
                            return true;
                    }
                }
           
        }
       // 2.	The Issue button will be inactive where:
//a.	The Offset Credits Completed field is blank i.e. must offset credits for that month before the fee statements can be issued.
//b.	The Issue Fee Statements Completed field is not blank i.e. can issue fee statements only once for that month.
//c.	Today’s date is on/before the last day of the Monthly AR month displayed.

      
        if ( HasValue(monthDates[selectedMonth]?.emailFeeStatsCompleted) || !HasValue(monthDates[selectedMonth]?.createFeeStatsCompleted))
        {
            return true;
        }else{
            return false;
        }
    };

    return (
        <div className="div-non-ant-table div-monthlyar" style={{maxWidth:"1200px"}}>
            <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-start", alignItems: "center", padding: "16px" }}>
                <h3 style={{ marginRight: "50px" }}>Month</h3>
                <StandardSelect items={displayMonths} selectedItem={displayMonths[selectedMonth]} onChange={onMonthChanged} />
            </div>
            <table style={{position:"relative", background:"white"}}>
                <thead>
                    <tr>
                        <th style={{width:"15%"}}></th>
                        <th></th>
                        <th style={{minWidth:"150px"}}>Due</th>
                        <th style={{width:"15%"}}>Completed</th>
                        <th style={{width:"15%"}}>Processed</th>
                        <th style={{width:"15%"}}>Failed</th>
                        {
                            ignoreColumnVisible() &&
                            <th style={{width:"5%"}}></th>
                        }   
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>Create Invoices</td>
                        <td style={{textAlign:"center"}}><Button className="btn-save" disabled={HasValue(monthDates[selectedMonth]?.createCompleted)} icon={<PlusCircleOutlined />} onClick={createClicked}>Create</Button></td>
                        <td></td>
                        <td style={{textAlign:"center"}}>{(HasValue(monthDates[selectedMonth]?.createCompleted)) ? new Date(monthDates[selectedMonth].createCompleted).toLocaleDateString("en-GB") : null}</td>
                        <td style={{textAlign:"right"}}>{(monthDates[selectedMonth]?.invoicesCreated)}</td>
                        <td style={{textAlign:"right"}}>{(monthDates[selectedMonth]?.invoicesFailed)}</td>
                        {
                            ignoreColumnVisible() &&
                            <td><CancelButton text="Ignore" callback={ignoreInvoicesClicked}/></td>  
                        }
                                             
                    </tr>
                    <tr>
                        <td>Generate Direct Debits</td>
                        <td style={{textAlign:"center"}}><Button className="btn-save" disabled={!HasValue(monthDates[selectedMonth]?.createCompleted) || HasValue(monthDates[selectedMonth]?.generateComleted)} icon={<RedoOutlined />} onClick={generateClicked}>Generate</Button></td>                      
                        <td style={{textAlign:"center"}}><DatePicker locale={locale} format={"DD-MM-YYYY"} value={HasValue(monthDates[selectedMonth]?.generateDue) ? moment(monthDates[selectedMonth].generateDue) : null} disabled={monthDates[selectedMonth]?.generateComleted} allowClear={false} onChange={(val) => generateDueChanged(val)} disabledDate={(date) => date < moment().startOf('day')}/></td>
                        <td style={{textAlign:"center"}}>{(HasValue(monthDates[selectedMonth]?.generateComleted)) ? new Date(monthDates[selectedMonth].generateComleted).toLocaleDateString("en-GB") : null}</td>
                        <td style={{textAlign:"right"}}>{(monthDates[selectedMonth]?.ddProcessed)}</td>
                        <td style={{textAlign:"right"}}>{(monthDates[selectedMonth]?.ddFailed)}</td>
                        {
                            ignoreColumnVisible() &&
                            <td></td>  
                        }
                    </tr>
                    <tr>
                        <td>Update Unpaid Direct Debits</td>
                        <td style={{textAlign:"center"}}>
                        <input type="file"  ref={inputFileRef} onChange={saveFile} multiple="multiple" accept=".xml" style={{display:"none"}}  />
                        <Button className="btn-save" disabled={HasValue(monthDates[selectedMonth]?.updateCompleted) || !HasValue(monthDates[selectedMonth]?.generateComleted)}
                              icon={<UploadOutlined />} onClick={onUpdateClick}>Update</Button></td>
                        <td></td>
                        <td style={{textAlign:"center"}}>{(HasValue(monthDates[selectedMonth]?.updateCompleted)) ? new Date(monthDates[selectedMonth].updateCompleted).toLocaleDateString("en-GB") : null}</td>
                        <td style={{textAlign:"right"}}>{(monthDates[selectedMonth]?.unpaidProcessed)}</td>
                        <td style={{textAlign:"right"}}>{(monthDates[selectedMonth]?.unpaidFailed)}</td>
                        {
                            ignoreColumnVisible() &&
                            <td></td>  
                        }
                    </tr>
                    <tr>
                        <td>Allocate Direct Debit Receipts</td>
                        <td style={{textAlign:"center"}}><Button className="btn-save" 
                        disabled={HasValue(monthDates[selectedMonth]?.allocateCompleted) || !HasValue(monthDates[selectedMonth]?.updateCompleted)} icon={<CheckCircleOutlined />} onClick={allocateClicked}>Allocate</Button></td>
                        <td></td>
                        <td style={{textAlign:"center"}}>{(HasValue(monthDates[selectedMonth]?.allocateCompleted)) ? new Date(monthDates[selectedMonth].allocateCompleted).toLocaleDateString("en-GB") : null}</td>
                        <td style={{textAlign:"right"}}>{(monthDates[selectedMonth]?.receiptsProcessed)}</td>
                        <td style={{textAlign:"right"}}>{(monthDates[selectedMonth]?.receiptsFailed)}</td>
                        {
                            ignoreColumnVisible() &&
                            <td></td>  
                        }
                    </tr>                                           
                    <tr>
                        <td>Offset Credits</td>
                        <td style={{textAlign:"center"}}><Button className="btn-save" disabled={offsetDisabled()} icon={<CheckCircleOutlined />} onClick={offsetCreditsClicked}>Offset</Button></td>
                        <td></td>
                        <td style={{textAlign:"center"}}>{(HasValue(monthDates[selectedMonth]?.offsetCreditsCompleted)) ? new Date(monthDates[selectedMonth].offsetCreditsCompleted).toLocaleDateString("en-GB") : null}</td>
                        <td style={{textAlign:"right"}}>{(monthDates[selectedMonth]?.creditsProcessed)}</td>
                        <td style={{textAlign:"right"}}>{(monthDates[selectedMonth]?.creditsFailed)}</td>
                        {
                            ignoreColumnVisible() &&
                            <td></td>  
                        }
                    </tr>
                    <tr>
                        <td>Create Fee Statements </td>
                        <td style={{textAlign:"center"}}><Button className="btn-save" disabled={issueFeeDisabled()} 
                        icon={<CheckCircleOutlined />} onClick={issueFeeStatementClicked}>Create</Button></td>
                        <td></td>
                        <td style={{textAlign:"center"}}>{(HasValue(monthDates[selectedMonth]?.createFeeStatsCompleted)) ? new Date(monthDates[selectedMonth].createFeeStatsCompleted).toLocaleDateString("en-GB") : null}</td>
                        <td style={{textAlign:"right"}}>{(monthDates[selectedMonth]?.feeStatsProcessed)}</td>
                        <td style={{textAlign:"right"}}>{(monthDates[selectedMonth]?.feeStatsFailed)}</td>
                        {
                            ignoreColumnVisible() &&
                            <td></td>  
                        }
                    </tr>

                    <tr>
                        <td>Email Fee Statements</td>
                        <td style={{textAlign:"center"}}><Button className="btn-save" disabled={sendEmailDisabled()} 
                        icon={<MailOutlined />} onClick={sendEmailStatementClicked}>Email</Button></td>
                        <td></td>
                        <td style={{textAlign:"center"}}>{(HasValue(monthDates[selectedMonth]?.emailFeeStatsCompleted)) ? new Date(monthDates[selectedMonth].emailFeeStatsCompleted).toLocaleDateString("en-GB") : null}</td>
                        <td style={{textAlign:"right"}}>{(monthDates[selectedMonth]?.emailsProcessed)}</td>
                        <td style={{textAlign:"right"}}>{(monthDates[selectedMonth]?.emailsFailed)}</td>
                        {
                            ignoreColumnVisible() &&
                            <td></td>  
                        }
                    </tr>
                </tbody>
            </table>

         <Modal centered={true} visible={isModalOpen} onOk={handleOk} >
           <p>Please wait for the fee statments to finish. This may take a few minutes.</p>    
                <ProgressBar  completed={percent} bgcolor="#6a1b9a" text={progressText}></ProgressBar>    
         </Modal>
     
        </div>
    )

    async function feeStatementFailedAction(errorDesc, eventSource, token) {
        AlertModal(errorDesc);
        eventSource.close();
        console.log(errorDesc);
        setProgressText(errorDesc);
        setIsModalOpen(false);
        const data = await GetMonthlyAR(token);
        setData(data);
    }
}

export default MonthlyARPage;