import { Select } from "antd";
import { HasValue } from "../Helper/JSHelper";
import { useContext, useEffect, useState } from "react";
import { useMsal } from "@azure/msal-react";
import { MainContext } from "../Components/Context";
import { GetAccessToken } from "../Helper/JWTToken";
import { GetOffices } from "../Data Layer/Data";

function OfficeCardSelect() {
  const msal = useMsal();

  const context = useContext(MainContext);
  let initialText = "Select office...";

  if (HasValue(context.office.code) && HasValue(context.office.name))
    initialText = `${context.office.code} - ${context.office.name}`;
  else if (HasValue(context.office.name)) initialText = context.office.name;


  const [officesData, setOfficesData] = useState([]);

  useEffect(async () => {
    const token = await GetAccessToken(msal);
    const data = await GetOffices(token);

    debugger;

    if (HasValue(data.offices)) {
      if (data.offices.length === 1) {
        const office = {code: data.offices[0].officeCode, name: data.offices[0].officeName, singleOffice: true,};
        context.setOffice(office);
      } else {
        debugger;
        setOfficesData(data.offices);
      }
    }
  }, []);
  
  useEffect(async () => {
        console.log(officesData);    
  }, [officesData]);

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "center",
        marginLeft: "40px",
        marginTop: "5px",
      }}
    >
      <h3 style={{ marginRight: "15px", position: "relative", top: "-2px" }}>
        Office:{" "}
      </h3>
      <Select mode='multiple' placeholder="Select office">
        {officesData.map((office, index) => {
          return (
            <Select.Option key={index} value={office.officeCode}>
              {office.officeCode + " - " + office.officeName}
            </Select.Option>
          );
        })}
      </Select>
    </div>
  );
}

export default OfficeCardSelect;
