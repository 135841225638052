import "../Style/LodgementsStyle.css"
import { useMsal } from '@azure/msal-react';
import { DatePicker, Spin, Table} from 'antd';
import { useContext, useEffect, useState } from 'react';
import { HasValue } from '../Helper/JSHelper';
import { GetAddRefundInfo, PostRefundData} from '../Data Layer/Data';
import { GetAccessToken } from '../Helper/JWTToken';
import NumericInput from '../Components/NumericInput';
import { MainContext } from "../Components/Context";
import moment from 'moment';
import locale from 'antd/lib/locale/en_GB';
import SaveButton from "../Components/SaveButton";
import AlertModal from "../Components/AlertModal";
import CancelButton from "../Components/CancelButton";
import TextArea from "antd/lib/input/TextArea";

function CreateRow (index, data) {
    var newRow = {
        number: data.transactionID,
        date: new Date(data.dateCreated).toLocaleDateString("en-GB"),
        currentBalance: data.currentBalance,
        amount: null,
        datePaid: null,
        index: index
    }

    return newRow;
}

function GetDataRows (transactions) {
    var data = [];
    var index = 0;

    if (HasValue(transactions))
    {
        transactions.forEach(transaction => {
            data.push(CreateRow(index, transaction));
            index ++;
        });
    }

    return data;
}

function AddRefundPage() {
    const msal = useMsal();
    const context = useContext(MainContext);

    const [isLoading, setIsLoading] = useState(false);
    const [dataRows, setDataRows] = useState(null);
    const [note, setNote] = useState("");
    const [clientData, setClientData] = useState(null);

    useEffect(async () => {
        if(HasValue(context.client.code)) {
            setIsLoading(true);
           
            const token = await GetAccessToken(msal);
            const data = await GetAddRefundInfo(token, context.client.code);

            setDataRows(GetDataRows(data.transactions)); 
            setClientData(data.clientData);    
            setIsLoading(false);
        }
        else 
        {
            setDataRows(null); 
            setClientData(null);
        }
    }, [context.client.code]);

    const onAmountChanged = (index, value) =>
    {
        if (value === ".")
          value = "0.";
          
        if (isNaN(value))
            return;

        if (value < 0)
            return;

        var copy = dataRows.slice();
        if (HasValue(value) && Number(value) > -copy[index].currentBalance)
            return;

        copy[index].amount = value;

        setDataRows(copy);
    }

    const dateChanged = (e, index) => {
        var copy = dataRows.slice();
        copy[index].datePaid = e?._d;

        setDataRows(copy);
    }

    const calculateCurrentBalance = (row) =>
    {
      var result = Number(row.currentBalance ?? 0); 

      if (HasValue(row.amount))
          result += Number(row.amount);

      return result.toLocaleString(undefined, { minimumFractionDigits: 2 });
    }

    const calculateAmountTotal = () => {
        var sum = 0;

        dataRows.forEach(row => {
            if (HasValue(row.amount))
                sum += Number(row.amount);
        });

        return sum;
      }

    const disabledDate = (current) => {
        
        return current > moment().endOf('day');
      };

    const columns = [
        {
          title: 'Number',
          dataIndex: 'number',
          key: 'number',
          render:(text,record)=><p > {record.number}</p>    
        },
        {
          title: 'Date',
          dataIndex: 'date',
          key: 'date' ,
          align: 'center'           
        },
        {
          title: 'Current Balance',
          dataIndex: 'currentBalance',
          key: 'currentBalance',
          render: (text, record) => <p className="p-red text-right">{record.currentBalance.toLocaleString(undefined, { minimumFractionDigits: 2 })}</p>       
        },
        {
          title: 'Amount',
          dataIndex: 'Amount',
          key: 'Amount',
          align: 'right',
          render: (text, record) =>{
                  return <NumericInput value={record.amount} setValue={(value) => onAmountChanged(record.index, value)} style={{width:"100px",textAlign: "right"}}/>
                }             
        },
        {
          title: 'New Balance',
          dataIndex: 'newBalance',
          key: 'newBalance'  ,
          render: (text, record) => {
            var balance  = calculateCurrentBalance(record);
            if (balance < 0)
                return <p  className="p-red text-right">{balance}</p>   
            else
                return <p className="text-right">{balance}</p>  
          }        
        },
        {
          title: 'Date Paid',
          dataIndex: 'datePaid',
          key: 'datePaid',
          align: 'center',
          render: (text, record) => <DatePicker locale={locale} format="DD-MM-YYYY" value={HasValue(record.datePaid) ? moment(record.datePaid) : null} onChange={(e) => dateChanged(e, record.index)} disabledDate={disabledDate} allowClear={true} />        
        }
      ];

      const saveDisabled = () => {
        if (!HasValue(note))
          return true;
  
        var hasData = false;
  
        dataRows.forEach(row => {
          if (HasValue(row.amount) && row.amount > 0 && HasValue(row.datePaid))
            hasData = true;
          else if (HasValue(row.amount) || HasValue(row.datePaid))
          {
            hasData = false;
            return;
          }
        });
  
        if (!hasData)
          return true;
  
        return false;
      }
    
      const saveWriteOff = async () => {
          let data = {
              note: note,
              clientCode: context.client.code,
              transactions: []
            };
    
            dataRows.forEach(row => {
              if (HasValue(row.amount))
              {
                var transaction = {
                  reference: row.number,
                  amount: row.amount,
                  datePaid: row.datePaid
                }
    
                data.transactions.push(transaction);
              }
            });
    
            const token = await GetAccessToken(msal);
            const result = await PostRefundData(token, data);
    
            if (result.status == 200) {
              AlertModal("Refund created.");
              setIsLoading(true);
             
              const token = await GetAccessToken(msal);
              const data = await GetAddRefundInfo(token, context.client.code);
  
              setDataRows(GetDataRows(data.transactions)); 
              setClientData(data.clientData);
              setNote("");        
              setIsLoading(false);
            }
            else
                AlertModal("Failed to create refund.");
      }
    
      const cancelDisabled = () => {
        if (HasValue(note))
          return false;
  
        var hasData = false;
  
        dataRows.forEach(row => {
          if (HasValue(row.amount) || HasValue(row.datePaid))
            hasData = true;
        });
  
        if (!hasData)
          return true;
  
        return false;
      }
    
      const cancelClicked = () => {
          var copy = dataRows.slice();
  
          copy.forEach(row => {
              row.amount = null;
              row.datePaid = null;
          });
          setNote("");
          setDataRows(copy);
      }

    function setTitle () {
        return (
            <h3>Transactions</h3>
        );
    }
  
    const totalsRow = (pageData) => {
      
        return (
            <Table.Summary.Row>
              <Table.Summary.Cell className="text-align-center">Total</Table.Summary.Cell>
              <Table.Summary.Cell></Table.Summary.Cell>
              <Table.Summary.Cell></Table.Summary.Cell>
              <Table.Summary.Cell>{calculateAmountTotal().toLocaleString(undefined, { minimumFractionDigits: 2 })}</Table.Summary.Cell>
              <Table.Summary.Cell></Table.Summary.Cell>
              <Table.Summary.Cell></Table.Summary.Cell>
            </Table.Summary.Row>
        );
    };

    if (isLoading)
      return (
        <div style={{width:"100%", height:"100%", minHeight:"inherit", display:"flex", alignItems:"center", justifyContent:"center"}}>
            <Spin size="large" />
        </div>
      )
    if (dataRows === null)
        return (
            <p style={{padding:"20px"}}>Select a client to add a refund.</p>
        )
    else
        return (
            <>
            <div style={{display:"flex", flexDirection:"row", justifyContent:"flex-start", alignItems:"center", marginTop:"15px", marginBottom:"15px"}}>
                <div style={{display:"flex", flexDirection:"row", justifyContent:"space-between", maxWidth:"30%"}}>
                    <p style={{marginRight:"10px"}} className="text-bold">Client Code:</p>
                    <p>{clientData.code}</p>
                </div>
                <div style={{display:"flex", flexDirection:"row", justifyContent:"space-between", maxWidth:"30%", marginLeft:"30px"}}>
                    <p style={{marginRight:"10px"}} className="text-bold">Client Name:</p>
                    <p>{clientData.name}</p>
                </div>
                <div style={{display:"flex", flexDirection:"row", justifyContent:"space-between", maxWidth:"30%", marginLeft:"30px"}}>
                    <p style={{marginRight:"10px"}} className="text-bold">Client Address:</p>
                    <p>{clientData.address}</p>
                </div>
            </div>
            <form>
            <div className="div-ant-table " style={{ maxHeight: "100%", width: "100%", overflow: "auto" }}>         
                <Table title={setTitle} dataSource={dataRows} columns={columns} pagination={{position: ['none','none']}} summary={totalsRow}/>  
            </div>
            <div style={{display: "flex", justifyContent: "space-between", alignItems:"flex-start", marginTop: "15px"}}>
              <div style={{display: "flex", justifyContent: "flex-start", alignItems:"flex-start"}}>
                  <p style={{fontWeight:"bold", width:"max-content"}}>Note:</p>
                  <TextArea value={note} maxLength={500} onChange={(val) => setNote(val.target.value)} rows={3} style={{width:"500px", marginLeft: "15px"}}/>
              </div>
              <div style={{ width: "100%", display: "flex", justifyContent: "flex-end" }}>
                  <SaveButton text="Save" disabled={saveDisabled()} callback={saveWriteOff}/>
                  <CancelButton style={{ marginLeft: "10px" }} text="Cancel" disabled={cancelDisabled()} callback={cancelClicked}/>
              </div>
            </div>
            </form>
            </>
        )

}

export default AddRefundPage;