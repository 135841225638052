import "../Style/MonthlyDDStyle.css"
import { useEffect, useState, useContext } from "react";
import { Select, Spin } from "antd";
import { useMsal } from "@azure/msal-react";
import { GetAccessToken } from "../Helper/JWTToken";
import { MainContext } from "../Components/Context";
import { Find, HasValue } from "../Helper/JSHelper";
import { GetMonthlyDDData, PostMonthlyDD } from "../Data Layer/Data";
import { Authorize, CheckAuthorization } from "../Helper/Authorization";
import NumericInput from "../Components/NumericInput";
import StandardSelect from "../Components/StandardSelect";
import SaveButton from "../Components/SaveButton";
import CancelButton from "../Components/CancelButton";
import ConfirmModal from "../Components/ConfirmModal";


const { Option } = Select;

function defaultServiceData() {
    return {
        bookeepingLocal: {
            directDebitId: null,
            present: false,
            net: null,
            unpaidDD: 0,
            other: 0,
            vat: null,
            subServiceId: null
        },
        bookeepingRecording: {
            present: false,
        },
        poac: {
            directDebitId: null,
            present: false,
            net: null,
            unpaidDD: 0,
            other: 0,
            vat: null,
            subServiceId: null
        },
        prepaymentStandard: {
            directDebitId: null,
            present: false,
            net: null,
            unpaidDD: 0,
            other: 0,
            vat: null,
            subServiceId: null
        },
        vat: {
            directDebitId: null,
            present: false,
            net: null,
            unpaidDD: 0,
            other: 0,
            vat: null,
            subServiceId: null
        },
        shareCapital: {
            directDebitId: null,
            present: false,
            net: null,
            unpaidDD: 0,
            other: 0,
            vat: null,
            subServiceId: null
        },
        farmPro: {
            directDebitId: null,
            present: false,
            net: null,
            unpaidDD: 0,
            other: 0,
            vat: null,
            subServiceId: null
        },
        payroll: {
            directDebitId: null,
            present: false,
            net: null,
            unpaidDD: 0,
            other: 0,
            vat: null
        },
        unpaidDD: {
            directDebitId: null,
            present: false,
            net: null,
            unpaidDD: 0,
            other: 0,
            vat: null,
            subServiceId: null
        },
        prepaymentTopUp: {
            directDebitId: null,
            present: false,
            net: null,
            unpaidDD: 0,
            other: 0,
            vat: null,
            subServiceId: null
        }
    }
}

function MonthlyDDPage() {
    const context = useContext(MainContext);
    const msal = useMsal();

    const [isLoading, setIsLoading] = useState(false);
    const [ddData, setDDData] = useState(null);
    const [serviceData, setServiceData] = useState(defaultServiceData());
    const [editAuthorized, setEditAuthorized] = useState(false);
    const [monthEditable, setMonthEditable] = useState(false);
    const [monthList, setMonthList] = useState([]);
    const [displayMonths, setDisplayMonths] = useState([]);
    const [selectedMonth, setSelectedMonth] = useState(null);
    const [isDirty, setIsDirty] = useState(false);
    const [saveEnabled, setSaveEnabled] = useState(false);
    const [allInvoiceList, setAllInvoiceList] = useState([]);
    const [selectedInvoice, setSelectedInvoice] = useState(null);
    const [selectedInvoice1, setSelectedInvoice1] = useState(null);// for the second line of LO
    const [placeholderInvoice, setPlaceholderInvoice] = useState(null);
    const [placeholderInvoice1, setPlaceholderInvoice1] = useState(null);// for the second line of LO
    const [loOtherAmount, setLoOtherAmount] = useState(null);
    const [loOtherUnpaid, setLoOtherUnpaid] = useState(null);
    const [loOtherId, setLoOtherId] = useState(null);
    const [loOtherAmount1, setLoOtherAmount1] = useState(null);
    const [loOtherUnpaid1, setLoOtherUnpaid1] = useState(null);
    const [loOtherId1, setLoOtherId1] = useState(null);
    const [hasBankDetails, setHasBankDetails] = useState(false);
     // if client has DateHQAdded field is Null in the cms_ClientContacts table  is new client
    //Display an “*” before the Client Code on the top of each page 
    //Do not allow any field to be changed
    const [isNewClient,setIsNewClient]=useState(false); 

    useEffect(async () => {
        const auth = await CheckAuthorization(["Monthly Direct Debit - Edit"], msal);

        setEditAuthorized(auth);
    }, []);

    useEffect(async () => {
        if (HasValue(context.client.code)) {
            setIsLoading(true);
            const token = await GetAccessToken(msal);
            const data = await GetMonthlyDDData(token, context.client.code);

           // setInvoiceList(data.invoices);
           // setInvoiceList1(data.invoices);
            setAllInvoiceList(data.invoices);
            setMonthList(data.months);
            setDisplayMonths(data.displayMonth);
            setDDData(data.directDebits);
            setSelectedMonth(data.months.length - 1); 
            setHasBankDetails(data.hasBankDetails);          
            setIsLoading(false);
            if(HasValue(context.client.isNewClient)){
                if (context.client.isNewClient=='1'){
                    setIsNewClient(true);
                }else{
                    setIsNewClient(false);
                }
            }
        }
        else
            setDDData(null);
    }, [context.client.code]);

    useEffect(() => {
       
        if (HasValue(ddData) && ddData.length > 0 && HasValue(selectedMonth)) {
            setServiceData(getServicesData(ddData, selectedMonth));

            const invoice = Find(ddData, "invoiceMonth", monthList[selectedMonth]);

            setLoOtherAmount(invoice.loOtherAmount);
            setLoOtherUnpaid(invoice.loOtherUnpaid);
            setLoOtherId(invoice.loOtherId);
            setSelectedInvoice(invoice.selectedInvoice);
            setPlaceholderInvoice(invoice.selectedInvoice);

            setLoOtherAmount1(invoice.loOtherAmount1);
            setLoOtherUnpaid1(invoice.loOtherUnpaid1);
            setLoOtherId1(invoice.loOtherId1);
            setSelectedInvoice1(invoice.selectedInvoice1);
            setPlaceholderInvoice1(invoice.selectedInvoice1);
        }
        else
            setServiceData(defaultServiceData());

        setIsDirty(false);
    }, [ddData, selectedMonth])

    useEffect(() => {
        if (serviceData != null) {
            let enabled = true;

            if (HasValue(selectedInvoice) && selectedInvoice != -1 && !HasValue(loOtherAmount))
                enabled = false;
            else if ((!HasValue(selectedInvoice) || selectedInvoice == -1) && HasValue(loOtherAmount))
                enabled = false;

            setSaveEnabled(enabled);
        }
    }, [serviceData])

    useEffect(() => {

        if (HasValue(selectedInvoice) && selectedInvoice != -1 && !HasValue(loOtherAmount))
            setSaveEnabled(false);
        else if ((!HasValue(selectedInvoice) || selectedInvoice == -1) && HasValue(loOtherAmount))
            setSaveEnabled(false);
        else
            setSaveEnabled(true);
    }, [selectedInvoice, loOtherAmount])
  
    function getServicesData(invoiceData, selectedMonth) {

        let data = defaultServiceData();

        const invoice = Find(invoiceData, "invoiceMonth", monthList[selectedMonth]);
        setMonthEditable(invoice.isEditable);
        let hasBR = false;

        invoice.services.forEach(service => {
            let addBR = false;

            switch (service.name) {
                //Bookeeping local
                case 0: {
                    data.bookeepingLocal.present = true;
                    data.bookeepingLocal.directDebitId = service.directDebitId;
                    data.bookeepingLocal.net = service.net;
                    data.bookeepingLocal.vat = service.vat;
                    data.bookeepingLocal.unpaidDD = service.unpaid;
                    data.bookeepingLocal.other = service.other ?? 0;
                    data.bookeepingLocal.subServiceId = service.subServiceId;
                    break;
                }
                //POAC
                case 2: {
                    data.poac.present = true;
                    data.poac.directDebitId = service.directDebitId;
                    data.poac.net = service.net;
                    data.poac.vat = service.vat;
                    data.poac.unpaidDD = service.unpaid;
                    data.poac.other = service.other ?? 0;
                    data.poac.subServiceId = service.subServiceId;
                    if (!hasBR)
                        addBR = true;
                    break;
                }
                //PrepaymentStandard
                case 3: {
                    data.prepaymentStandard.present = true;
                    data.prepaymentStandard.directDebitId = service.directDebitId;
                    data.prepaymentStandard.net = service.net;
                    data.prepaymentStandard.vat = service.vat;
                    data.prepaymentStandard.unpaidDD = service.unpaid;
                    data.prepaymentStandard.other = service.other ?? 0;
                    data.prepaymentStandard.subServiceId = service.subServiceId;
                    if (!hasBR)
                        addBR = true;
                    break;
                }
                //VAT
                case 4: {
                    data.vat.present = true;
                    data.vat.directDebitId = service.directDebitId;
                    data.vat.net = service.net;
                    data.vat.vat = service.vat;
                    data.vat.unpaidDD = service.unpaid;
                    data.vat.other = service.other ?? 0;
                    data.vat.subServiceId = service.subServiceId;
                    break;
                }
                //ShareCapital
                case 5: {
                    data.shareCapital.present = true;
                    data.shareCapital.directDebitId = service.directDebitId;
                    data.shareCapital.net = service.net;
                    data.shareCapital.vat = 0;
                    data.shareCapital.unpaidDD = service.unpaid;
                    data.shareCapital.other = service.other ?? 0;
                    data.shareCapital.subServiceId = service.subServiceId;
                    if (!hasBR)
                        addBR = true;
                    break;
                }
                //FarmPro
                case 6: {
                    data.farmPro.present = true;
                    data.farmPro.directDebitId = service.directDebitId;
                    data.farmPro.net = service.net;
                    data.farmPro.vat = service.vat;
                    data.farmPro.unpaidDD = service.unpaid;
                    data.farmPro.other = service.other ?? 0;
                    data.farmPro.level = service.level;
                    data.farmPro.subServiceId = service.subServiceId;
                    break;
                }
                //Payroll
                case 7: {
                    data.payroll.present = true;
                    data.payroll.directDebitId = service.directDebitId;
                    data.payroll.net = service.net;
                    data.payroll.vat = service.vat;
                    data.payroll.unpaidDD = service.unpaid;
                    data.payroll.other = service.other ?? 0;
                    data.payroll.level = service.level;
                    data.payroll.subServiceId = service.subServiceId;
                    break;
                }
                //UnpaidDD
                case 8: {
                    data.unpaidDD.present = true;
                    data.unpaidDD.directDebitId = service.directDebitId;
                    data.unpaidDD.net = service.net;
                    data.unpaidDD.other = service.other ?? 0;
                    data.unpaidDD.vat = service.vat;
                    data.unpaidDD.unpaidDD = service.unpaid;
                    data.unpaidDD.subServiceId = service.subServiceId;
                    break;
                }
                //PrepaymentTopUp
                case 9: {
                    data.prepaymentTopUp.present = true;
                    data.prepaymentTopUp.directDebitId = service.directDebitId;
                    data.prepaymentTopUp.net = service.net;
                    data.prepaymentTopUp.other = service.other ?? 0;
                    data.prepaymentTopUp.vat = service.vat;
                    data.prepaymentTopUp.unpaidDD = service.unpaid;
                    data.prepaymentTopUp.subServiceId = service.subServiceId;
                    break;
                }
            }
            if (addBR) {
                data.bookeepingRecording.present = true;
                data.bookeepingRecording.level = service.level;
                hasBR = true;
            }
        });

        return data;
    }

    const setData = (val, service, field) => {

        if ((!HasValue(val) && !HasValue(serviceData[service][field])) || val == serviceData[service][field])
            return;
            
        setIsDirty(true);

        var copy = { ...serviceData };

        if (isNaN(val)) {
            return;
        }

        copy[service][field] = val;
        setServiceData(copy);
    }

    const getGross = (service) => {

        if (!HasValue(service.net))
            return null;

        return Math.round(service.net * (1 + (service.vat ?? 0) / 100) * 100) / 100;
    }

    const getServiceTotal = (service) => {
        if (!HasValue(service))
            return 0;

        return Math.round((getGross(service) + service.unpaidDD + service.other) * 100) / 100;
    }

    const getBRCurrentTotal = () => {
        const sum = getGross(serviceData?.poac) + getGross(serviceData?.prepaymentStandard) + getGross(serviceData?.vat) + Number(serviceData?.shareCapital.net);
        return sum;
    }

    const getBRUnpaidTotal = () => {
        const sum = Number(serviceData?.poac.unpaidDD) + Number(serviceData?.prepaymentStandard.unpaidDD) + Number(serviceData?.vat.unpaidDD) + Number(serviceData?.shareCapital.unpaidDD);
        return sum;
    }

    const getBROtherTotal = () => {
        const sum = Number(serviceData?.poac.other) + Number(serviceData?.prepaymentStandard.other) + Number(serviceData?.vat.other) + Number(serviceData?.shareCapital.other);
        return sum;
    }

    const getBRTotal = () => {
        const sum = getServiceTotal(serviceData?.poac) + getServiceTotal(serviceData?.prepaymentStandard) + getServiceTotal(serviceData?.vat) + getServiceTotal(serviceData?.shareCapital);
        return sum;
    }

    const getCurrentTotal = () => {
        let sum =
            getGross(serviceData?.bookeepingLocal) +
            getGross(serviceData?.poac) +
            getGross(serviceData?.prepaymentStandard) +
            getGross(serviceData?.vat) +
            getGross(serviceData?.shareCapital) +
            getGross(serviceData?.farmPro) +
            getGross(serviceData?.payroll) +
            getGross(serviceData?.unpaidDD) +
            getGross(serviceData?.prepaymentTopUp);

        if (HasValue(loOtherAmount))
            sum += Number(loOtherAmount);
        //for the second line of LO
        if (HasValue(loOtherAmount1))
            sum += Number(loOtherAmount1);


        return sum;
    }

    const getUnpaidTotal = () => {
        let sum =
            Number(serviceData?.bookeepingLocal.unpaidDD) +
            Number(serviceData?.poac.unpaidDD) +
            Number(serviceData?.prepaymentStandard.unpaidDD) +
            Number(serviceData?.vat.unpaidDD) +
            Number(serviceData?.shareCapital.unpaidDD) +
            Number(serviceData?.farmPro.unpaidDD) +
            Number(serviceData?.payroll.unpaidDD) +
            Number(serviceData?.unpaidDD.unpaidDD) +
            Number(serviceData?.prepaymentTopUp.unpaidDD);

        if (HasValue(loOtherUnpaid))
            sum += Number(loOtherUnpaid);
        //for the second line of LO
        if (HasValue(loOtherUnpaid1))
            sum += Number(loOtherUnpaid1);

        return sum;
    }

    const getOtherTotal = () => {
        let sum =
            Number(serviceData?.bookeepingLocal.other) +
            Number(serviceData?.poac.other) +
            Number(serviceData?.prepaymentStandard.other) +
            Number(serviceData?.vat.other) +
            Number(serviceData?.shareCapital.other) +
            Number(serviceData?.farmPro.other) +
            Number(serviceData?.payroll.other) +
            Number(serviceData?.unpaidDD.other) +
            Number(serviceData?.prepaymentTopUp.other);
        return sum;
    }

    const getTotal = () => {
        let sum =
            getServiceTotal(serviceData?.bookeepingLocal) +
            getServiceTotal(serviceData?.poac) +
            getServiceTotal(serviceData?.prepaymentStandard) +
            getServiceTotal(serviceData?.vat) +
            getServiceTotal(serviceData?.shareCapital) +
            getServiceTotal(serviceData?.farmPro) +
            getServiceTotal(serviceData?.payroll) +
            getServiceTotal(serviceData?.unpaidDD) +
            getServiceTotal(serviceData?.prepaymentTopUp);

        if (HasValue(loOtherAmount))
            sum += Number(loOtherAmount);

        if (HasValue(loOtherUnpaid))
            sum += Number(loOtherUnpaid);

        if (HasValue(loOtherAmount1))
            sum += Number(loOtherAmount1);

        if (HasValue(loOtherUnpaid1))
            sum += Number(loOtherUnpaid1);

        return sum;
    }

    const onMonthChanged = (val) => {
        const callback = () => {
            setSelectedMonth(Number(val));
            setIsDirty(false);
        }
        if (isDirty)
            ConfirmModal("Al changes made will be lost. Do you wish to proceed?", callback);
        else
            callback();
    }

    const cancelChanges = () => {
        const callback = () => {
           
            setServiceData(getServicesData(ddData, selectedMonth));
            console.log(ddData);
            const invoice = Find(ddData, "invoiceMonth", monthList[selectedMonth]);

            setLoOtherAmount(invoice.loOtherAmount);
            setLoOtherId(invoice.loOtherId);
            setSelectedInvoice(invoice.selectedInvoice);
            setPlaceholderInvoice(invoice.selectedInvoice);

            setLoOtherAmount1(invoice.loOtherAmount1);
            setLoOtherId1(invoice.loOtherId1);
            setSelectedInvoice1(invoice.selectedInvoice1);
            setPlaceholderInvoice1(invoice.selectedInvoice1);


            setIsDirty(false);
        }
        if (isDirty)
            ConfirmModal("Al changes made will be lost. Do you wish to proceed?", callback);
        else
            callback();
    }

    const onInvoiceChanged = (val,item) => {
        if (val == '0')
        {
            setSelectedInvoice(null);
            setPlaceholderInvoice(null);
        }
        else
            setSelectedInvoice(item);

        setIsDirty(true);
    }

    const onInvoiceChanged1 = (val,item) => {
        if (val == '0')
        {
            setSelectedInvoice1(null);
            setPlaceholderInvoice1(null);
        }
        else
            setSelectedInvoice1(item);

        setIsDirty(true);
    }
//SM AICI
    const getInvoices=()=>{

    var items=allInvoiceList.filter((invoice=>invoice!==selectedInvoice1));
    return items;
    }
    const getInvoices1=()=>{

        var items=allInvoiceList.filter((invoice=>invoice!==selectedInvoice));
        return items;
        }

    const onLoOtherAmountChanged = (val) => {

        setIsDirty(true);

        if (isNaN(val)) {
            return;
        }

        setLoOtherAmount(val);
    }

    const onLoOtherAmountChanged1 = (val) => {

        setIsDirty(true);

        if (isNaN(val)) {
            return;
        }

        setLoOtherAmount1(val);
    }
    const saveInvocie = async () => {
        const month = monthList[selectedMonth];

        let data = {
            clientID: context.client.code,
            month: month,
            transactions: []
        }    

        if (serviceData.bookeepingLocal.present){
            var oldObj0=ddData[selectedMonth].services.find(x=> {return x.name===0})
            data.transactions.push({
                directDebitId: serviceData.bookeepingLocal.directDebitId,
                serviceName: 0,
                invoiceMonth: month,
                other: serviceData.bookeepingLocal.other,
                subServiceId: serviceData.bookeepingLocal.subServiceId,
                oldOther:oldObj0.other
            });
        }
        if (serviceData.poac.present){
            var oldObj2=ddData[selectedMonth].services.find(x=> {return x.name===2});
            data.transactions.push({
                directDebitId: serviceData.poac.directDebitId,
                serviceName: 2,
                invoiceMonth: month,
                other: serviceData.poac.other,
                subServiceId: serviceData.poac.subServiceId,
                oldOther:oldObj2.other
            });
        }
        if (serviceData.prepaymentStandard.present){
            var oldObj3=ddData[selectedMonth].services.find(x=> {return x.name===3});
            data.transactions.push({
                directDebitId: serviceData.prepaymentStandard.directDebitId,
                serviceName: 3,
                invoiceMonth: month,
                other: serviceData.prepaymentStandard.other,
                subServiceId: serviceData.prepaymentStandard.subServiceId,
                oldOther:oldObj3.other
            });
        }
        if (serviceData.vat.present){
            var oldObj4=ddData[selectedMonth].services.find(x=> {return x.name===4});
            data.transactions.push({
                directDebitId: serviceData.vat.directDebitId,
                serviceName: 4,
                invoiceMonth: month,
                other: serviceData.vat.other,
                subServiceId: serviceData.vat.subServiceId,
                oldOther:oldObj4.other
            });
        }
        if (serviceData.shareCapital.present){
            var oldObj5=ddData[selectedMonth].services.find(x=> {return x.name===5});
            data.transactions.push({
                directDebitId: serviceData.shareCapital.directDebitId,
                serviceName: 5,
                invoiceMonth: month,
                other: serviceData.shareCapital.other,
                subServiceId: serviceData.shareCapital.subServiceId,
                oldOther:oldObj5.other
            });
        }
        if (serviceData.farmPro.present){
            var oldObj6=ddData[selectedMonth].services.find(x=> {return x.name===6});
            data.transactions.push({
                directDebitId: serviceData.farmPro.directDebitId,
                serviceName: 6,
                invoiceMonth: month,
                other: serviceData.farmPro.other,
                subServiceId: serviceData.farmPro.subServiceId,
                oldOther:oldObj6.other
            });
        }
        if (serviceData.payroll.present){
            var oldObj7=ddData[selectedMonth].services.find(x=> {return x.name===7});
            data.transactions.push({
                directDebitId: serviceData.payroll.directDebitId,
                serviceName: 7,
                invoiceMonth: month,
                other: serviceData.payroll.other,
                subServiceId: serviceData.payroll.subServiceId,
                oldOther:oldObj7.other??0
            });
        }
        if (serviceData.unpaidDD.present){
            var oldObj8=ddData[selectedMonth].services.find(x=> {return x.name===8});
            data.transactions.push({
                directDebitId: serviceData.unpaidDD.directDebitId,
                serviceName: 8,
                invoiceMonth: month,
                other: serviceData.unpaidDD.other,
                subServiceId: serviceData.unpaidDD.subServiceId,
                oldOther:oldObj8.other
            });
        }
        if (serviceData.prepaymentTopUp.present){
            var oldObj9=ddData[selectedMonth].services.find(x=> {return x.name===9});
            data.transactions.push({
                directDebitId: serviceData.prepaymentTopUp.directDebitId,
                serviceName: 9,
                invoiceMonth: month,
                other: serviceData.prepaymentTopUp.other,
                subServiceId: serviceData.prepaymentTopUp.subServiceId,
                oldOther:oldObj9.other
            });
        }
        if (HasValue(selectedInvoice) && selectedInvoice != -1 && HasValue(loOtherAmount))
        {
            data.selectedInvoice = selectedInvoice;
            data.loOtherAmount = Number(loOtherAmount);
            data.loOtherId = loOtherId;
        }
        else if ((!HasValue(selectedInvoice) || selectedInvoice == -1) && !HasValue(loOtherAmount))
        {
            data.selectedInvoice = "";
            data.loOtherAmount = 0;
            data.loOtherId = loOtherId;
        }
//second line of LO
        if (HasValue(selectedInvoice1) && selectedInvoice1 != -1 && HasValue(loOtherAmount1))
        {
            data.selectedInvoice1= selectedInvoice1;
            data.loOtherAmount1 = Number(loOtherAmount1);
            data.loOtherId1 = loOtherId1;
        }
        else if ((!HasValue(selectedInvoice1) || selectedInvoice1 == -1) && !HasValue(loOtherAmount1))
        {
            data.selectedInvoice1 = "";
            data.loOtherAmount1 = 0;
            data.loOtherId1 = loOtherId1;
        }
        
        const token = await GetAccessToken(msal);
        await PostMonthlyDD(token, data);

        const newData = await GetMonthlyDDData(token, context.client.code);

        setMonthList(newData.months);
        setDisplayMonths(newData.displayMonth);
        setDDData(newData.directDebits);
        setIsDirty(false);
    }

    if (isLoading)
        return (
            <div style={{ width: "100%", height: "100%", minHeight: "inherit", display: "flex", alignItems: "center", justifyContent: "center" }}>
                <Spin size="large" />
            </div>
        )
    if (ddData === null)
        return (
            <p style={{ padding: "20px" }}>Select a client to view monthly invoices.</p>
        )
    else
        return (
            <div className="div-monthly-dd div-non-ant-table">
                <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-start", alignItems: "center", padding: "16px" }}>
                    <h3 style={{ marginRight: "50px" }}>Direct Debit Month</h3>
                    <StandardSelect items={displayMonths} selectedItem={displayMonths[selectedMonth]} onChange={onMonthChanged} invertList={true}/>
                </div>
                <form>
                <table style={{position:"relative", background:"white"}}>
                    <thead>
                        <tr>
                            <th rowSpan="2" >Service</th>
                            <th rowSpan="2">Current</th>
                            <th colSpan="2">Arrears </th>
                            <th rowSpan="2">Total</th>
                        </tr>
                        <tr>

                            <th style={{ textAlign: "right", borderLeft:"1px solid rgba(0, 0, 0, 0.3)" }}>Unpaid DDs </th>
                            <th style={{ textAlign: "right" }}>Other</th>

                        </tr>
                    </thead>
                    <tbody>
                        {
                            serviceData.bookeepingLocal.present &&
                            <tr>
                                <td>Bookeeping Local Office</td>
                                <td style={{ textAlign: "right" }}>{getGross(serviceData.bookeepingLocal)?.toLocaleString(undefined, { minimumFractionDigits: 2 })}</td>
                                <td style={{ textAlign: "right" }}>{serviceData.bookeepingLocal.unpaidDD?.toLocaleString(undefined, { minimumFractionDigits: 2 })}</td>
                                <td style={{ textAlign: "right" }}><NetInput value={serviceData.bookeepingLocal.other} isEditable={!isNewClient&& (editAuthorized && monthEditable)} onChange={(e) => setData(e, "bookeepingLocal", "other")} /></td>
                                <td style={{ textAlign: "right" }}>{getServiceTotal(serviceData.bookeepingLocal)?.toLocaleString(undefined, { minimumFractionDigits: 2 })}</td>
                            </tr>
                        }
                        {
                            serviceData.bookeepingRecording.present &&
                            <tr className="border-top">
                                <td style={{ textDecoration: "underline" }}>Bookeeping Recording</td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                            </tr>
                        }
                        {
                            serviceData.poac.present &&
                            <tr>
                                <td style={{ paddingLeft: "36px" }}>POAC</td>
                                <td style={{ textAlign: "right" }}>{getGross(serviceData.poac)?.toLocaleString(undefined, { minimumFractionDigits: 2 })}</td>
                                <td style={{ textAlign: "right" }}>{serviceData.poac.unpaidDD?.toLocaleString(undefined, { minimumFractionDigits: 2 })}</td>
                                <td style={{ textAlign: "right" }}><NetInput value={serviceData.poac.other} isEditable={!isNewClient&& (editAuthorized && monthEditable)} onChange={(e) => setData(e, "poac", "other")} /></td>
                                <td style={{ textAlign: "right" }}>{getServiceTotal(serviceData.poac)?.toLocaleString(undefined, { minimumFractionDigits: 2 })}</td>
                            </tr>
                        }
                        {
                            serviceData.prepaymentStandard.present &&
                            <tr>
                                <td style={{ paddingLeft: "36px" }}>Prepayment Standard</td>
                                <td style={{ textAlign: "right" }}>{getGross(serviceData.prepaymentStandard)?.toLocaleString(undefined, { minimumFractionDigits: 2 })}</td>
                                <td style={{ textAlign: "right" }}>{serviceData.prepaymentStandard.unpaidDD?.toLocaleString(undefined, { minimumFractionDigits: 2 })}</td>
                                <td style={{ textAlign: "right" }}><NetInput value={serviceData.prepaymentStandard.other} isEditable={!isNewClient&& (editAuthorized && monthEditable)} onChange={(e) => setData(e, "prepaymentStandard", "other")} /></td>
                                <td style={{ textAlign: "right" }}>{getServiceTotal(serviceData.prepaymentStandard)?.toLocaleString(undefined, { minimumFractionDigits: 2 })}</td>
                            </tr>
                        }
                        {
                            (serviceData.vat.present && serviceData.bookeepingRecording.present) &&
                            <tr>
                                <td style={{ paddingLeft: "36px" }}>VAT by IFAC</td>
                                <td style={{ textAlign: "right" }}>{getGross(serviceData.vat)?.toLocaleString(undefined, { minimumFractionDigits: 2 })}</td>
                                <td style={{ textAlign: "right" }}>{serviceData.vat.unpaidDD?.toLocaleString(undefined, { minimumFractionDigits: 2 })}</td>
                                <td style={{ textAlign: "right" }}><NetInput value={serviceData.vat.other} isEditable={(editAuthorized && monthEditable)} onChange={(e) => setData(e, "vat", "other")} /></td>
                                <td style={{ textAlign: "right" }}>{getServiceTotal(serviceData.vat)?.toLocaleString(undefined, { minimumFractionDigits: 2 })}</td>
                            </tr>
                        }
                        {
                            serviceData.shareCapital.present &&
                            <tr>
                                <td style={{ paddingLeft: "36px" }}>Share Capital</td>
                                <td style={{ textAlign: "right" }}>{getGross(serviceData.shareCapital)?.toLocaleString(undefined, { minimumFractionDigits: 2 })}</td>
                                <td style={{ textAlign: "right" }}>{serviceData.shareCapital.unpaidDD?.toLocaleString(undefined, { minimumFractionDigits: 2 })}</td>
                                <td style={{ textAlign: "right" }}><NetInput value={serviceData.shareCapital.other} isEditable={!isNewClient&& (editAuthorized && monthEditable)} onChange={(e) => setData(e, "shareCapital", "other")} /></td>
                                <td style={{ textAlign: "right" }}>{getServiceTotal(serviceData.shareCapital)?.toLocaleString(undefined, { minimumFractionDigits: 2 })}</td>
                            </tr>
                        }
                        {
                            serviceData.bookeepingRecording.present &&
                            <tr className="border-bottom">
                                <td style={{ paddingLeft: "36px" }}>Subtotal</td>
                                <td style={{ textAlign: "right" }}>{getBRCurrentTotal()?.toLocaleString(undefined, { minimumFractionDigits: 2 })}</td>
                                <td style={{ textAlign: "right" }}>{getBRUnpaidTotal()?.toLocaleString(undefined, { minimumFractionDigits: 2 })}</td>
                                <td style={{ textAlign: "right" }}>{getBROtherTotal()?.toLocaleString(undefined, { minimumFractionDigits: 2 })}</td>
                                <td style={{ textAlign: "right" }}>{getBRTotal()?.toLocaleString(undefined, { minimumFractionDigits: 2 })}</td>
                            </tr>
                        }
                        {
                            serviceData.farmPro.present &&
                            <tr>
                                <td>Farm Pro</td>
                                <td style={{ textAlign: "right" }}>{getGross(serviceData.farmPro)?.toLocaleString(undefined, { minimumFractionDigits: 2 })}</td>
                                <td style={{ textAlign: "right" }}>{serviceData.farmPro.unpaidDD?.toLocaleString(undefined, { minimumFractionDigits: 2 })}</td>
                                <td style={{ textAlign: "right" }}><NetInput value={serviceData.farmPro.other} isEditable={!isNewClient&& (editAuthorized && monthEditable)} onChange={(e) => setData(e, "farmPro", "other")} /></td>
                                <td style={{ textAlign: "right" }}>{getServiceTotal(serviceData.farmPro)?.toLocaleString(undefined, { minimumFractionDigits: 2 })}</td>
                            </tr>
                        }
                        {
                            serviceData.payroll.present &&
                            <tr>
                                <td>Payroll by IFAC</td>
                                <td style={{ textAlign: "right" }}>{getGross(serviceData.payroll)?.toLocaleString(undefined, { minimumFractionDigits: 2 })}</td>
                                <td style={{ textAlign: "right" }}>{serviceData.payroll.unpaidDD?.toLocaleString(undefined, { minimumFractionDigits: 2 })}</td>
                                <td style={{ textAlign: "right" }}><NetInput value={serviceData.payroll.other} isEditable={!isNewClient&& (editAuthorized && monthEditable)} onChange={(e) => setData(e, "payroll", "other")} /></td>
                                <td style={{ textAlign: "right" }}>{getServiceTotal(serviceData.payroll)?.toLocaleString(undefined, { minimumFractionDigits: 2 })}</td>
                            </tr>
                        }
                        {
                            serviceData.unpaidDD.present &&
                            <tr>
                                <td>Unpaid DD Fee</td>
                                <td style={{ textAlign: "right" }}>{getGross(serviceData.unpaidDD)?.toLocaleString(undefined, { minimumFractionDigits: 2 })}</td>
                                <td style={{ textAlign: "right" }}>{serviceData.unpaidDD.unpaidDD?.toLocaleString(undefined, { minimumFractionDigits: 2 })}</td>
                                <td style={{ textAlign: "right" }}><NetInput value={serviceData.unpaidDD.other} isEditable={!isNewClient&& (editAuthorized && monthEditable)} onChange={(e) => setData(e, "unpaidDD", "other")} /></td>
                                <td style={{ textAlign: "right" }}>{getServiceTotal(serviceData.unpaidDD)?.toLocaleString(undefined, { minimumFractionDigits: 2 })}</td>
                            </tr>
                        }
                        {
                            serviceData.prepaymentTopUp.present &&
                            <tr>
                                <td>Prepayment Top-up</td>
                                <td style={{ textAlign: "right" }}>{getGross(serviceData.prepaymentTopUp)?.toLocaleString(undefined, { minimumFractionDigits: 2 })}</td>
                                <td style={{ textAlign: "right" }}>{serviceData.prepaymentTopUp.unpaidDD?.toLocaleString(undefined, { minimumFractionDigits: 2 })}</td>
                                <td style={{ textAlign: "right" }}><NetInput value={serviceData.prepaymentTopUp.other} isEditable={!isNewClient&& (editAuthorized && monthEditable)} onChange={(e) => setData(e, "prepaymentTopUp", "other")} /></td>
                                <td style={{ textAlign: "right" }}>{getServiceTotal(serviceData.prepaymentTopUp)?.toLocaleString(undefined, { minimumFractionDigits: 2 })}</td>
                            </tr>
                        }
                        <tr>
                            <td style={{ textDecoration: "underline" }}>LO Other:</td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                        </tr>
                        <tr>
                            <td><div style={{display:"flex", flexDirection:"row", alignItems:"center", justifyContent:"space-between"}}><p>Invoice No.</p> 
                            <StandardSelect items={getInvoices()} selectedItem={selectedInvoice} placeholder={placeholderInvoice} onChange={(a,b)=>onInvoiceChanged(a,b.children)} disabled={!(editAuthorized && monthEditable && hasBankDetails)}/></div></td>
                            <td style={{ textAlign: "right" }}><NetInput value={loOtherAmount} isEditable={!isNewClient&& (editAuthorized && monthEditable && hasBankDetails)} onChange={ onLoOtherAmountChanged} /></td>
                            <td style={{ textAlign: "right" }}>{Number(loOtherUnpaid).toLocaleString(undefined, { minimumFractionDigits: 2 })}</td>
                            <td></td>
                            <td style={{ textAlign: "right" }}>{Number((loOtherAmount ?? 0) + (loOtherUnpaid ?? 0)).toLocaleString(undefined, { minimumFractionDigits: 2 })}</td>
                        </tr>
                        <tr>
                            <td><div style={{display:"flex", flexDirection:"row", alignItems:"center", justifyContent:"space-between"}}><p>Invoice No.</p> 
                            <StandardSelect items={getInvoices1()} selectedItem={selectedInvoice1} placeholder={placeholderInvoice1} onChange={(a,b)=>onInvoiceChanged1(a,b.children)} disabled={ !(editAuthorized && monthEditable && hasBankDetails)}/></div></td>
                            <td style={{ textAlign: "right" }}><NetInput value={loOtherAmount1} isEditable={!isNewClient&& (editAuthorized && monthEditable && hasBankDetails)} onChange={ onLoOtherAmountChanged1} /></td>
                            <td style={{ textAlign: "right" }}>{Number(loOtherUnpaid1).toLocaleString(undefined, { minimumFractionDigits: 2 })}</td>
                            <td></td>
                            <td style={{ textAlign: "right" }}>{Number((loOtherAmount1 ?? 0) + (loOtherUnpaid1 ?? 0)).toLocaleString(undefined, { minimumFractionDigits: 2 })}</td>
                        </tr>
                        <tr style={{ fontWeight: "bold" }}>
                            <td>Total</td>
                            <td style={{ textAlign: "right" }}>{getCurrentTotal()?.toLocaleString(undefined, { minimumFractionDigits: 2 })}</td>
                            <td style={{ textAlign: "right" }}>{getUnpaidTotal()?.toLocaleString(undefined, { minimumFractionDigits: 2 })}</td>
                            <td style={{ textAlign: "right" }}>{getOtherTotal()?.toLocaleString(undefined, { minimumFractionDigits: 2 })}</td>
                            <td style={{ textAlign: "right" }}>{getTotal()?.toLocaleString(undefined, { minimumFractionDigits: 2 })}</td>
                        </tr>
                    </tbody>
                </table>
                </form>
                <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-end", paddingTop: "20px" }}>
                    <Authorize permissions={["Monthly Invoice - Edit"]}>
                        <SaveButton style={{ marginRight: "10px" }} callback={saveInvocie} disabled={isNewClient?true:(!saveEnabled || !monthEditable || !isDirty)} />
                        <CancelButton callback={cancelChanges} disabled={isNewClient?true: !isDirty} />
                    </Authorize>
                </div>
            </div>
        )
}

const paymentMethods = [
    {
        key: 0,
        text: "No selection"
    },
    {
        key: 1,
        text: "DD"
    },
    {
        key: 2,
        text: "Cash"
    },
]

function NetInput({ value, isEditable, onChange }) {
    if (isEditable)
        return (
            <NumericInput value={value} setValue={onChange} />
        )
    else
        return (
            <p>{value?.toLocaleString(undefined, { minimumFractionDigits: 2 })}</p>
        )
}

export default MonthlyDDPage;

