import { Button } from "antd";
import { CloseCircleOutlined } from '@ant-design/icons';
import { useState } from "react";
import { HasValue } from "../Helper/JSHelper";

function CancelButton({disabled=false, text="Cancel", callback, style}) {

    return (
        <Button style={style} disabled={disabled} className="btn-cancel" icon={<CloseCircleOutlined />} onClick={callback}>{text}</Button>
    )
}

export default CancelButton