import React, { useState } from "react";
import { HasValue } from "../Helper/JSHelper";

export const MainContext = React.createContext();

export function Context({children}) {
    let initialClient = {
        code: null,
        title: null,
        firstName: null,
        surname: null,
        officeCode: null,
        officeName: null,
        isNewClient:'0'
    }

    const code = sessionStorage.getItem("clientCode");
    const title = sessionStorage.getItem("clientTitle");
    const firstName = sessionStorage.getItem("clientFirstName");
    const surname = sessionStorage.getItem("clientSurname");
    const officeCode = sessionStorage.getItem("clientOfficeCode");
    const officeName = sessionStorage.getItem("clientOfficeName");
    const isNewClient=sessionStorage.getItem("isNewClient");
    if (HasValue(code))
        initialClient = {
            code,
            title,
            firstName,
            surname,
            officeCode,
            officeName,
            isNewClient
        };

    const [client, setClient] = useState(initialClient);

    const [office, setOffice] = useState({
        code: -1,
        name: null,
        singleOffice: false
    });

    const contextValue = {client, setClient, office, setOffice};
    return ( 
        <MainContext.Provider value={contextValue}>
            {children}
        </MainContext.Provider>
    )
}