import React from 'react';
import { Layout, Menu, Breadcrumb, Image } from 'antd';
import {
  UsergroupAddOutlined,
  DatabaseOutlined,
  SettingOutlined,
  BookOutlined
} from '@ant-design/icons';
import { Link, useParams } from 'react-router-dom';
import { _imgFeenomLogoRevShort } from '../Resources/Resources';
import TermsFooter from '../Components/TermsFooter';
import UserCard from '../Components/UserCard';
import {MenuExt, SubMenuExt, MenuItemExt} from '../Components/AntdExtended/AuthorizationExt';
import { GetAuthorizationToken } from '../Helper/JWTToken';
import { MsalContext } from '@azure/msal-react';
import ClientCard from '../Components/ClientCard';
import {Context} from '../Components/Context' 
import OfficeCard from '../Components/OfficeCard';
import OfficeCardSelect from '../Components/OfficeCardSelect';
import HeaderAction from '../Components/HeaderAction';
import {
  BrowserRouter as Router,
  Switch,
  Route
} from 'react-router-dom';

const { Header, Content, Sider } = Layout;
const { SubMenu } = Menu;

class MainLayout extends React.Component {
  static contextType = MsalContext;

  state = {
    collapsed: false,
    userName: "",
    isLoaded: false,
    setUserName: this.setUserName,
    selectedKey:["3"],
    openKeys:[]
  };

  onCollapse = collapsed => {
    this.setState({ collapsed });
  };

  setUserName = (name) => {
    this.setState({userName: name});
  }

  componentDidMount() {

    const msal = this.context;

    const InitialTokenGet = async () => {
      await GetAuthorizationToken(msal);
      this.setState({isLoaded: true});
    }

    InitialTokenGet();
}
  
  render() {
    const { collapsed } = this.state;
    let { openKeys } = this.state;
    const rootSubmenuKeys = ['sub1', 'sub2', 'sub3'];

    const onOpenChange = (keys) => {
      const latestOpenKey = keys.find(key => openKeys.indexOf(key) === -1);
      if (rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
        this.setState({openKeys: keys});
      } else {
        const val = (latestOpenKey) ? [latestOpenKey] : [];
        this.setState({openKeys: val});
      }
    };

    if (this.state.isLoaded)
    return (

      <Layout className='div-layout' style={{ minHeight: '100vh' }}>
        <Sider collapsible width={"250px"} collapsed={collapsed} onCollapse={this.onCollapse}>
          <div>
            <Link to="/"><Image style={{display:"flex", justifyContent:"center"}} src={_imgFeenomLogoRevShort} preview={false}></Image></Link>
          </div>
          <MenuExt theme="dark" mode="inline" openKeys={openKeys} onOpenChange={onOpenChange}>
            <SubMenuExt key="sub1" icon={<UsergroupAddOutlined />} title="Clients">
              {/*<MenuItemExt disabled={true} permissions={["Edit Client Field"]} key="1">Edit Client Field</MenuItemExt>*/}
              <MenuItemExt permissions={["Client History"]} key="2"><Link to="/client/history">Client History</Link></MenuItemExt>
            </SubMenuExt>
            <SubMenu key="sub2" icon={<BookOutlined />} title="Invoicing">
            <MenuItemExt permissions={["Standard Monthly Fees - View"]} key="3"><Link to="/invoicing/stdfees">Standard Monthly Fees</Link></MenuItemExt>             
              <MenuItemExt permissions={["Monthly Invoice - View"]} key="4"><Link to="/invoicing/monthlyinvoice">Monthly Invoice Preparation</Link></MenuItemExt>
              <MenuItemExt permissions={["Monthly Direct Debit - View"]} key="5"><Link to="/invoicing/monthlydd">Monthly Direct Debit Preparation</Link></MenuItemExt>
              <MenuItemExt permissions={["Monthly Accounts Receivable"]} key="6"><Link to="/invoicing/monthlyar">Monthly Accounts Receivable</Link></MenuItemExt>
              <MenuItemExt permissions={["Invoice Format"]} key="7"><Link to="/invoicing/format">Invoices List</Link></MenuItemExt>
              <MenuItemExt permissions={["Receipt Format"]} key="8"><Link to="/invoicing/receipts">Receipts List</Link></MenuItemExt>             
              <MenuItemExt permissions={["Refund List"]} key="9"><Link to="/invoicing/refunds">Refunds List</Link></MenuItemExt>             
              <MenuItemExt permissions={["Fee Statement"]} key="10"><Link to="/invoicing/feestatement">Fee Statement</Link></MenuItemExt>
              <MenuItemExt permissions={["Lodgements and Receipts Rec"]} key="11"><Link to="/invoicing/lodgements">Lodgements Reconciliation</Link></MenuItemExt>
              <MenuItemExt permissions={["Create Invoice (HQ)"]} key="12"><Link to="/invoicing/createmonthlyinvoice">Create Monthly Invoice</Link></MenuItemExt>       
              <MenuItemExt permissions={["Create Credit Note (HQ)"]} key="13"><Link to="/invoicing/createcreditnote">Create Monthly Credit Note</Link></MenuItemExt>
              <MenuItemExt permissions={["AddRefund"]} key="14"><Link to="/invoicing/addrefund">Add Refund</Link></MenuItemExt>
              <MenuItemExt permissions={["CreateJournal"]} key="15"><Link to="/invoicing/createjournal">Create Journal</Link></MenuItemExt>
              <MenuItemExt permissions={["BadDebit"]} key="16"><Link to="/invoicing/baddebts">Write Off Bad Debt</Link></MenuItemExt>
              <MenuItemExt permissions={["Vat Rate"]} key="17"><Link to="/invoicing/vatrate">Vat Rate</Link></MenuItemExt>

            </SubMenu>
            <SubMenu key="sub3" icon={<DatabaseOutlined />} title="Reporting">
              <MenuItemExt permissions={["Unpaid Direct Debits - View"]} key="18"><Link to="/reporting/unpaiddd">Unpaid Direct Debits</Link></MenuItemExt>
              <MenuItemExt permissions={["Shares Bought"]} key="19"><Link to="/reporting/sharesbought">Shares</Link></MenuItemExt>
              {/* <MenuItemExt permissions={["Debtors Control Account"]} key="20"><Link to="/reporting/debtorscontrolaccount">Debtors Control Account</Link></MenuItemExt> */}
            </SubMenu>
          </MenuExt>
        </Sider>
        <Layout className="site-layout">
          <Header className="site-layout-background" style={{ padding: 0 }} >
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginRight: '15px', marginLeft: '15px' }}>
              <Switch>                  
              <Route path='/invoicing/format'>
                <h2>Invoices List</h2>
                <ClientCard/> 
                <div style={{ display: 'flex', justifyContent: 'flex-start', alignItems:'center'}}>  
                  <OfficeCard/>
                </div>
              </Route>   
              <Route path='/invoicing/receipts'>
                <h2>Receipts List</h2>
                <ClientCard/> 
                <div style={{ display: 'flex', justifyContent: 'flex-start', alignItems:'center'}}>  
                  <OfficeCard/>
                </div>
              </Route>  
              <Route path='/invoicing/refunds'>
                <h2>Refunds List</h2>
                <ClientCard/> 
                <div style={{ display: 'flex', justifyContent: 'flex-start', alignItems:'center'}}>  
                  <OfficeCard/>
                </div>
              </Route> 
              <Route path='/invoicing/vatrate'>
              <div style={{ display: 'flex', justifyContent: 'flex-start', alignItems:'center'}}>  
                  <h2>Vat Rate</h2>
              </div>
              </Route>     
              <Route path='/invoicing/stdfees'>
              <div style={{ display: 'flex', justifyContent: 'flex-start', alignItems:'center'}}>  
                  <h2>Standard Monthly Fees</h2>
              </div>
              </Route>   
              <Route path='/invoicing/monthlyinvoice'>
                  <h2>Monthly Invoice Preparation</h2>
                  <ClientCard/> 
              </Route>  
              <Route path='/invoicing/createmonthlyinvoice'>
                  <h2>Create Monthly Invoice</h2>
                  <ClientCard/> 
              </Route>  
              <Route path='/invoicing/createcreditnote'>
                  <h2>Create Monthly Credit Note</h2>
                  <ClientCard/> 
              </Route>  
              <Route path='/invoicing/monthlydd'>
                  <h2>Monthly Direct Debit Preparation</h2>
                  <ClientCard/> 
              </Route>   
              <Route path='/invoicing/monthlyar'>
              <div style={{ display: 'flex', justifyContent: 'flex-start', alignItems:'center'}}>  
                  <h2>Monthly Accounts Receivable</h2>
              </div>
              </Route> 
              <Route path='/invoicing/lodgements'>
              <div style={{ display: 'flex', justifyContent: 'flex-start', alignItems:'center'}}>  
                  <h2>Lodgements and Receipts Reconciliation</h2>
              </div>
              </Route> 
              <Route path='/client/history'>
                <h2>Client History</h2>
                <ClientCard/>
              </Route>   
              <Route path='/invoicing/feestatement'>
                <h2>Fee Statement</h2>
                <ClientCard/>
              </Route> 
              <Route path='/reporting/unpaiddd'>
                <h2>Unpaid Direct Debits</h2>
                <OfficeCard hasAllOffices={true}/>
              </Route>     
              <Route path='/reporting/sharesbought'>
              <div style={{ display: 'flex', justifyContent: 'flex-start', alignItems:'center'}}>  
                  <h2>Shares</h2>
              </div>
              </Route>    
              <Route path='/reporting/debtorscontrolaccount'>
              <div style={{ display: 'flex', justifyContent: 'flex-start', alignItems:'center'}}>  
                  <h2>Debtors Control Account</h2>
                  <OfficeCardSelect/>
              </div>
              </Route> 
              <Route path='/invoicing/addrefund'>
                  <h2>Add Refund</h2>
                  <ClientCard/>
              </Route>  
              <Route path='/invoicing/createjournal'>
              <div style={{ display: 'flex', justifyContent: 'flex-start', alignItems:'center'}}>  
                  <h2>Create Journal</h2>
              </div>
              </Route>    
              <Route path='/invoicing/baddebts'>
                  <h2>Write Off Bad Debts</h2>
                  <ClientCard/>
              </Route>            
              </Switch>
              <UserCard></UserCard>
            </div>
          </Header>
          <Content style={{ margin: '0 16px' }}>
            <Breadcrumb style={{ margin: '40px 0' }}>
              <Breadcrumb.Item></Breadcrumb.Item>
            </Breadcrumb>
            <div className="site-layout-background" style={{ padding: 20, minHeight: 360 }}>
              {this.props.children}
            </div>
          </Content>
          <TermsFooter style={{ textAlign: 'center', backgroundColor:"white" }}>Ant Design ©2018 Created by Ant UED</TermsFooter>
        </Layout>
      </Layout>

    );

    else
      return (<></>);
  }
}

export default MainLayout;