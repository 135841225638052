
import { Modal, Button } from "antd";

import SaveButton from "../Components/SaveButton";
import CancelButton from "../Components/CancelButton";

const { confirm } = Modal;

const AlertModal = async (text, button = "Close") => {      
    confirm({
      width:"464px",
      content: 
        <div style={{width:"400px"}}>
            <p>{text}</p>
            <div style={{marginTop:"20px", width:"100%", display: "flex", justifyContent: "flex-end"}}>
                <Button type="primary" onClick={() => Modal.destroyAll()}>{button}</Button>
            </div>
        </div>,
      okButtonProps: {style:{display:"none"}},
      cancelButtonProps: {style:{display:"none"}},
    });
} 

export default AlertModal;