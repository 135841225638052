import { useEffect, useState, useContext } from "react";
import { Spin, Table} from "antd";
import { useMsal } from "@azure/msal-react";
import { GetAccessToken } from "../Helper/JWTToken";
import { MainContext } from "../Components/Context";
import { HasValue } from "../Helper/JSHelper";
import ColumnSearchProps from "../Components/AntdExtended/ColumnSearchProps";
import { GetHistory } from "../Data Layer/Data";

function TablePagination() {
  return new
  {
    showTotal: true
  }
}

function ClientHistoryPage() {

    const context = useContext(MainContext);
    const msal = useMsal();
    const [history, setHistory] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    
    useEffect(async () => {
        if (HasValue(context.client.code))
        {
            setIsLoading(true);
            const token = await GetAccessToken(msal);
            const data = await GetHistory(token, context.client.code);

            const historyList = data.history.map((item, i) => {
                return {
                    key: i,
                    fieldName: item.fieldChanged,
                    oldValue: item.oldVal ?? "",     
                    newValue: item.newVal ?? "",          
                    startDate: new Date(item.commenceDate),
                    startDateString: (HasValue(item.commenceDate)) ? new Date(item.commenceDate).toLocaleDateString("en-GB") : null,   
                    note: item.note,          
                    modifiedDate: new Date(item.dateModified),
                    modifiedDateString: (HasValue(item.dateModified)) ? new Date(item.dateModified).toLocaleDateString("en-GB") : null,
                    staff: item.modifiedBy
                }
            });

            setHistory(historyList);
            setIsLoading(false);
        }
        else 
          setHistory(null);
    }, [context.client]);
    
    const columns = [
        {
          title: 'Field Name',
          dataIndex: 'fieldName',
          key: 'fieldName',
          sorter: (a,b) => (a.fieldName?.toLowerCase() < b.fieldName?.toLowerCase()) ? -1 : 1,
          ...ColumnSearchProps("fieldName", "Field Name")
        },
        {
          title: 'Old Value',
          dataIndex: 'oldValue',
          key: 'oldValue',
          sorter: (a,b) => a.oldValue.localeCompare(b.oldValue, undefined, { numeric: true, sensitivity: 'base'}),
          ...ColumnSearchProps("oldValue", "Old Value")
        },
        {
          title: 'New Value',
          dataIndex: 'newValue',
          key: 'newValue',
          sorter: (a,b) => a.newValue.localeCompare(b.newValue, undefined, { numeric: true, sensitivity: 'base'}),
          ...ColumnSearchProps("newValue", "New Value")
        },
        {
          title: 'Effective Date',
          dataIndex: 'startDateString',
          key: 'startDate',
          sorter: (a,b) => (a.startDate < b.startDate) ? -1 : 1,
          ...ColumnSearchProps("startDateString", "Effective Date"),
          render: (text, record) => <p className="text-center">{text}</p>,
        },
        {
          title: 'Modified Date',
          dataIndex: 'modifiedDateString',
          key: 'modifiedDate',
          sorter: (a,b) => (a.modifiedDate < b.modifiedDate) ? -1 : 1,
          ...ColumnSearchProps("modifiedDateString", "Modified Date"),
          render: (text, record) => <p className="text-center">{text}</p>,
        },
        {
          title: 'Staff',
          dataIndex: 'staff',
          key: 'staff',
          sorter: (a,b) => (Number(a.staff.split(" ")[0]) < Number(b.staff.split(" ")[0])) ? -1 : 1,
          ...ColumnSearchProps("staff", "Staff")
        },
      ];

    function setTitle () {
        return <h3>Client History - {context.client.code} {context.client.firstName} {context.client.surname}</h3>;
    }
    
    if (isLoading)
      return (
        <div style={{width:"100%", height:"100%", minHeight:"inherit", display:"flex", alignItems:"center", justifyContent:"center"}}>
            <Spin size="large" />
        </div>
      )
    if (history === null)
        return (
            <p style={{padding:"20px"}}>Select a client to view history.</p>
        )
    else
        return (
          <div  className="div-ant-table">
                <Table title={setTitle} dataSource={history} columns={columns} pagination={{showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`}}/>
          </div>
        )
}

export default ClientHistoryPage;