import imgGreenFields from './images/green-field-login.png'
import imgIfacLogoWhite from './images/ifac-logo-white.png'
import imgIfacLogoSidebar from './images/ifac-logo-sidebar.png'
import imgIfacLogoLong from './images/ifac-logo-long.png'
import imgFeenomLogo from './images/feenom_logo.jpg'
import imgFeenomLogoRev from './images/feenom_logo_rev.jpg'
import imgFeenomLogoRevShort from './images/feenom_logo_rev_short.jpg'

import fontCalibri from './fonts/calibri-regular-edited.ttf'
import fontCalibriBold from './fonts/calibri-bold.ttf'
import fontCalibriItalic from './fonts/calibri-italic.ttf'

export const _imgGreenFields = imgGreenFields;
export const _imgIfacLogoWhite = imgIfacLogoWhite;
export const _imgIfacLogoSidebar = imgIfacLogoSidebar;
export const _imgIfacLogoLong = imgIfacLogoLong;
export const _imgFeenomLogo = imgFeenomLogo;
export const _imgFeenomLogoRev = imgFeenomLogoRev;
export const _imgFeenomLogoRevShort = imgFeenomLogoRevShort;

export const _fontCalibri = fontCalibri;
export const _fontCalibriBold = fontCalibriBold;
export const _fontCalibriItalic = fontCalibriItalic;