
import { Menu } from "antd";
import { AuthExtension } from "../../Helper/Authorization";

export const MenuExt = AuthExtension(Menu);

const { SubMenu } = Menu;
export const SubMenuExt = AuthExtension(SubMenu);

const { Item } = Menu;
export const MenuItemExt = AuthExtension(Item);

