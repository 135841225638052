import { useEffect, useState, useContext } from "react";
import NamedInput from "../Components/NamedInput";
import MainLayout from "./MainLayout";
import { Spin, Table, Button, Input, Pagination, DatePicker} from "antd";
import {SearchOutlined} from "@ant-design/icons";
import { useMsal } from "@azure/msal-react";
import { GetAccessToken } from "../Helper/JWTToken";
import { FindClients, GetHistory, GetSharesBoughtReport, GetUnprintedInvoices } from "../Data Layer/Data";
import { MainContext } from "../Components/Context";
import PDFInvoice from "../Components/PDFInvoice";
import { HasValue } from "../Helper/JSHelper";
import ColumnSearchProps from "../Components/AntdExtended/ColumnSearchProps";

import ReactPDF, { pdf, PDFViewer } from '@react-pdf/renderer'
import { ArrowLeftOutlined } from "@ant-design/icons";
import moment from "moment";

const { RangePicker } = DatePicker;

function InvoiceListPage() {
    //Default date ranges for client and office selection
    const defRangeClient = null;
    const defRangeOffice = [moment().subtract(30, 'days'), moment()]

    const context = useContext(MainContext);
    const msal = useMsal();
    const [invoices, setInvoices] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [selectedInvoice, setSelectedInvoice] = useState(null);
    const [viewInvoice, setViewInvocie] = useState(false);
    const [dateRange, setDateRange] = useState(defRangeClient);
    const [initialGenerate, setInitialGenerate] = useState(true);

    useEffect(async () => {
      if(HasValue(context.client.code)) {
        setDateRange(defRangeClient);
      }
      else if(HasValue(context.office.code) && context.office.code != -1) {
        setDateRange(defRangeOffice);
      }
      generateTable();
    }, []);
    useEffect(async () => {
        if (!initialGenerate)
        {
          if(HasValue(context.client.code)) {
            //if date range is already at default generate table
            if (dateRange == defRangeClient)
              generateTable();
            //else change date range, which will then generate table
            else
              setDateRange(defRangeClient);
          }
          else if (context.office.code != -1 )
          {
            if (dateRange == defRangeOffice)
              generateTable();
            //else change date range, which will then generate table
            else
              setDateRange(defRangeOffice);
          }
          else if (!HasValue(context.office.code) || context.office.code == -1 || !HasValue(context.client.code))
            setInvoices(null)
        }
    }, [context.client.code]);

    useEffect(async () => {
      if (!initialGenerate)
      {
        if(HasValue(context.office.code) && context.office.code != -1) {
          //if date range is already at default generate table
          if (dateRange == defRangeOffice)
          generateTable();
          //else change date range, which will then generate table
          else
            setDateRange(defRangeOffice);
        }
        else if (!HasValue(context.office.code) || context.office.code == -1 || !HasValue(context.client.code))
            setInvoices(null)
      }
    }, [context.office.code]);

    useEffect(async () => {
      if (!initialGenerate)
        {
          generateTable();
        }
    }, [dateRange]);

    const generateTable = async() => {
      if ((HasValue(context.office.code) && context.office.code != -1) || HasValue(context.client.code))
        {
            let startDate = null;
            let endDate = null;
            let code = context.office.code;
            if (code === -1 || HasValue(context.client.code))
              code = null;

            if (HasValue(dateRange)){
              startDate = dateRange[0]?.format();
              endDate = dateRange[1]?.format();
            }

            setIsLoading(true);
            const token = await GetAccessToken(msal);
            const data = await GetUnprintedInvoices(token, context.client.code, code, startDate, endDate);

            setInvoices(data.invoices);         
            setIsLoading(false);
        }
      else 
        setInvoices(null);

      setInitialGenerate(false);
    }
    
    const mapInvoices = (invoices) => {
      const invoiceItems = invoices.map((item, i) => {
        return {
            key: i,
            number: item.invoiceNumber,
            clientString: `${item.clientCode} - ${item.clientFirstName} ${item.clientSurname}`,             
            createdDate: new Date(item.dateCreated),
            createdDateString: (HasValue(item.dateCreated)) ? new Date(item.dateCreated).toLocaleDateString("en-GB") : null, 
            balanceDue: item.amountDue,
            balanceDueString: `€${item.amountDueString}`,
            isCancelled: HasValue(item.dateCancelled),
            isCreditNote: item.isCreditNote,
            hasPdf: item.hasPdf
        }
      });

      return invoiceItems;
    };

    
    const columns = [
        {
          title: 'Reference Number',
          dataIndex: 'number',
          key: 'number',
          sorter: (a,b) => (a.number < b.number) ? -1 : 1,
          ...ColumnSearchProps("number", "Reference Number"),
          render: (text, record) => {
            return (
              <>
              { 
                (record.isCancelled) ?
                <p className="p-cancelled">{text}</p> :
                (
                  (record.isCreditNote) ?
                  <p className="p-red">{text}</p> :
                  <p>{text}</p> 
                )
              }
              </>
            )
          }
                  
        },
        {
          title: 'Client',
          dataIndex: 'clientString',
          key: 'clientString',
          ...ColumnSearchProps("clientString", "Client"),         
          render: (text, record) => (record.isCreditNote) ?
                                    <p className="text-center p-red">{text}</p> :
                                    <p className="text-center">{text}</p> 
        },
        {
          title: 'Date Created',
          dataIndex: 'createdDateString',
          key: 'createdDate',
          sorter: (a,b) => (a.createdDate < b.createdDate) ? -1 : 1,
          ...ColumnSearchProps("createdDateString", "Date Created"),         
          render: (text, record) => (record.isCreditNote) ?
                                    <p className="text-center p-red">{text}</p> :
                                    <p className="text-center">{text}</p> 
        },
        {
          title: 'Balance Due',
          dataIndex: 'balanceDueString',
          key: 'balanceDue',
          sorter: (a,b) => (a.balanceDue < b.balanceDue) ? -1 : 1,
          render: (text, record) => (record.isCreditNote) ?
                                    <p className="text-center p-red">{text}</p> :
                                    <p className="text-center">{text}</p> 
        },
        {
          title: '',
          dataIndex: '',
          key: '',
          render: (text, record) => {
            if (record.hasPdf)
              return(
                <div className="text-center">
                  <Button type="primary" onClick={() => { showInvoice(record.number);}}>View</Button>
                </div>
              );
            else
                return null;
          }
        },
      ];

    const showInvoice = async (number) => {
      const invoice = getSelectedInvoice(number);     
      const blob = await pdf(<PDFInvoice invoice={invoice}/>).toBlob();
      let url = URL.createObjectURL(blob)
      window.open(url, "_blank");
    }

    const dateChanged = (value) => {
        setDateRange(value);
    }

    function setTitle () {
        let text = "";

        if (HasValue(context.client.code)){
            text = `${context.client.code} ${context.client.firstName ?? ''} ${context.client.surname ?? ''}, from ${context.client.officeCode} - ${context.client.officeName ?? ''}`;
        }       
        else if (HasValue(context.office.code) && context.office.code != -1)
          text += `${context.office.code} - ${context.office.name}`;

        return (
          <div style={{display:"flex", flexDirection:"row", justifyContent:"space-between"}}>
            <h3>Invoices for: {text}</h3>
            <RangePicker value={dateRange} format="DD-MM-YYYY" onChange={dateChanged} allowEmpty={[true,true]} allowClear={true}></RangePicker>
          </div>
        );
    }

    const getSelectedInvoice = (number) => {
      if (HasValue(number)) {
        for (let i = 0; i < invoices.length; i++)
          if (invoices[i].invoiceNumber === number)
            return invoices[i];
      }

      return null;
    }

    if (viewInvoice)
      return (
        <div className="pdf-container" style={{width:"100%", height:"100%", display:"flex", flexDirection:"column"}}>
          <PDFViewer>
              <PDFInvoice invoice={getSelectedInvoice()}/>
          </PDFViewer>
        </div>
      )
    
    if (isLoading)
      return (
        <div style={{width:"100%", height:"100%", minHeight:"inherit", display:"flex", alignItems:"center", justifyContent:"center"}}>
            <Spin size="large" />
        </div>
      )
    if (invoices === null)
        return (
            <p style={{padding:"20px"}}>Select a client or an office to view invoices.</p>
        )
    else
        return (
          
          <div className="div-ant-table" style={{maxHeight:"100%", width: "100%", overflow:"auto"}}>
            <Table title={setTitle} dataSource={mapInvoices(invoices)} columns={columns} pagination={{showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`}}/>
          </div>
        )
}

export default InvoiceListPage;