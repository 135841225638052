import { Modal, Button } from "antd";
import SaveButton from "../Components/SaveButton";
import CancelButton from "../Components/CancelButton";
import { Input } from 'antd';
import { useEffect, useState } from "react";

const { TextArea } = Input;

const { confirm } = Modal;

function TextEditModal ({toEdit, visible, setVisible, callback, saveButton = "Change", closeButton = "Close"}) {     
    const [text, setText] = useState(toEdit);

    useEffect(async () => {
      setText(toEdit);
   }, [toEdit]);

    return (
      <Modal visible={visible} okButtonProps={{style:{display:"none"}}} cancelButtonProps={{style:{display:"none"}}}>
        <div style={{width:"500px"}}>
            <TextArea rows={10} value={text} onChange={e => setText(e.target.value)}/>
            <div style={{marginTop:"20px", width:"100%", display: "flex", justifyContent: "flex-end"}}>
                <SaveButton text={saveButton} callback={() => {callback(text); setVisible(false);}}/>
                <CancelButton text={closeButton} style={{marginLeft:"20px"}} callback={() => setVisible(false)}/>
            </div>
        </div>
      </Modal>
    )
} 

export default TextEditModal;