import { useEffect, useState, useContext } from "react";
import { Spin, Table, Button, DatePicker} from "antd";
import { useMsal } from "@azure/msal-react";
import { GetAccessToken } from "../Helper/JWTToken";
import { DeleteRefund, GetRefundList  } from "../Data Layer/Data";
import { MainContext } from "../Components/Context";
import { HasValue } from "../Helper/JSHelper";
import ColumnSearchProps from "../Components/AntdExtended/ColumnSearchProps";
import { pdf } from '@react-pdf/renderer'
import moment from "moment";
import PDFRefund from "../Components/PDFRefund";
import { DeleteOutlined} from '@ant-design/icons';
import TextEntryModal from "../Components/TextEntryModal";
import ConfirmModal from "../Components/ConfirmModal";
import StandardSelect from "../Components/StandardSelect";
import { Authorize } from "../Helper/Authorization";

const { RangePicker } = DatePicker;

const refundFilters = ["Unreconciled", "Reconciled", "Deleted"];

function RefundListPage() {
    //Default date ranges for client and office selection
    const defRangeClient = null;
    const defRangeOffice = [moment().subtract(30, 'days'), moment()]

    const context = useContext(MainContext);
    const msal = useMsal();
    const [refunds, setRefunds] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [dateRange, setDateRange] = useState(defRangeClient);
    const [filter, setFilter] = useState("Unreconciled");
    const [initialGenerate, setInitialGenerate] = useState(true);

    useEffect(async () => {
      if(HasValue(context.client.code)) {
        setDateRange(defRangeClient);
      }
      else if(HasValue(context.office.code) && context.office.code != -1) {
        setDateRange(defRangeOffice);
      }
      generateTable();
    }, []);
    useEffect(async () => {
        if (!initialGenerate)
        {
          if(HasValue(context.client.code)) {
            //if date range is already at default generate table
            if (dateRange == defRangeClient)
              generateTable();
            //else change date range, which will then generate table
            else
              setDateRange(defRangeClient);
          }
          else if (context.office.code != -1 )
          {
            if (dateRange == defRangeOffice)
              generateTable();
            //else change date range, which will then generate table
            else
              setDateRange(defRangeOffice);
          }
          else if (!HasValue(context.office.code) || context.office.code == -1 || !HasValue(context.client.code))
            setRefunds(null)
        }
    }, [context.client.code]);

    useEffect(async () => {
      if (!initialGenerate)
      {
        if(HasValue(context.office.code) && context.office.code != -1) {
          //if date range is already at default generate table
          if (dateRange == defRangeOffice)
          generateTable();
          //else change date range, which will then generate table
          else
            setDateRange(defRangeOffice);
        }
        else if (!HasValue(context.office.code) || context.office.code == -1 || !HasValue(context.client.code))
          setRefunds(null)
      }
    }, [context.office.code]);

    useEffect(async () => {
      if (!initialGenerate)
        {
          generateTable();
        }
    }, [dateRange]);

    const generateTable = async() => {
      if ((HasValue(context.office.code) && context.office.code != -1) || HasValue(context.client.code))
        {
            let startDate = null;
            let endDate = null;

            let code = context.office.code;
            if (code === -1 || HasValue(context.client.code))
              code = null;

            if (HasValue(dateRange)){
              startDate = dateRange[0]?.format();
              endDate = dateRange[1]?.format();
            }

            setIsLoading(true);
            const token = await GetAccessToken(msal);
            const data = await GetRefundList(token, context.client.code, code, startDate, endDate);
 
            setRefunds(data.refunds);         
            setIsLoading(false);
        }
      else 
        setRefunds(null);

      setInitialGenerate(false);
    }
    
    const mapRefunds = (refunds) => {
      const refundItems = refunds.map((item, i) => {
        return {
            key: i,
            number: item.refundNo,
            clientString: `${item.clientCode} - ${item.clientName}`,             
            createdDate: new Date(item.createdDate),
            createdDateString: item.createdDateStr, 
            amount: item.amount,
            amountString: `€${item.amount?.toLocaleString(undefined, { minimumFractionDigits: 2 })}`,
            isReconciled: item.isReconciled,
            isUnreconciled: item.isUnreconciled,
            isDeleted: item.isDeleted
        }
      });

      return refundItems;
    };

    const filterRefunds = (refunds) => {
        let filteredRefunds = [];
        refunds.forEach(refund => {
          if (filter === "Reconciled" && refund.isReconciled)
            filteredRefunds.push(refund);
          else if (filter === "Unreconciled" && refund.isUnreconciled)
            filteredRefunds.push(refund);
          else if (filter === "Deleted" && refund.isDeleted)
            filteredRefunds.push(refund);
        });

        return filteredRefunds;
    }
    
    const columns = [
        {
          title: 'Refund Number',
          dataIndex: 'number',
          key: 'number',
          sorter: (a,b) => (a.number < b.number) ? -1 : 1,
          ...ColumnSearchProps("number", "Refund Number"),
          render: (text, record) => {
            return (
              <>
              { 
                (record.isDeleted) ?
                <p className="p-cancelled">{text}</p> :
                <p>{text}</p> 
              }
              </>
            )
          }
                  
        },
        {
          title: 'Client',
          dataIndex: 'clientString',
          key: 'clientString',
          sorter: (a,b) => (a.clientString < b.clientString) ? -1 : 1,
          ...ColumnSearchProps("clientString", "Client")
        },
        {
          title: 'Date Created',
          dataIndex: 'createdDateString',
          key: 'createdDate',
          sorter: (a,b) => (a.createdDate < b.createdDate) ? -1 : 1,
          ...ColumnSearchProps("createdDateString", "Date Created"),         
          render: (text, record) => <p className="text-center">{text}</p>
        },
        {
          title: 'Amount',
          dataIndex: 'amountString',
          key: 'amount',
          sorter: (a,b) => (a.balanceDue < b.balanceDue) ? -1 : 1,
          render: (text, record) => <p className="text-right">{text}</p>
        },
        {
          title: '',
          dataIndex: '',
          key: '',
          render: (text, record) =>
            <div style={{ display: 'flex', flexDirection: "row", justifyContent: "space-evenly" }}>
                <Button type="primary" onClick={() => { showRefund(record.number);}}>View</Button>
                { 
                !record.isDeleted &&
                <Authorize permissions={["AddRefund"]}>
                  <Button className="btn-delete-icon" icon={<DeleteOutlined />} onClick={() => deleteRefund(record.number)} />
                </Authorize>
                }
            </div>
        },
      ];

    const showRefund = async (number) => {
      const refund = getSelectedRefund(number);     
      const blob = await pdf(<PDFRefund refund={refund}/>).toBlob();
      let url = URL.createObjectURL(blob)
      window.open(url, "_blank");
    }

    const deleteRefund = (number) =>{
        const callback = async () => {    
            const callback2 = async (reason) => {
                const token = await GetAccessToken(msal);
                const result = await DeleteRefund(token, number, reason);
                if (result.status == 200) {
                    setIsLoading(true);
                    let startDate = null;
                    let endDate = null;

                    let code = context.office.code;
                    if (code === -1)
                    code = null;

                    if (HasValue(dateRange)){
                    startDate = dateRange[0]?.format();
                    endDate = dateRange[1]?.format();
                    }

                    setIsLoading(true);
                    const token = await GetAccessToken(msal);
                    const data = await GetRefundList(token, context.client.code, code, startDate, endDate);
        
                    setRefunds(data.refunds);         
                    setIsLoading(false);
                }
            }

            TextEntryModal("Reason", callback2);
        }

        ConfirmModal("Are you sure you want to delete this refund?", callback);
    }

    const dateChanged = (value) => {
        setDateRange(value);
    }

    const filterChanged = (key, item) => {
      setFilter(item.children);
    }

    function setTitle () {
        let text = "";

        if (HasValue(context.client.code)){
          text = `${context.client.code} ${context.client.firstName ?? ''} ${context.client.surname ?? ''}, from ${context.client.officeCode} - ${context.client.officeName ?? ''}`;
        }       
        else if (HasValue(context.office.code) && context.office.code != -1)
          text += `${context.office.code} - ${context.office.name}`;

        return (
          <div style={{display:"flex", flexDirection:"row", justifyContent:"space-between"}}>
            <h3>Refunds for: {text}</h3>
            <StandardSelect items={refundFilters} selectedItem={filter} onChange={filterChanged} style={{width:"150px"}}/>
            <RangePicker value={dateRange} onChange={dateChanged} format="DD-MM-YYYY" allowEmpty={[true,true]} allowClear={true}></RangePicker>
          </div>
        );
    }

    const getSelectedRefund = (number) => {
      if (HasValue(number)) {
        for (let i = 0; i < refunds.length; i++)
          if (refunds[i].refundNo === number)
            return refunds[i];
      }

      return null;
    }
    
    if (isLoading)
      return (
        <div style={{width:"100%", height:"100%", minHeight:"inherit", display:"flex", alignItems:"center", justifyContent:"center"}}>
            <Spin size="large" />
        </div>
      )
    if (refunds === null)
        return (
            <p style={{padding:"20px"}}>Select a client or an office to view refunds.</p>
        )
    else
        return (
          
          <div className="div-ant-table" style={{maxHeight:"100%", width: "100%", overflow:"auto"}}>
            <Table title={setTitle} dataSource={mapRefunds(filterRefunds(refunds))} columns={columns} pagination={{showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`}}/>
          </div>
        )
}

export default RefundListPage;