import { useMsal } from "@azure/msal-react";
import { Button, Dropdown, Input, Menu }  from 'antd'; 
import MenuItem from "antd/lib/menu/MenuItem";
import { useContext, useEffect, useState } from "react";
import { MainContext } from "../Components/Context";
import { FindClients } from "../Data Layer/Data";
import { HasValue } from "../Helper/JSHelper";
import { GetAccessToken } from "../Helper/JWTToken";

function ClientCard() {
    const context = useContext(MainContext);
    const [search, setSearch] = useState("");
    const [clientVisible, setClientVisible] = useState(false);
    const [clientDropdown, setClientDropdown] = useState(<></>);
    const msal = useMsal();

    useEffect(() => {
        if (!HasValue(context.client.code)){
            setSearch("");
            setClientDropdown(<></>);
        }           
    }, [context.client.code]);

    const onSearchChange = (e) => {
        e.preventDefault();
        setSearch(e.target.value)
    }

    const onSearchClick = async () => {
        const token = await GetAccessToken(msal);

        let searchTerms = search.split(' ');
        let code = null;
        let firstName = null;
        let surname = null

        for (let i = 0; i < searchTerms.length; i++) {
            //Check if search term is numeric
            if (isNaN(searchTerms[i]))
                //If not numeric and last term, assume surname
                if (i === searchTerms.length - 1)
                    surname = searchTerms[i]
                //else assume first name
                else
                    firstName = searchTerms[i];
            //if numeric assume code
            else
                code = searchTerms[i];
        }

        const data = await FindClients(token, code, firstName, surname);
        const setClient = (code, title, firstName, surname, officeCode, officeName,isNewClient) => {

            const client = {code, title, firstName, surname, officeCode, officeName,isNewClient};
            sessionStorage.setItem("isNewClient",isNewClient)
            sessionStorage.setItem("clientCode", code);
            sessionStorage.setItem("clientTitle", title);
            sessionStorage.setItem("clientFirstName", firstName);
            sessionStorage.setItem("clientSurname", surname);
            sessionStorage.setItem("clientOfficeCode", officeCode);
            sessionStorage.setItem("clientOfficeName", officeName);
            context.setClient(client);

            if (!context.office.singleOffice)
            {
                const office = {code: -1, name:null, singleOffice: false};
                context.setOffice(office);
            }

            setClientVisible(false);
        };

        // if there is only one client, select automatically
        if (HasValue(data.clients) && data.clients.length === 1) {
            setClient(data.clients[0].code, data.clients[0].title, data.clients[0].firstName, data.clients[0].surname, data.clients[0].officeCode, data.clients[0].officeName,data.clients[0].isNewClient)
            setClientDropdown(GenerateClientsDropdown(data.clients, setClient, setClientVisible));
        }
        else {
            setClientDropdown(GenerateClientsDropdown(data.clients, setClient, setClientVisible));
            //This is used to force the dropdown to appear without mouse-over
            setClientVisible(true);
        }
    }

    const onClearClick = () => {
        setSearch("");
        setClientDropdown(<></>);
        sessionStorage.removeItem("isNewClient");
        sessionStorage.removeItem("clientCode");
        sessionStorage.removeItem("clientTitle");
        sessionStorage.removeItem("clientFirstName");
        sessionStorage.removeItem("clientSurname");

        context.setClient({           
            code: null,
            title: null,
            firstName: null,
            surname: null,
            isNewClient:"0"
        });
    }

    let clientText = "No client selected"
    if (context.client.code !== null) {
        clientText = ` ${context.client.isNewClient=="1"?"*":""} ${context.client.code} ${context.client.firstName} ${context.client.surname}`;
    }

    //Used to close client dropdown on outside click
    const doc = document.getElementById('root');
    
    function closeDropdownHandler (e) {
        setClientVisible(false);
        doc.removeEventListener("click", closeDropdownHandler);
    }
    
    if (clientVisible) 
        doc.addEventListener("click", closeDropdownHandler);

    return (
        <div style={{display:"flex", justifyContent:"flex-start", alignItems:"center"}}>
            <h3>{clientText}</h3>
            {clientVisible ?
            <Dropdown overlay={clientDropdown} visible={clientVisible} >
                    <Input.Search placeholder="Client search" value={search} onChange={onSearchChange} onSearch={onSearchClick} style={{width: "200px", marginLeft: "30px"}}/>
            </Dropdown>
            :
            <Dropdown overlay={clientDropdown} >
                    <Input.Search placeholder="Client search" value={search} onChange={onSearchChange} onSearch={onSearchClick} style={{width: "200px", marginLeft: "30px"}}/>
            </Dropdown>
            
            }
            <Button onClick={onClearClick} style={{ marginLeft:"10px" }}>
                Clear
            </Button>
        </div>
    )
}

function GenerateClientsDropdown(clientList, callback) {

    if (clientList == null)
        return (
            <Menu>
                <p className="error-text">No clients were found!</p>
            </Menu>
        );

    else {
        return (
            <Menu style={{maxHeight: '400px', overflowY:'auto'}}>
                {
                    clientList.map((client, i) =>
                        <Menu.Item key={i}>
                            <a onClick={() => {callback(client.code, client.title, client.firstName, client.surname, client.officeCode, client.officeName,client.isNewClient)}}>
                                {`${client.code} ${client.firstName} ${client.surname}`}
                            </a>
                        </Menu.Item>
                    )
                }
            </Menu>
        )
    }
}

export default ClientCard;