import { Button, Input, Select } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import { HasValue } from "../../Helper/JSHelper";

const { Option } = Select;

function handleSearch(selectedKeys, confirm, dataIndex) {
    confirm();
};

function handleReset(clearFilters) {
    clearFilters();
};

function ReconcileSearchProps() {
    let searchInput;
    const result =
    {
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => {

            return (
                <div style={{ padding: 8 }}>
                    <Select
                        ref={node => {
                            searchInput = node;
                        }}
                        placeholder={`Select Reconciled`} 
                        value={selectedKeys[0]}
                        onChange={e => setSelectedKeys(e ? [e] : [])}
                        style={{ width: 188, marginBottom: 8, display: 'block' }}
                    >
                        <Option key={0}>Reconciled</Option>
                        <Option key={1}>Unreconciled</Option>
                    </Select>
                    <Button
                        type="primary"
                        onClick={() => handleSearch(selectedKeys, confirm, "reconcield")}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{ width: 90, marginRight: 8 }}
                    >
                        Search
                    </Button>
                    <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
                        Reset
                    </Button>
                </div>
            );
        },
        filterIcon: filtered => (

            <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
        ),
        onFilter: (value, record) => {
            if (value === '0') {
                if (HasValue(record.reconciled)) 
                    return Math.round(record.savedAmount * 100) === Math.round(record.reconciled * 100)
                else 
                    return false;
            }
            else {
                if (HasValue(record.reconciled) && record.key > 0)
                    return Math.round(record.savedAmount * 100) !== Math.round(record.reconciled * 100)
                else 
                    return true;
            }
        },
        render: text => text,
    }
    return result;
};

export default ReconcileSearchProps;