
export function HasValue(object) {
    try {
        if (typeof object === "undefined")
            return false;

        if (object === null)
            return false;

        if (object === "")
            return false;

        return true;
    }
    catch {
        return false;
    }
};

export function Find(array, prop, value) {
    if (!HasValue(array) || array.length < 1)
        return null;

    for (var i = 0; i < array.length; i++) {
        if (array[i][prop] === value)
            return array[i];
    }
}

export function FindAll(array, prop, value) {
    if (!HasValue(array) || array.length < 1)
        return null;

    let result = [];

    for (var i = 0; i < array.length; i++) {
        if (array[i][prop] === value)
            result.push(array[i]);
    }

    return result;
}

export function FindIndex(array, prop, value) {
    if (!HasValue(array) || array.length < 1)
        return null;

    for (var i = 0; i < array.length; i++) {
        if (array[i][prop] === value)
            return i;
    }
}

export function MaxValue(array, prop) {
    if (!HasValue(array) || array.length < 1)
        return null;

    let result = array[0][prop];

    for (var i = 1; i < array.length; i++) {
        if (array[i][prop] > result)
            result = array[i][prop];
    }

    return result;
}

export function Wait(milisec) {
    return new Promise(resolve => {
        setTimeout(() => { resolve('') }, milisec);
    })
}

export async function SendSignal(setter) {
    setter(true);
    await Wait(10);
    setter(false);
}

export function CopyList(list) {
    if (!HasValue(list) || list.length < 1)
        return null;

    let result = [];

    list.forEach(element => {
        let item = { ...element };
        result.push(item);
    });

    return result;
}

//Indicates if a number ends in '.', '.0' or '.00', in which case it should be stored in memory as a string to preserve it
export function IsStringNumber(number) {
    let string = String(number);

    if(string.length > 0 && string[string.length - 1] == '.' )
        return true;
    
    if (string.length > 1 && string[string.length - 2] == '.' && string[string.length - 1] == '0')
        return true;

    if (string.length > 2 && string[string.length - 3] == '.' && string[string.length - 2] == '0' && string[string.length - 1] == '0')
        return true;

    return false;
}
