import { MsalContext, useMsal } from "@azure/msal-react";
import { render } from "@testing-library/react";
import { useEffect, useState } from "react";
import { HasValue } from "../Helper/JSHelper";
import { GetAuthorizationToken } from "../Helper/JWTToken";

const jwt = require('jsonwebtoken');
const PermissionList = [
    "Edit Client Field",
    "Client History",  
    "Invoice Format",
    "Fee Statement",
    "Receipt Format",
    "Standard Monthly Fees - View",
    "Standard Monthly Fees - Edit",
    "Monthly Invoice - View",
    "Monthly Invoice - Edit",
    "Monthly Direct Debit - View",
    "Monthly Direct Debit - Edit",
    "Vat Rate",
    "Monthly Accounts Receivable",
    "Lodgements and Receipts Rec",
    "Create Invoice (HQ)",
    "Invoice Format (HQ)",
    "Create Credit Note (HQ)",
    "Credit Note (HQ) Format",   
    "Unpaid Direct Debits - View",
    "Unpaid Direct Debits - Edit",
    "Shares Bought",
    "CNInvoiceInput",
    "AddRefund",
    "CreateJournal",
    "BadDebit",
    "Refund List"
    ]

async function CheckAuthorization(permissionsRequired, msal) {
    try {
        var token = await GetAuthorizationToken(msal);
        if (HasValue(token)){
            const decoded = jwt.decode(token);

            //Add extra zeros to the authorization bitmap if its length is less than the number of permissions
            const bitDiff = PermissionList.length - decoded.role.length
            if (bitDiff > 0)
                for (let i = 0; i < bitDiff; i++)
                    decoded.role += "0";

            const permissionsPresentBits = Number("0b" + decoded.role);

            let permissionsRequiredBits = "";

            for (let i = 0; i < PermissionList.length; i++) {
                if (permissionsRequired.includes(PermissionList[i]))
                    permissionsRequiredBits = permissionsRequiredBits + "1";
                else 
                    permissionsRequiredBits = permissionsRequiredBits + "0";
            }

            permissionsRequiredBits = Number("0b" + permissionsRequiredBits);
            
            if ((permissionsPresentBits & permissionsRequiredBits) === permissionsRequiredBits){
                return true;
            }
            else
                return false;
        }
        else 
            return false;
    }
    catch (e) {
        return false;
    }
}

async function SetAuthorization (permissionsRequired, setIsAuthorized, msal) {
    setIsAuthorized(await CheckAuthorization(permissionsRequired, msal));
}

function AuthExtension(X) {
    return class extends X {
        static contextType = MsalContext;

        constructor(props) {
            super(props);

            this.state = {
                isAuthorized: false
            };

            this.permissionsRequired = props.permissions;
        }

        componentDidMount() {
            const msal = this.context;
            
            if (HasValue(this.permissionsRequired))
                SetAuthorization(
                    this.permissionsRequired, 
                    (authorized) => {
                        this.setState({          
                            isAuthorized: authorized
                        });
                    },
                    msal
                );       
            else {
                this.setState({
                    isAuthorized: true
                });
            }
        }

        render () {
            if (this.state.isAuthorized)
            {
                return super.render();
            }
            else
            {
                return null;
            }
        }
    }
}

function Authorize ({permissions, children, isVisible = true}){
    const msal = useMsal();
    let auth = false;
    if (!HasValue(permissions))
        auth = true;

    const [isAuthorized, setIsAuthorized] = useState(auth);

    useEffect(async () => {
        if (!isAuthorized) {          
            SetAuthorization(permissions, setIsAuthorized, msal);
        }
    }, []);

    if (isVisible && isAuthorized)
        return(children);
    else
        return(null);
}

export { AuthExtension };
export { Authorize };
export { CheckAuthorization };