import "../Style/LodgementsStyle.css"
import { useMsal } from '@azure/msal-react';
import { Spin, Button, Table, Input } from 'antd';
import { useContext, useEffect, useState } from 'react';
import { Find, FindIndex, HasValue } from '../Helper/JSHelper';
import moment from 'moment';
import { GetMICData, GetVatRate, PostCreditNote, PostMICData } from '../Data Layer/Data';
import { GetAccessToken } from '../Helper/JWTToken';
import NumericInput from '../Components/NumericInput';
import { MainContext } from "../Components/Context";
import KeySelect from "../Components/KeySelect";
import SaveButton from "../Components/SaveButton";
import PDFInvoice from "../Components/PDFInvoice";
import { pdf } from "@react-pdf/renderer";
import TextEditModal from "../Components/TextEditModal";
import AlertModal from "../Components/AlertModal";
import ConfirmModal from "../Components/ConfirmModal"
import StandardSelect from "../Components/StandardSelect";
import Checkbox from "antd/lib/checkbox/Checkbox";
import { Authorize, CheckAuthorization } from "../Helper/Authorization";

const { TextArea } = Input;

let totalGross = 0;

function GetMonthList() {
    let monthList = [];

    //let currentMonth = moment().startOf('month');
    let currentMonth = moment.utc(Date()).startOf('month');
    let index = 0;

    do {
        monthList.push({
            key: index,
            display: currentMonth.format('MMMM YYYY'),
            date: currentMonth.toDate()
        });

        currentMonth.add(-1, 'months');
        index++;
    }
    while (index < 60)

    return monthList;
}

function GetCreditNoteUpload(creditNoteData, monthList) {

    let minMonth = 60;
    let maxMonth = 0;
    let looFee = 0;
    let shareCapital = null;

    let services = [];

    creditNoteData.services.forEach(service => {
        looFee += service.net ?? 0;

        if (service.toDateKey < minMonth)
            minMonth = service.toDateKey;

        if (service.fromDateKey > maxMonth)
            maxMonth = service.fromDateKey

        var cmiService = {
            serviceId: service.serviceId,
            serviceCategoryId: service.categoryId,
            startMonth: monthList[service.fromDateKey].date,
            endMonth: monthList[service.toDateKey].date,
            amount: service.net
        };

        if (service.serviceId == 22 && HasValue(creditNoteData.bookeepingSubServices) && creditNoteData.bookeepingSubServices.length > 0) {
            cmiService.subServices = [];

            creditNoteData.bookeepingSubServices.forEach(subService => {
                if (subService.subServiceId == 3)
                    shareCapital = subService.net;
                else
                    looFee += subService.net ?? 0;

                let cmiSubService = {
                    SubServiceId: subService.subServiceId,
                    Amount: subService.net
                };

                if (subService.subServiceId == 4 && HasValue(subService.net) && subService.net != 0) {
                    cmiSubService.startMonth = monthList[subService.fromDateKey].date;
                    cmiSubService.endMonth = monthList[subService.toDateKey].date;
                }

                cmiService.subServices.push(cmiSubService);
            });
        }

        services.push(cmiService)
    });

    let result = {
        minMonth: monthList[maxMonth].date,
        maxMonth: monthList[minMonth].date,
        loFee: Math.round(looFee * 100) / 100,
        shareCapital: shareCapital,
        services: services
    }

    return result;
}

function GenerateCreditNotePreview(creditNoteData, vat, description, info, monthList) {

    let date = new Date();
    let dueDate = moment().add(1, "months").toDate();

    if (!HasValue(creditNoteData.services) || creditNoteData.services.length == 0)
        return;

    let looFee = 0;
    let shareCapital = 0;
    let isMultipleVatRate = false;
    let minMonth = 60;

    creditNoteData.services.forEach(service => {
        looFee += service.net ?? 0;

        if (service.toDateKey < minMonth)
            minMonth = service.toDateKey;
    });

    let monthString = moment(monthList[minMonth].date).endOf('year').format("MM/YYYY");
    let yearString = moment(monthList[minMonth].date).format("YYYY");

    if (HasValue(creditNoteData.bookeepingSubServices) && creditNoteData.bookeepingSubServices.length > 0) {
        creditNoteData.bookeepingSubServices.forEach(subService => {
            if (subService.subServiceId == 3)
                shareCapital = subService.net ?? 0;
            else
                looFee += subService.net ?? 0;
        });
    }

    if (shareCapital > 0)
        isMultipleVatRate = true;

    let net = Math.round((looFee + shareCapital) * 100) / 100;
    let looFeeGross = Math.round(looFee * (1 + vat / 100) * 100) / 100;
    let amountDue = Math.round((looFeeGross + shareCapital) * 100) / 100;
    let vatAmount = Math.round((looFeeGross - looFee) * 100) / 100;

    let ref = `${info.clientCode}/${monthString}/${yearString}/syn2`;

    let result = {
        isMonthlyInvoice: false,
        invoiceNumber: "",
        ref: ref,
        dateCreated: date,
        clientTitle: info.clientTitle,
        clientFirstName: info.clientFirstName,
        clientSurname: info.clientSurname,
        clientAddress1: info.clientAddress1,
        clientAddress2: info.clientAddress2,
        clientAddress3: info.clientAddress3,
        clientAddress4: info.clientAddress4,
        clientAddress5: info.clientAddress5,
        description: description,
        dateCancelled: null,
        isMultipleVatRate: isMultipleVatRate,
        vat: vat,
        annualReturnFeeString: shareCapital.toLocaleString(undefined, { minimumFractionDigits: 2 }),
        netString: net.toLocaleString(undefined, { minimumFractionDigits: 2 }),
        vatAmountString: vatAmount.toLocaleString(undefined, { minimumFractionDigits: 2 }),
        loFeeString: looFee.toLocaleString(undefined, { minimumFractionDigits: 2 }),
        loFeeGrossString: looFeeGross.toLocaleString(undefined, { minimumFractionDigits: 2 }),
        amountDueString: amountDue.toLocaleString(undefined, { minimumFractionDigits: 2 }),
        dateDue: dueDate,
        iban: info.iban,
        bic: info.bic,
        isCreditNote: true
    };

    return result;
}

function GetCategoryId(creditNoteService, serviceData) {
    let result = null;

    const service = Find(serviceData.services, "serviceId", creditNoteService.serviceId)

    if (HasValue(serviceData.clientSubServices))
        serviceData.clientSubServices.forEach(clientSubService => {
            const subService = Find(service.subServices, "subServiceId", clientSubService.subServiceId);

            if (HasValue(subService)) {
                const category = Find(service.categories, "serviceCategoryId", subService.serviceCategoryId)

                if (HasValue(category))
                    result = category.serviceCategoryId;
            }
        });

    return result;
}

function CreateCreditNote(services = null, subServices = null) {
    let creditNote = {
        services: [],
        bookeepingSubServices: []
    };

    if (services != null) {
        let newServices = services.map((item, i) => {
            return { ...item };
        });

        creditNote.services = newServices
    }

    let brIndex = FindIndex(creditNote.services, "serviceId", 22);
    if (HasValue(brIndex)) {
        if (!HasValue(subServices) || subServices.length === 0)
            creditNote.bookeepingSubServices = [
                {
                    subServiceId: 1,
                    name: "POAC",
                    net: null,
                    hasDates: false,
                    fromDateKey: null,
                    toDateKey: null
                },
                {
                    subServiceId: 2,
                    name: "Prepayment Standard",
                    net: null,
                    hasDates: false,
                    fromDateKey: null,
                    toDateKey: null
                },
                {
                    subServiceId: 4,
                    name: "Vat by IFAC",
                    net: null,
                    hasDates: true,
                    fromDateKey: null,
                    toDateKey: null
                },
                {
                    subServiceId: 3,
                    name: "Share Capital",
                    net: null,
                    hasDates: false,
                    fromDateKey: null,
                    toDateKey: null
                },
            ];
        else {
            creditNote.bookeepingSubServices = subServices;

            creditNote.bookeepingSubServices.forEach(element => {
                if (element.subServiceId != 4) {
                    element.fromDateKey = creditNote.services[brIndex].fromDateKey;
                    element.toDateKey = creditNote.services[brIndex].toDateKey;
                }
            });
        }
    }

    return creditNote;
}

function CreateCreditNoteService(service, serviceCategoryId = null) {
    let category = Find(service.categories, 'serviceCategoryId', serviceCategoryId ?? service.categoryId);
    return {
        serviceId: service.serviceId,
        name: service.name,
        categories: service.categories,
        categoryName: category?.description,
        categoryId: serviceCategoryId ?? service.categoryId,
        toDateKey: service.toDateKey,
        fromDateKey: service.fromDateKey,
        hasNet: service.hasNet,
        net: service.net
    };
}

function CreateCreditNotePage() {
    const msal = useMsal();
    const context = useContext(MainContext);

    const [isLoading, setIsLoading] = useState(false);
    const [serviceData, setServiceData] = useState(null);
    const [creditNoteData, setCreditNoteData] = useState(CreateCreditNote());
    const [monthList, setMonthList] = useState(GetMonthList());
    const [description, setDescription] = useState("");
    const [invoicesDue, setInvoicesDue] = useState([]);
    const [invoicesDisplay, setInvoicesDisplay] = useState([]);
    const [selectedInvoice, setSelectedInvoice] = useState(null);
    const [message245, setMessage245] = useState("");
    const [message246, setMessage246] = useState("");
    const [modalVisible, setModalVisible] = useState(false);
    const [isManualInvoice, setIsManualInvoice] = useState(false);
    const [manualInvoiceNumber, setManualInvoiceNumber] = useState("");
    const [currentVatRate, setCurrentVatRate] = useState(0);
    const [isHQAdded, setIsHQAdded] = useState(false);
    const [reason, setReason] = useState("");

    const paymentList = ["Cash", "DD"];

    useEffect(async () => {
        if (HasValue(context.client.code)) {
            setIsLoading(true);
            const token = await GetAccessToken(msal);
            const data = await GetMICData(token, context.client.code, true);
            const vat = await GetVatRate(token);

            setCurrentVatRate(vat);
            setServiceData(data);
            setInvoicesDue(data.invoicesDue);
            setInvoicesDisplay(data.invoicesDue.map((invoice) => invoice.invoiceNumber));
            setMessage245(data.message245);
            setMessage246(data.message246);
            setIsHQAdded(data.isHQAdded);
            setIsLoading(false);
        }
        else {
            setServiceData(null);
        }
    }, [context.client.code]);

    useEffect(async () => {
        setDescription("")
        setCreditNoteData(CreateCreditNote());
    }, [serviceData]);

    const genetareDescription = (data) => {
        if (!HasValue(data.services) || data.services.length == 0)
            return "";

        let result = "";

        for (let i = 0; i < data.services.length; i++) {
            let serviceString = "";

            switch (data.services[i].serviceId) {
                case 5: {
                    serviceString = "Payroll Service";
                    break;
                }
                case 22: {
                    serviceString = "Recorder Bookkeeping Service and ongoing Local Office support";
                    break;
                }
                case 23: {
                    serviceString = "Bookeeping Service";
                    break;
                }
                case 28: {
                    serviceString = "FarmPro Service";
                    break;
                }
                case 30: {
                    serviceString = "Professional Services";
                    break;
                }
                case 11: {
                    serviceString = "Professional Services";
                    break;
                }
                default: {
                    serviceString = `${data.services[i].name} Service`;
                    break;
                }
            }

            let monthString = `${monthList[data.services[i].fromDateKey]?.display} to ${monthList[data.services[i].toDateKey]?.display}`;

            if (data.services[i].fromDateKey == data.services[i].toDateKey)
                monthString = monthList[data.services[i].fromDateKey]?.display;


            result += `${i + 1}. Change in provision of ${serviceString} for ${monthString}.\n\n`;
        }

        setDescription(result);
    }

    const computeNet = (creditNoteData, service) => {
        const test = serviceData;

        if (!HasValue(service.categoryId) || !HasValue(service.fromDateKey) || !HasValue(service.toDateKey))
            return;

        let serviceInfo = null;
        let subServiceInfo = null;

        if (service.serviceId !== 22) {
            serviceInfo = Find(serviceData.services, "serviceId", service.serviceId);
            subServiceInfo = Find(serviceInfo.subServices, "serviceCategoryId", service.categoryId)
            if (!HasValue(subServiceInfo))
                service.net = null;
            else
                service.net = Math.round(calcFees(subServiceInfo.fees, monthList[service.fromDateKey], monthList[service.toDateKey]) * 100) / 100;
        }
        else {
            serviceInfo = Find(serviceData.services, "serviceId", 22);

            for (let i = 0; i < creditNoteData.bookeepingSubServices.length; i++) {
                if (creditNoteData.bookeepingSubServices[i].subServiceId == 4 || creditNoteData.bookeepingSubServices[i].subServiceId == 3)
                    continue;

                creditNoteData.bookeepingSubServices[i].net = null;

                for (let j = 0; j < serviceInfo.subServices.length; j++) {
                    if (serviceInfo.subServices[j].serviceCategoryId == service.categoryId && serviceInfo.subServices[j].subServiceType == creditNoteData.bookeepingSubServices[i].subServiceId) {
                        creditNoteData.bookeepingSubServices[i].net = Math.round(calcFees(serviceInfo.subServices[j].fees, monthList[service.fromDateKey], monthList[service.toDateKey]) * 100) / 100;
                        break;
                    }
                }
            }
        }
    }

    const calcFees = (fees, fromDate, toDate) => {
        let current = moment(fromDate.date);
        let end = moment(toDate.date);
        let result = 0;

        while (!current.isAfter(end, 'days')) {
            var test = current.year();
            let fee = Find(fees, 'year', current.year());
            if (HasValue(fee))
                result += fee.fee;

            current = current.add(1, 'months');
        }

        return result;
    }

    const serviceChanged = (oldServiceId, newServiceId) => {
        let creditNoteClone = CreateCreditNote(creditNoteData.services, creditNoteData.bookeepingSubServices);

        if (oldServiceId == -1 && newServiceId != -1) //insert new service
        {
            let newService = Find(serviceData.services, "serviceId", newServiceId);
            let newCreditNoteService = CreateCreditNoteService(newService, GetCategoryId(newService, serviceData));
            creditNoteClone.services.push(newCreditNoteService);
        }
        else if (newServiceId == -1) //delete existing service
        {
            const index = FindIndex(creditNoteClone.services, "serviceId", oldServiceId);
            creditNoteClone.services.splice(index, 1);

        }
        else //swap existing service
        {
            const index = FindIndex(creditNoteClone.services, "serviceId", oldServiceId);
            let newService = Find(serviceData.services, "serviceId", newServiceId);
            let newCreditNoteService = CreateCreditNoteService(newService, GetCategoryId(newService, serviceData));
            creditNoteClone.services[index] = newCreditNoteService;
        }

        creditNoteClone = CreateCreditNote(creditNoteClone.services, creditNoteClone.bookeepingSubServices);

        setCreditNoteData(creditNoteClone);
        genetareDescription(creditNoteClone);
    };

    const categoryChanged = (serviceId, categoryId) => {
        let creditNoteClone = CreateCreditNote(creditNoteData.services, creditNoteData.bookeepingSubServices);

        const index = FindIndex(creditNoteClone.services, "serviceId", serviceId);
        let service = creditNoteClone.services[index];
        service.categoryId = categoryId;
        creditNoteClone.services[index] = CreateCreditNoteService(service);

        computeNet(creditNoteClone, creditNoteClone.services[index]);

        setCreditNoteData(creditNoteClone);
    };

    const monthChanged = (serviceId, monthKey, isFrom, isSubService) => {
        let creditNoteClone = CreateCreditNote(creditNoteData.services, creditNoteData.bookeepingSubServices);

        if (isSubService) {
            const index = FindIndex(creditNoteClone.bookeepingSubServices, "subServiceId", serviceId);
            let service = creditNoteClone.bookeepingSubServices[index];
            if (isFrom)
                service.fromDateKey = monthKey;
            else
                service.toDateKey = monthKey;

            if (HasValue(service.fromDateKey) && HasValue(service.toDateKey) && service.fromDateKey < service.toDateKey) {
                service.fromDateKey = monthKey;
                service.toDateKey = monthKey;
            }

            creditNoteClone.bookeepingSubServices[index] = { ...service };
        }
        else {
            const index = FindIndex(creditNoteClone.services, "serviceId", serviceId);
            let service = creditNoteClone.services[index];
            if (isFrom)
                service.fromDateKey = monthKey;
            else
                service.toDateKey = monthKey;

            if (HasValue(service.fromDateKey) && HasValue(service.toDateKey) && service.fromDateKey < service.toDateKey) {
                service.fromDateKey = monthKey;
                service.toDateKey = monthKey;
            }

            creditNoteClone.services[index] = CreateCreditNoteService(service);
            computeNet(creditNoteClone, creditNoteClone.services[index]);
        }

        genetareDescription(creditNoteClone);

        setCreditNoteData(creditNoteClone);
    }

    const netChanged = (serviceId, val, isSubService) => {
        if (isNaN(val))
            return;

        if (val < 0)
            return;

        let creditNoteClone = CreateCreditNote(creditNoteData.services, creditNoteData.bookeepingSubServices);

        if (isSubService) {
            const index = FindIndex(creditNoteClone.bookeepingSubServices, "subServiceId", serviceId);
            let service = creditNoteClone.bookeepingSubServices[index];
            service.net = val;
        }
        else {
            const index = FindIndex(creditNoteClone.services, "serviceId", serviceId);
            let service = creditNoteClone.services[index];
            service.net = val;

            creditNoteClone.services[index] = CreateCreditNoteService(service);
        }

        setCreditNoteData(creditNoteClone);
    }

    const vatRate = () => {
        if (isManualInvoice)
            return currentVatRate;

        if (!HasValue(selectedInvoice))
            return null;
        
        else return invoicesDue[selectedInvoice].vatRate;
    }

    const getGross = (net) => {
        if (!HasValue(net) || !HasValue(vatRate()))
            return null;

        return Math.round(net * (1 + vatRate() / 100) * 100) / 100;
    }

    const columns = [
        {
            title: 'Service',
            render: (text, record) => {
                if (!record.isSubService)
                    return (
                        <KeySelect style={{ width: "230px" }} selectedItem={record.serviceName} items={record.serviceSelect} onChange={(val) => serviceChanged(record.serviceId, Number(val))} />
                    )

                else
                    return (
                        <p>{record.serviceName}</p>
                    )
            }
        },
        {
            title: 'Category Code / Level',
            render: (text, record) => record.hasCategories && <KeySelect style={{ width: "250px" }} selectedItem={record.categoryName} items={record.categorySelect} onChange={(val) => categoryChanged(record.serviceId, Number(val))} />
        },
        {
            title: 'From',
            render: (text, record) => record.hasDates && <KeySelect style={{ width: "150px" }} selectedItem={(HasValue(record.fromDateKey)) ? monthList[record.fromDateKey].display : null} items={monthList} onChange={(val) => monthChanged(record.serviceId, Number(val), true, record.isSubService)} />
        },
        {
            title: 'To',
            render: (text, record) => record.hasDates && <KeySelect style={{ width: "150px" }} selectedItem={(HasValue(record.toDateKey)) ? monthList[record.toDateKey].display : null} items={monthList} onChange={(val) => monthChanged(record.serviceId, Number(val), false, record.isSubService)} />
        },
        {
            title: 'Net',
            render: (text, record) => {
                if (record.serviceId === -2
                    ||record.hideShareCapitalFields === 1
                    ) {
                    return (
                        <p style={{ width: "100%", textAlign: "right" }}>{record.net?.toLocaleString(undefined, { minimumFractionDigits: 2 })}</p>
                    )
                }
                else if (record.hasAmounts)
                    return (
                        <NumericInput style={{ textAlign: "right" }} value={record.net} setValue={(e) => netChanged(record.serviceId, e, record.isSubService)} />
                    )
            }
        },
        {
            title: 'Gross',
            render: (text, record) => {
                if (record.hasAmounts) {
                    if (record.serviceId === -2) {
                        return (
                            <p style={{ width: "100%", textAlign: "right" }}>{record.gross?.toLocaleString(undefined, { minimumFractionDigits: 2 })}</p>
                        )
                    }
                    else if (record.isSubService && record.serviceId == 3)
                        return (
                            <p style={{ width: "100%", textAlign: "right" }}>{record.net?.toLocaleString(undefined, { minimumFractionDigits: 2 })}</p>
                        )
                    else
                        return (
                            <p style={{ width: "100%", textAlign: "right" }}>{getGross(record.net)?.toLocaleString(undefined, { minimumFractionDigits: 2 })}</p>
                        )
                }
            }
        },
    ];

    const generateRow = (service) => {
        if (service == null) {
            return {
                serviceName: null,
                serviceId: -1,
                hasCategories: false,
                categoryName: null,
                categoryId: null,
                categorySelect: [],
                hasDates: false,
                hasAmounts: false,
                isSubService: false,
                fromDateKey: null,
                toDateKey: null,
                net: null
            };
        }

        let hasCategories = false;
        let categorySelect = [];
        let hasAmounts = true;
        if (service.serviceId == 22)
            hasAmounts = false;
        if (HasValue(service.categories) && service.categories.length > 0) {
            hasCategories = true;
            categorySelect = service.categories.map((category, i) => {
                return {
                    key: category.serviceCategoryId,
                    display: category.description
                }
            });
        }
        let newRow = {
            serviceName: service.name,
            serviceId: service.serviceId,
            hasCategories: hasCategories,
            categorySelect: categorySelect,
            categoryName: service.categoryName,
            categoryId: service.categoryId,
            hasDates: true,
            hasAmounts: hasAmounts,
            isSubService: false,
            fromDateKey: service.fromDateKey,
            toDateKey: service.toDateKey,
            net: service.net
        }

        return newRow;
    };

    const generateBookepingRows = (subServices,service) => {
        let newRows = [];

        let netTotal = 0;
        let grossTotal = 0;

        let hideShareCapitalFields=0;//don't hide share capital fiels

        subServices.forEach(subservice => {
            var net = 0;
            if (!isNaN(subservice.net))
                net = Number(subservice.net);

            netTotal += net;

            if (subservice.subServiceId == 3){
                if (service.categoryId && (service.categoryId===3 //Category 5
                ||service.categoryId===4//Category 5S
                ))
                    {
                        hideShareCapitalFields=1;//hide share capital net field
                    }
                else{
                    grossTotal += net;
                }
            }
            else
                grossTotal += getGross(net) ?? 0;

            newRows.push({
                serviceName: subservice.name,
                serviceId: subservice.subServiceId,
                hasCategories: false,
                categoryName: null,
                categoryId: null,
                categorySelect: [],
                hasDates: subservice.hasDates,
                hasAmounts: true,
                isSubService: true,
                fromDateKey: subservice.fromDateKey,
                toDateKey: subservice.toDateKey,
                net: subservice.net,
                hideShareCapitalFields:hideShareCapitalFields
            });
        });

        //Add Totals row
        newRows.push({
            serviceName: "",
            serviceId: -2,
            hasCategories: false,
            categoryName: null,
            categoryId: null,
            categorySelect: [],
            hasDates: false,
            hasAmounts: true,
            isSubService: true,
            fromDateKey: null,
            toDateKey: null,
            net: netTotal,
            gross: grossTotal
        })

        return newRows;
    }

    const generateTableRows = () => {
        let rows = [];

        let serviceSelect = [];

        serviceData.services.forEach((service) => {
            const index = FindIndex(creditNoteData.services, "serviceId", service.serviceId);

            if (service.serviceId == 29)
                return;

            if (!HasValue(index))
                serviceSelect.push({
                    key: service.serviceId,
                    display: service.name
                });
        });

        serviceSelect.push({
            key: -1,
            display: "None"
        });

        creditNoteData.services.forEach(service => {
            let newRow = generateRow(service);
            newRow.serviceSelect = serviceSelect;

            rows.push(newRow);

            if (service.serviceId == 22) {
                rows.push(...generateBookepingRows(creditNoteData.bookeepingSubServices,service));
            }
        });

        let netTotal = 0;
        let grossTotal = 0;

        rows.forEach(row => {
            if (row.serviceId == -2)
                return;

            var net = 0;
            if (!isNaN(row.net))
                net = Number(row.net);

            netTotal += net;

            if (row.isSubService && row.serviceId == 3)
                grossTotal += net;
            else
                grossTotal += getGross(net) ?? 0;
        });

        //Add empty row if necessary
        if (creditNoteData.services.length < 6) {
            let newRow = generateRow(null);
            newRow.serviceSelect = serviceSelect;

            rows.push(newRow);
        }

        //Add Totals row
        rows.push({
            serviceName: "Total",
            serviceId: -2,
            hasCategories: false,
            categoryName: null,
            categoryId: null,
            categorySelect: [],
            hasDates: false,
            hasAmounts: true,
            isSubService: true,
            fromDateKey: null,
            toDateKey: null,
            net: netTotal,
            gross: grossTotal
        })

        totalGross = grossTotal;

        return rows;
    }
    const selectedInvoiceChanged = (key) =>
    {
        setSelectedInvoice(key);
    }

    const manualInvoiceChanged = (e) =>
    {
        const val = e.target.value
        if (HasValue(val) && val.length > 50)
            return;

        setManualInvoiceNumber(val);
    }

    const setTitle = () => {
        let text = "Create Credit Note";

        if (HasValue(context.client.code)) {
            text += ` - ${context.client.code} - ${context.client.title} ${context.client.firstName} ${context.client.surname}`;
        }

        return (
            <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems:"center", maxWidth:"1200px" }}>
                <h3 style={{maxWidth:"400px"}}>{text}</h3>
                <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-start", alignItems:"center" }}>
                    <p style={{marginRight:"10px"}} className="text-bold">Invoice Number:</p>
                    {
                        !isManualInvoice ?
                        <StandardSelect items={invoicesDisplay} selectedItem={selectedInvoice} onChange={selectedInvoiceChanged} style={{width:"150px"}}/>
                        :
                        <Input type="text" style={{width:"150px"}} value={manualInvoiceNumber} onChange={manualInvoiceChanged}/>
                    }
                    <Authorize permissions={["CNInvoiceInput"]}>
                        <p style={{marginLeft:"20px", marginRight:"10px"}} className="text-bold">Input Number:</p>
                        <Checkbox  checked={isManualInvoice} onChange={(e) => setIsManualInvoice(e.target.checked)}/>
                    </Authorize>
                </div>
                <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-start", alignItems:"center"}}>
                    <p style={{marginRight:"10px"}} className="text-bold">Date Created:</p>
                    <p style={{width:"80px"}}>{(HasValue(selectedInvoice) && !isManualInvoice) ? new Date(invoicesDue[selectedInvoice]?.dateCreated).toLocaleDateString("en-GB") : null}</p>
                </div>
                <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-start", alignItems:"center"}}>
                    <p style={{marginRight:"10px"}} className="text-bold">Balance Due:</p>
                    <p style={{width:"50px"}}>{(HasValue(selectedInvoice) && !isManualInvoice) ? invoicesDue[selectedInvoice]?.balanceDue.toLocaleString(undefined, { minimumFractionDigits: 2 }) : null}</p>
                </div>
            </div>
        );
    }

    const showCreditNote = async () => {
        const creditNote = GenerateCreditNotePreview(creditNoteData, vatRate(), description, serviceData.info, monthList);
        const blob = await pdf(<PDFInvoice invoice={creditNote} draft={true} />).toBlob();
        let url = URL.createObjectURL(blob)
        window.open(url, "_blank");
    }

    const saveDisabled = () => {

        if (!HasValue(reason))
        return true;

        if (creditNoteData.services.length < 1)
            return true;

        if (!isManualInvoice && selectedInvoice === null)
            return true;

        if (isManualInvoice && !HasValue(manualInvoiceNumber))
            return true;

        let result = false;
        creditNoteData.services.forEach(service => {
            if (HasValue(service.categories) && service.categories.length > 0 && !HasValue(service.categoryId))
                result = true;

            if (!HasValue(service.fromDateKey) || !HasValue(service.toDateKey))
                result = true;

            if (service.serviceId != 22 && (service.net ?? 0) == 0)
                result = true;

        });

        creditNoteData.bookeepingSubServices.forEach(subService => {
            if (subService.subServiceId < 3 && (!HasValue(subService.net) || subService.net === ''))
                result = true;
            //Disable save id vat has values with no date
            if ((subService.subServiceId == 4) && (HasValue(subService.net) && subService.net !== '') && !(HasValue(subService.fromDateKey) && HasValue(subService.toDateKey)))
                result = true;

            let brService = Find(creditNoteData.services, 'serviceId', 22);

            if (brService.categoryId == 7 && (!HasValue(subService.net) || subService.net === ''))
                result = true;
        });

        return result;
    }

    const editDescription = () => {
        setModalVisible(true);
    }

    const checkDates = (data) => {
        if (isManualInvoice)
            return true;

        const date1 = moment(data.minMonth);
        const date2 = moment(data.maxMonth);
        const date3 = moment(invoicesDue[selectedInvoice].firstMonth);
        const date4 = moment(invoicesDue[selectedInvoice].lastMonth);

        if (date1.startOf("month") < date3.startOf("month") || date2.endOf("month") > date4.endOf("month"))
            return false;

        return true;
    }

    const saveCreditNote = async () => {
        if (!isManualInvoice && invoicesDue[selectedInvoice].balanceDue + 0.05 < totalGross) {
            AlertModal(message245);
            return;
        }

        let data = GetCreditNoteUpload(creditNoteData, monthList);

        data.description = description;
        data.vatRate = vatRate();
        data.cNReason=reason

        if (isManualInvoice)
            data.invoiceNumber = manualInvoiceNumber;
        else
            data.invoiceNumber = invoicesDue[selectedInvoice].invoiceNumber;

        data.clientCode = Number(context.client.code);

        const callback = async () => {
            const token = await GetAccessToken(msal);
            const result = await PostCreditNote(token, data);
            if (result.status == 200) {
                AlertModal("Credit note created.");
                setReason("");
                setIsLoading(true);

                const data = await GetMICData(token, context.client.code, true);

                setServiceData(data);
                setInvoicesDue(data.invoicesDue);
                setInvoicesDisplay(data.invoicesDue.map((invoice) => invoice.invoiceNumber));
                setMessage245(data.message245);
                setMessage246(data.message246);
                setDescription("")
                setCreditNoteData(CreateCreditNote());  
                setSelectedInvoice(null);   
                setIsManualInvoice(false);
                setManualInvoiceNumber("");         
                setIsLoading(false);
            }
            else
                AlertModal("Failed to create credit note.");
        }

        if (!checkDates(data)){
            ConfirmModal(message246, callback)
        }
        else 
            await callback();    
    }

    if (isLoading)
        return (
            <div style={{ width: "100%", height: "100%", minHeight: "inherit", display: "flex", alignItems: "center", justifyContent: "center" }}>
                <Spin size="large" />
            </div>
        )
    if (serviceData === null)
        return (
            <p style={{ padding: "20px" }}>Select a client to create a credit note.</p>
        )
    else
        return (
            <>
                <div className="div-ant-table center-cells" style={{ maxHeight: "100%", width: "100%", overflow: "auto" }}>
                    <form>
                        <Table title={setTitle} dataSource={generateTableRows()} columns={columns} pagination={{ position: ['none', 'none'], defaultPageSize: 12 }} />
                    </form>
                </div>
                { 
                    isHQAdded &&
                    <div>
                         <div style={{display: "flex", justifyContent: "space-between", alignItems:"flex-start", marginTop: "15px"}}></div>
                        <div style={{display: "flex", justifyContent: "flex-start", alignItems:"flex-start"}}>
                            <p style={{fontWeight:"bold", width:"max-content"}}>Reason:</p>
                            <TextArea value={reason} maxLength={500} onChange={(val) => setReason(val.target.value)} rows={3} style={{width:"500px", marginLeft: "15px"}}/>
                        </div>
                        <div style={{ display: "flex", justifyContent: "flex-end", alignItems: "center", marginTop: "15px" }}>
                            <div style={{ width: "100%", display: "flex", justifyContent: "flex-end" }}>
                                <SaveButton text="Create" disabled={saveDisabled()} callback={saveCreditNote} />
                                <Button type="primary" style={{ marginLeft: "10px" }} disabled={saveDisabled()} onClick={showCreditNote}>Preview</Button>
                                <Button type="primary" style={{ marginLeft: "10px" }} disabled={saveDisabled()} onClick={editDescription}>CN Desc</Button>
                                <TextEditModal toEdit={description} visible={modalVisible} setVisible={setModalVisible} callback={setDescription} />
                            </div>
                        </div>
                    </div>
                }
                {
                    !isHQAdded &&
                    <div style={{display: "flex", justifyContent: "flex-start", alignItems:"center", marginTop: "15px"}}>
                        <h3>A credit note cannot be created for this client as they have not yet been added by HQ</h3>
                    </div>
                }
            </>
        )
}

export default CreateCreditNotePage;