import { Button } from "antd";
import { SaveOutlined } from '@ant-design/icons';
import { useState } from "react";
import { HasValue } from "../Helper/JSHelper";

function SaveButton({disabled=false, text="Save", callback, hasLoading=true, style, className="" }) {
    const [loading, setLoading] = useState(false);

    const onClick = async () => {
        if (HasValue(callback)) {

            if (hasLoading)
                setLoading(true);
                
            await callback();

            setLoading(false);
        }
    }

    return (
        <Button style={style} disabled={disabled} className={`btn-save ${className}`} icon={<SaveOutlined />} loading={loading} onClick={onClick}>{text}</Button>
    )
}

export default SaveButton