
import { useMsal } from "@azure/msal-react";
import { accessTokenRequest } from "../authConfig";
import { HasValue } from "./JSHelper";
import axios from 'axios';
const config = window['appSettings'];

export async function GetAccessToken(msal, callback) {
    const { instance, accounts } = msal;

    const request = {
        ...accessTokenRequest,
        account: accounts[0]
    };

    try {
        var result = await Promise.resolve(instance.acquireTokenSilent(request));
        return result.accessToken;
    }

    catch (e) {
        var result = await Promise.resolve(instance.acquireTokenPopup(request));
        return result.accessToken;
    }
};

export async function GetAuthorizationToken(msal) {
    var authorizationToken = sessionStorage.getItem("authorizationToken");

    if (HasValue(authorizationToken))
    {
        return authorizationToken;
    }
    else
    {
        try {
            const result = await AcquireAuthorizationToken(msal);
            sessionStorage.setItem("authorizationToken", result);
            return result;
        }
        catch (e) {
            return null;
        }
    }
    
}

async function AcquireAuthorizationToken(msal) {
    try {
        const accessToken = await GetAccessToken(msal);
        const result = await axios.get(
            config.API_URL + "Authorization/Token", 
            {
                headers: {
                authorization: `Bearer ${accessToken}`
                }
            });          
        return result.data;
    }
    catch(e) {
        throw e;
    }
}

