import { useMsal } from "@azure/msal-react";
import { Button }  from 'antd'; 

function UserCard() {

    const { instance, accounts } = useMsal();

    const userName = accounts[0].name;

    const handleLogout = () => {
      sessionStorage.clear();
      instance.logoutRedirect();
   }

    return (
    <div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
        <p>Hello, {userName}</p>
        <Button type='primary' className="btn-login" style={{width: 'fit-content', marginLeft: '20px' }} onClick={() => handleLogout()}>Logout</Button>
    </div>
    );
}

export default UserCard;